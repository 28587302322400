import React from "react";
import EyouthLogo from "../../../assets/EYouthLogo.png";
import itidaLogo from "../../../assets/ITIDALogo.png";
import MobileNavMenu from "./MobileNavMenu";
const MobileNavBar = ({ children }) => {
  return (
    <>
      <div className="w-full fixed z-[100] top-0 left-0 px-[5%] h-fit py-1rem tablet:hidden flex items-center bg-[#f8f8f8] shadow ">
        <MobileNavMenu />
        <div className={`w-fit flex items-center gap-3 mx-auto`}>
          <img className="w-[4.5rem]" src={EyouthLogo} alt="e-youth logo" />
          <img className="w-[4.5rem]" src={itidaLogo} alt="ITIDA logo" />
        </div>
        {children}
      </div>
    </>
  );
};

export default MobileNavBar;
