import DeliverablesComponent from "../../Components/DelivrablesComponent/DelivrablesComponent";
import QAComponent from "../../Components/QAComponent/QAComponent";

function Dashboard() {
  return (
    <>
      <DeliverablesComponent isPaginationDisabled pageTitle="Latest Reviews" />
      <QAComponent pageTitle="Latest Q&A" isPaginationDisabled />
    </>
  );
}

export default Dashboard;
