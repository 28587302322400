import axios from "axios";

const putWebinarRecord = async (id, link) => {
    try {
        const response = await axios.put(
            `https://dfppback.eyouthlearning.com/webinar/${id}/updaterecorded/`,
            { link },
        );
        return response.data;
    } catch (error) {
        if (error.response?.data) {
            throw error
        }
        throw new Error("Something went wrong.");
    }
}

export default putWebinarRecord;