import axios from "axios";
import { webinarAttendanceEndPoint } from "./endPoints";

export async function apiPostWebinarAttendance(webinarId) {
  try {
    const response = await axios.post(webinarAttendanceEndPoint, {
      webinar: webinarId,
    });
    return response.data;
  } catch (error) {
    console.error("Error posting question:", error);
  }
}
