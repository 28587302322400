import React from 'react'

export default function Clock({ className }) {
    return (
        <svg
            width={21}
            height={21}
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M11.4112 10.7632C11.4112 11.1043 11.1347 11.3808 10.7936 11.3808C10.4525 11.3808 10.1759 11.1043 10.1759 10.7632C10.1759 10.4221 10.4525 10.1455 10.7936 10.1455C11.1347 10.1455 11.4112 10.4221 11.4112 10.7632Z"
                fill="white"
            />
            <path
                d="M11.4112 10.7632C11.4112 11.1043 11.1347 11.3808 10.7936 11.3808C10.4525 11.3808 10.1759 11.1043 10.1759 10.7632C10.1759 10.4221 10.4525 10.1455 10.7936 10.1455M11.4112 10.7632C11.4112 10.4221 11.1347 10.1455 10.7936 10.1455M11.4112 10.7632H14.4994M10.7936 10.1455V6.23386M18.2052 10.7632C18.2052 14.8565 14.8869 18.1748 10.7936 18.1748C6.70025 18.1748 3.38196 14.8565 3.38196 10.7632C3.38196 6.66986 6.70025 3.35156 10.7936 3.35156C14.8869 3.35156 18.2052 6.66986 18.2052 10.7632Z"
                stroke="white"
                strokeWidth={1.23527}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
