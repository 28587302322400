import axios from 'axios';
import { InstructorWebinar } from './endPoints';

const apiPostWebinar = async (webinarData) => {
    const response = await axios.post(
        InstructorWebinar,
        webinarData,
    );
    return response.data;
};

export default apiPostWebinar;
