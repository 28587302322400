import React from 'react'

export default function Benefits2({ className }) {
    return (
        <svg
            width={90}
            height={90}
            viewBox="0 0 90 90"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <g clipPath="url(#clip0_812_2687)">
                <path
                    d="M0 4C0 1.79086 1.79086 0 4 0H86C88.2091 0 90 1.79086 90 4V86C90 88.2091 88.2091 90 86 90H4C1.79086 90 0 88.2091 0 86V4Z"
                    fill="#F48C06"
                    fillOpacity={0.3}
                />
                <path
                    d="M30.25 41.25C33.2836 41.25 35.75 38.7836 35.75 35.75C35.75 32.7164 33.2836 30.25 30.25 30.25C27.2164 30.25 24.75 32.7164 24.75 35.75C24.75 38.7836 27.2164 41.25 30.25 41.25ZM68.75 41.25C71.7836 41.25 74.25 38.7836 74.25 35.75C74.25 32.7164 71.7836 30.25 68.75 30.25C65.7164 30.25 63.25 32.7164 63.25 35.75C63.25 38.7836 65.7164 41.25 68.75 41.25ZM71.5 44H66C64.4875 44 63.1211 44.6102 62.1242 45.5984C65.5875 47.4977 68.0453 50.9266 68.5781 55H74.25C75.7711 55 77 53.7711 77 52.25V49.5C77 46.4664 74.5336 44 71.5 44ZM49.5 44C54.8195 44 59.125 39.6945 59.125 34.375C59.125 29.0555 54.8195 24.75 49.5 24.75C44.1805 24.75 39.875 29.0555 39.875 34.375C39.875 39.6945 44.1805 44 49.5 44ZM56.1 46.75H55.3867C53.5992 47.6094 51.6141 48.125 49.5 48.125C47.3859 48.125 45.4094 47.6094 43.6133 46.75H42.9C37.4344 46.75 33 51.1844 33 56.65V59.125C33 61.4023 34.8477 63.25 37.125 63.25H61.875C64.1523 63.25 66 61.4023 66 59.125V56.65C66 51.1844 61.5656 46.75 56.1 46.75ZM36.8758 45.5984C35.8789 44.6102 34.5125 44 33 44H27.5C24.4664 44 22 46.4664 22 49.5V52.25C22 53.7711 23.2289 55 24.75 55H30.4133C30.9547 50.9266 33.4125 47.4977 36.8758 45.5984Z"
                    fill="#F48C06"
                />
            </g>
            <defs>
                <clipPath id="clip0_812_2687">
                    <path
                        d="M0 45C0 20.1472 20.1472 0 45 0C69.8528 0 90 20.1472 90 45V90H45C20.1472 90 0 69.8528 0 45Z"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}
