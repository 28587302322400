import React from 'react';
import useFetch from '../../../../hooks/useFetch';
import { AiReviewerGetAll } from '../../../../Services/endPoints';
import { Link } from 'react-router-dom';
import { pageRoutes } from '../../../../constants';
import BackBtn from '../../../../Components/svgs/BackBtn';
import AiIcon from '../../../../Components/svgs/AIIcon';
import { format } from 'date-fns';

export default function AIReviewerHistory() {
    const {
        data: aiReviewerHistory,
        loading: aiReviewerHistoryLoading,
        error: aiReviewerHistoryError,
    } = useFetch(AiReviewerGetAll);

    if (aiReviewerHistoryLoading) return <div>Loading...</div>;
    if (aiReviewerHistoryError) return <div>Error: {aiReviewerHistoryError}</div>;

    return (
        <div className='m-6'>
            <Link
                to={`/${pageRoutes.aiReviewer}`}
                className='bg-[#FFC107] px-4 py-5 border rounded-xl text-[#273C97] text-base font-semibold flex w-fit gap-4 justify-center items-center'
            >
                <BackBtn />
                Back To AI reviewer
            </Link>

            <div className='flex gap-6 md:gap-8 justify-center items-center flex-wrap my-10 flex-col md:flex-row'>
                {/* History Cards */}
                {aiReviewerHistory?.results?.map((history, index) => (
                    <div
                        key={history.id}
                        className='border border-[#D9D9D9] rounded-lg p-4 md:p-5 md:w-[46%] w-full'
                    >
                        <div className='flex justify-between'>
                            <h2 className='text-[#121212] text-lg font-semibold'>
                                {history.job_title}
                            </h2>
                            <span className='text-[#4CAF50] border-[#4CAF50] border-2 rounded-md px-3 py-1 text-lg font-bold'>
                                {index + 1}
                            </span>
                        </div>
                        <h5 className='flex gap-1 items-center mb-7'>
                            {' '}
                            <AiIcon />
                            {format(new Date(history.uploaded_at), 'EEEE, dd MMMM yyyy')}
                        </h5>
                        <div className='flex gap-4 items-center justify-center'>
                            <Link
                                to={`${history.id}`}
                                className='btn-primary p-4 w-5/6 !text-lg'
                            >
                                View Answer
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
