import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import Header from "../components/Header";
import CardsInfo from "../components/CardsInfo";
import TableSkeleton from "../Common/Table/TableSkeleton";
import Table from "../Common/Table/Table";
import usePaginationNumbers from "../../../hooks/usePaginationNumbers";
import { dashboardLeadsEndPoint, dashboardLeadsStatisticsEndPoint } from "../../../Services/endPoints";
import group from "../../../assets/Group2.png";
import student from "../../../assets/student.png";
import AIReviewerFilter from '../components/AIReviewerFilter';
import useFetch from "../../../hooks/useFetch";

function AIReviewer() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [refetchTrigger, setRefetchTrigger] = useState(false);
  const navigate = useNavigate();
  const dynamicLeadsEndpoint = `${dashboardLeadsEndPoint}?${searchParams.toString()}`;

  const {
    data: dashboardLeadsData,
    page,
    totalPages,
    isLoading,
    error,
    rowsPerPage,
    handlePageChange,
    setCurrentPage,
    nextPage,
    prevPage,
    setRowsPerPage: handleRowsPerPageChange,
    refetch,
  } = usePaginationNumbers(dynamicLeadsEndpoint, refetchTrigger);

  const reviewerCards = [
    {
      img: <img src={student} alt="group" />,
      title: "Mock-ups",
      value: dashboardLeadsData?.length || "0",
    },
    {
      img: <img src={student} alt="student" />,
      title: "Reviewed",
      value: dashboardLeadsData?.filter((lead) => lead.status === "Approved")
        .length || "0",
    },
    {
      img: <img src={group} alt="student" />,
      title: "Average Review Time",
      value: "2 Days",
    },
  ];

  const {
    data: dashboardStudentStatisticsData,
  } = useFetch(dashboardLeadsStatisticsEndPoint);

  const handleStatusUpdate = async (id, newStatus) => {
    try {
      const response = await axios.patch(
        `https://dfppback.eyouthlearning.com/api/leads-status/${id}/`,
        { status: newStatus }
      );

      if (response.status === 200) {
        console.log("Status updated successfully");
        setRefetchTrigger((prev) => !prev);
        refetch();
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleRowClick = (row) => {
    navigate(`/ai-interviewing/${row.id}`);
  };

  const tableColumns = [
    { header: "ID", accessor: "id" },
    { header: "Student Name", accessor: "name" },
    { header: "Email", accessor: "email" },
    { header: "Phone", accessor: "phone" },
    { header: "Technical Track", accessor: "track" },
    {
      header: "English Score",
      accessor: "score_questions",
      Cell: ({ value }) => (
        <span className={value < 5 ? "text-red-500" : "text-green-500"}>
          ● {value}
        </span>
      ),
    },
    {
      header: "Technical Score",
      accessor: "score_video",
      Cell: ({ value }) => (
        <span className={value < 5 ? "text-red-500" : "text-green-500"}>
          ● {typeof value === "number" ? value : value || "N/A"}
        </span>
      ),
    },
    {
      header: "Status",
      accessor: "status",
      Cell: ({ value }) => (
        <span
          className={`px-3 py-1 rounded-full ${value === "Accepted"
            ? "bg-green-100 text-green-700"
            : value === "Rejected"
              ? "bg-red-100 text-red-700"
              : "bg-yellow-100 text-yellow-700"
            }`}
        >
          {value}
        </span>
      ),
    },
    {
      header: "Action",
      accessor: "action",
      Cell: ({ row }) => (
        <select
          value={row.original.status}
          onClick={(e) => e.stopPropagation()} // Prevent row click event
          onChange={(e) => {
            e.stopPropagation(); // Prevent row click event
            handleStatusUpdate(row.original.id, e.target.value); // Update status
          }}
          className="border border-gray-300 rounded p-1 text-sm"
        >
          <option value="Pending">Pending</option>
          <option value="Accepted">Accepted</option>
          <option value="Rejected">Rejected</option>
        </select>
      ),
    },
  ];

  const tableData =
    dashboardLeadsData?.map((lead) => ({
      id: lead.id,
      name: lead.name,
      email: lead.email,
      phone: lead.phone,
      track: lead.track,
      score_questions: lead.score_questions,
      score_video: lead.score_video,
      status: lead.status,
    })) || [];

  return (
    <div className="p-4">
      <Header>
        <CardsInfo cards={reviewerCards} />
      </Header>

      <AIReviewerFilter
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />

      <div className="mt-6">
        {isLoading ? (
          <TableSkeleton columns={tableColumns.length} rows={5} />
        ) : error ? (
          <p>Error fetching data</p>
        ) : (
          <Table
            columns={tableColumns}
            data={tableData}
            rowsPerPageOptions={[5, 10, 20]}
            isLoading={isLoading}
            currentPage={page}
            totalPages={totalPages}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={handleRowsPerPageChange}
            setCurrentPage={setCurrentPage}
            handlePageChange={handlePageChange}
            prevPage={prevPage}
            nextPage={nextPage}
            handleActionChange={handleStatusUpdate}
            onRowClick={handleRowClick}
          />
        )}
      </div>
    </div>
  );
}

export default AIReviewer;
