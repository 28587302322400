import React from 'react';

function QuestionIcon({ className }) {
  return (
    <div>
      <svg className={className} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.625 3.75C12.6783 3.75 13.205 3.75 13.5834 4.0028C13.7471 4.11223 13.8878 4.25286 13.9972 4.41664C14.25 4.79498 14.25 5.32165 14.25 6.375V13.5C14.25 14.9142 14.25 15.6213 13.8107 16.0607C13.3713 16.5 12.6642 16.5 11.25 16.5H6.75C5.33579 16.5 4.62868 16.5 4.18934 16.0607C3.75 15.6213 3.75 14.9142 3.75 13.5V6.375C3.75 5.32165 3.75 4.79498 4.0028 4.41664C4.11223 4.25286 4.25286 4.11223 4.41664 4.0028C4.79498 3.75 5.32165 3.75 6.375 3.75" stroke="#9E9E9E" stroke-width="2" />
        <path d="M6.75 3.75C6.75 2.92157 7.42157 2.25 8.25 2.25H9.75C10.5784 2.25 11.25 2.92157 11.25 3.75C11.25 4.57843 10.5784 5.25 9.75 5.25H8.25C7.42157 5.25 6.75 4.57843 6.75 3.75Z" stroke="#9E9E9E" stroke-width="2" />
        <path d="M6.75 9L11.25 9" stroke="#9E9E9E" stroke-width="2" stroke-linecap="round" />
        <path d="M6.75 12L9.75 12" stroke="#9E9E9E" stroke-width="2" stroke-linecap="round" />
      </svg>

    </div>
  );
}

export default QuestionIcon;