import React from 'react';
import Star from '../../../Components/svgs/Star';

export default function StudentsRatings({
    averageRating,
    feedbackCount,
    reviews,
}) {
    return (
        <div className='md:col-span-3 flex justify-between sm:items-start flex-col bg-white rounded-xl shadow-lg p-6'>
            <div className='flex justify-between items-center w-full'>
                <h1 className='text-[#273C97] font-bold text-lg'>
                    Student Ratings Overview
                </h1>
                <div className='bg-[#F3F4F6] rounded-lg w-fit py-4 mt-6 mr-1rem text-center'>
                    <h3 className='text-[#273C97] rounded-md px-4 py-2 text-base font-medium bg-white'>
                        Average Rating
                    </h3>
                    <h3 className='text-[#202020] mt-2 text-base font-medium'>
                        {averageRating ? `${averageRating} Stars` : 'N/A'}
                    </h3>
                </div>
            </div>

            <div className='flex sm:mt-auto mt-6 sm:flex-row flex-col gap-4 justify-between items-center'>
                <p className='text-sm font-normal sm:mt-auto mt-4 text-[#000000] sm:w-1/3'>
                    Track how students rate their learning experiences and instructors.
                    This chart highlights the distribution of ratings, allowing you to
                    monitor satisfaction levels and identify areas for improvement
                </p>

                <div className='flex flex-col gap-4'>
                    <div className='flex gap-4 items-center'>
                        <Star
                            starColor={'#4CAF50'}
                            className='bg-[#BDF4BF] w-10 h-10 rounded-sm p-2'
                        />
                        <div>
                            <progress
                                className='progress five-stars-progress w-56'
                                value={(reviews?._5 / feedbackCount) * 100 || 0}
                                max='100'
                            ></progress>
                            <h5 className='text-[#AEAEAE] font-normal text-sm'>
                                {reviews?._5 || 0} out of {feedbackCount || 0} Student
                                <span className='text-[#202020] ml-1'>5-Stars</span>
                            </h5>
                        </div>
                        <h2 className='text-[#696969] font-semibold text-2xl'>
                            {((reviews?._5 / feedbackCount) * 100 || 0).toFixed(1)}%
                        </h2>
                    </div>
                    <div className='flex gap-4 items-center'>
                        <Star
                            starColor={'#90EE90'}
                            className='bg-[#DDF9E4] w-10 h-10 rounded-sm p-2'
                        />
                        <div>
                            <progress
                                className='progress four-stars-progress w-56'
                                value={(reviews?._4 / feedbackCount) * 100 || 0}
                                max='100'
                            ></progress>
                            <h5 className='text-[#AEAEAE] font-normal text-sm'>
                                {reviews?._4 || 0} out of {feedbackCount || 0} Student
                                <span className='text-[#202020] ml-1'>4-Stars</span>
                            </h5>
                        </div>
                        <h2 className='text-[#696969] font-semibold text-2xl'>
                            {((reviews?._4 / feedbackCount) * 100 || 0).toFixed(1)}%
                        </h2>
                    </div>
                    <div className='flex gap-4 items-center'>
                        <Star
                            starColor={'#FFD700'}
                            className='bg-[#FFFADD] w-10 h-10 rounded-sm p-2'
                        />
                        <div>
                            <progress
                                className='progress three-stars-progress w-56'
                                value={(reviews?._3 / feedbackCount) * 100 || 0}
                                max='100'
                            ></progress>
                            <h5 className='text-[#AEAEAE] font-normal text-sm'>
                                {reviews?._3 || 0} out of {feedbackCount || 0} Student
                                <span className='text-[#202020] ml-1'> 3-Stars</span>
                            </h5>
                        </div>
                        <h2 className='text-[#696969] font-semibold text-2xl'>
                            {((reviews?._3 / feedbackCount) * 100 || 0).toFixed(1)}%
                        </h2>
                    </div>
                </div>
                <div className='flex flex-col gap-4'>
                    <div className='flex gap-4 items-center'>
                        <Star
                            starColor={'#FFA500'}
                            className='bg-[#FFEADA] w-10 h-10 rounded-sm p-2'
                        />
                        <div>
                            <progress
                                className='progress two-stars-progress w-56'
                                value={(reviews?._2 / feedbackCount) * 100 || 0}
                                max='100'
                            ></progress>
                            <h5 className='text-[#AEAEAE] font-normal text-sm'>
                                {reviews?._2 || 0} out of {feedbackCount || 0} Student
                                <span className='text-[#202020] ml-1'> 2-Stars</span>
                            </h5>
                        </div>
                        <h2 className='text-[#696969] font-semibold text-2xl'>
                            {((reviews?._2 / feedbackCount) * 100 || 0).toFixed(1)}%
                        </h2>
                    </div>
                    <div className='flex gap-4 items-center'>
                        <Star
                            starColor={'#ED4342'}
                            className='bg-[#FF4C4C66] w-10 h-10 rounded-sm p-2'
                        />
                        <div>
                            <progress
                                className='progress one-star-progress w-56'
                                value={(reviews?._1 / feedbackCount) * 100 || 0}
                                max='100'
                            ></progress>
                            <h5 className='text-[#AEAEAE] font-normal text-sm'>
                                {reviews?._1 || 0} out of {feedbackCount || 0} Student
                                <span className='text-[#202020] ml-1'> 1-Stars</span>
                            </h5>
                        </div>
                        <h2 className='text-[#696969] font-semibold text-2xl'>
                            {((reviews?._1 / feedbackCount) * 100 || 0).toFixed(1)}%
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    );
}
