import React from "react";
import arrowImage from '../../../assets/arrow-up 1.png';

function CardsInfo({ cards }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 bg-white p-4 rounded-xl shadow-md">
      {cards.map((card, index) => (
        <div
          key={index}
          className={`flex items-center gap-4 p-6  ${index !== cards.length - 1 ? "tablet:border-r-2" : "border-none"
            }`}
        >
          {card.img}
          <div>
            <p className="text-xs text-gray-400">{card.title}</p>
            <p className="text-3xl font-bold text-gray-900 my-1">{card.value}</p>
            {card.active && (
              <p className="text-sm">
                <span className="font-bold text-green-600"><img src={arrowImage} className='inline-block mb-1' />{card.active}</span> Active
              </p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default CardsInfo;
