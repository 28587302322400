import React from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import group from "../../../assets/Group2.png";
import instructor from "../../../assets/instructor.png";
import { pageRoutes } from "../../../constants";
import useFetch from '../../../hooks/useFetch';
import usePaginationNumbers from "../../../hooks/usePaginationNumbers";
import { dashboardInstructorsEndPoint, dashboardInstructorsStatisticsEndPoint } from "../../../Services/endPoints";
import Table from "../Common/Table/Table";
import TableSkeleton from "../Common/Table/TableSkeleton";
import CardsInfo from "../components/CardsInfo";
import Header from "../components/Header";
import InstructorFilters from "../components/InstructorFilters";

function Instructors() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // Extract filter parameters from the URL
  const coachingRound = searchParams.get("coaching_round");
  const jobProfileRound = searchParams.get("job_profile_round");

  // Construct the dynamic API URL with filters
  const dynamicInstructorsEndpoint = `${dashboardInstructorsEndPoint}?${coachingRound ? `coaching_round=${coachingRound}&` : ""
    }${jobProfileRound ? `job_profile_round=${jobProfileRound}` : ""}`;

  const {
    data: dashboardInstructorsData,
    page,
    totalPages,
    isLoading,
    error,
    rowsPerPage,
    handlePageChange,
    setCurrentPage,
    nextPage,
    prevPage,
    setRowsPerPage: handleRowsPerPageChange,
  } = usePaginationNumbers(dynamicInstructorsEndpoint, null);

  const {
    data: dashboardInstructorsStatisticsData,
  } = useFetch(dashboardInstructorsStatisticsEndPoint);

  const handleRowClick = (row) => {
    navigate(`/${pageRoutes.instructors}/${pageRoutes.instructorDetails}/${row.id}`)
  }

  const studentCards = [
    {
      img: <img src={group} alt="group" />,
      title: "Instructors",
      value: dashboardInstructorsStatisticsData?.total_instructors || "0",
      active: dashboardInstructorsStatisticsData?.joined_instructors || "0",
    },
    {
      img: <img src={instructor} alt="instructor" />,
      title: "Technical Coaches",
      value: dashboardInstructorsStatisticsData?.instructor_Technical || "0",
      active:
        dashboardInstructorsStatisticsData?.joined_instructor_Technical || "0",
    },
    {
      img: <img src={instructor} alt="instructor" />,
      title: "Webinars Coaches",
      value: dashboardInstructorsStatisticsData?.instructor_Webinars || "0",
      active:
        dashboardInstructorsStatisticsData?.joined_instructor_Webinars || "0",
    },
  ];

  const tableColumns = [
    { header: "Coach ID", accessor: "id" },
    { header: "Name", accessor: "name" },
    { header: "Email", accessor: "email" },
    { header: "Phone", accessor: "phone" },
    { header: "Coach Type", accessor: "coachType" },
    { header: "Job Profiles", accessor: "jobProfiles" },
    { header: "Coaching Rounds", accessor: "coachingRounds" },
    { header: "Status", accessor: "status" },
  ];

  const tableData =
    dashboardInstructorsData?.map((instructor) => ({
      id: instructor.id,
      name: instructor.name,
      email: instructor.user.email,
      phone: instructor.phone,
      coachType: instructor.role,
      jobProfiles: instructor.job_profile_rounds
        ?.map((job) => job.code)
        .join(", ") || "None",
      coachingRounds: instructor.coaching_rounds
        ?.map((round) => round.code)
        .join(", ") || "None",
      status: instructor.user.is_joined ? "Active" : "Inactive",
    })) || [];

  return (
    <div className="p-4">
      <Header>
        <CardsInfo cards={studentCards} />
      </Header>

      <InstructorFilters />

      <div className="mt-6">
        {isLoading ? (
          <TableSkeleton columns={tableColumns.length} rows={5} />
        ) : error ? (
          <p>Error fetching data</p>
        ) : (
          <Table
            columns={tableColumns}
            data={tableData}
            rowsPerPageOptions={[5, 10, 20]}
            isLoading={isLoading}
            currentPage={page}
            totalPages={totalPages}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={handleRowsPerPageChange}
            setCurrentPage={setCurrentPage}
            handlePageChange={handlePageChange}
            prevPage={prevPage}
            nextPage={nextPage}
            onRowClick={handleRowClick}
          />
        )}
      </div>
    </div>
  );
}

export default Instructors;