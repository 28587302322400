import React from "react";

const ArrowDown = ({ className }) => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99998 12.6C9.52498 12.6 10.05 12.3975 10.4475 12L15.3375 7.10998C15.555 6.89248 15.555 6.53248 15.3375 6.31498C15.12 6.09748 14.76 6.09748 14.5425 6.31498L9.65249 11.205C9.29249 11.565 8.70749 11.565 8.34749 11.205L3.45749 6.31498C3.23999 6.09748 2.87998 6.09748 2.66249 6.31498C2.44499 6.53248 2.44499 6.89248 2.66249 7.10998L7.55248 12C7.94998 12.3975 8.47499 12.6 8.99998 12.6Z"
        fill="currentcolor"
      />
    </svg>
  );
};

export default ArrowDown;
