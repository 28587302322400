import React from 'react'

export default function Numbers4({ className }) {
    return (
        <svg
            width={33}
            height={33}
            viewBox="0 0 62 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M7.48259 0.75293H54.3112C58.2635 0.75293 61.4789 3.96834 61.4789 7.92058V36.5911H0.314941V7.92058C0.314941 3.96834 3.53035 0.75293 7.48259 0.75293Z"
                fill="#202020"
            />
            <path
                d="M7.48259 43.7585C3.53035 43.7585 0.314941 40.5431 0.314941 36.5908H61.4789C61.4789 40.5431 58.2635 43.7585 54.3112 43.7585H33.2861V48.5369H41.7695C43.9458 48.5369 45.71 50.3012 45.71 52.4775H16.0838C16.0838 50.3012 17.848 48.5369 20.0243 48.5369H28.5077V43.7585H7.48259Z"
                fill="#202020"
            />
            <path
                d="M38.1227 13.226C38.0462 13.2113 37.9569 13.2113 37.8804 13.226C36.12 13.1529 34.7168 11.5018 34.7168 9.45624C34.7168 7.36683 36.1838 5.68652 38.0079 5.68652C39.8321 5.68652 41.2991 7.38144 41.2991 9.45624C41.2863 11.5018 39.8831 13.1529 38.1227 13.226Z"
                stroke="#F1F8FF"
                strokeWidth={2.58476}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M36.8088 23.8632C38.5564 24.1993 40.4826 23.8486 41.8348 22.8112C43.6334 21.4378 43.6334 19.1876 41.8348 17.8142C40.4699 16.7768 38.5181 16.4261 36.7705 16.7767"
                stroke="#F1F8FF"
                strokeWidth={2.58476}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.7779 13.226C22.8544 13.2113 22.9437 13.2113 23.0203 13.226C24.7806 13.1529 26.1838 11.5018 26.1838 9.45624C26.1838 7.36683 24.7169 5.68652 22.8927 5.68652C21.0685 5.68652 19.6016 7.38144 19.6016 9.45624C19.6143 11.5018 21.0175 13.1529 22.7779 13.226Z"
                stroke="#F1F8FF"
                strokeWidth={2.58476}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M24.0908 23.8627C22.3432 24.1988 20.417 23.8481 19.0648 22.8107C17.2662 21.4373 17.2662 19.1871 19.0648 17.8137C20.4297 16.7763 22.3815 16.4256 24.1291 16.7763"
                stroke="#F1F8FF"
                strokeWidth={2.58476}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M30.4704 24.1405C30.3939 24.1259 30.3046 24.1259 30.228 24.1405C28.4677 24.0675 27.0645 22.4164 27.0645 20.3708C27.0645 18.2814 28.5314 16.6011 30.3556 16.6011C32.1797 16.6011 33.6467 18.296 33.6467 20.3708C33.634 22.4164 32.2308 24.0821 30.4704 24.1405Z"
                stroke="#F1F8FF"
                strokeWidth={2.58476}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M26.7582 28.7431C24.9595 30.1166 24.9595 32.3667 26.7582 33.7402C28.7992 35.3036 32.1413 35.3036 34.1824 33.7402C35.981 32.3667 35.981 30.1166 34.1824 28.7431C32.1541 27.1943 28.7992 27.1943 26.7582 28.7431Z"
                stroke="#F1F8FF"
                strokeWidth={2.58476}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
