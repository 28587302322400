import { roles } from ".";
import AdminRoutes from "../NewPages/AdminRoutes";
import InstructorRoutes from "../NewPages/InstructorRoutes";
import QAInstructorRoutes from "../NewPages/QAInstructorRoutes";
import StudentRoutes from "../NewPages/StudentRoutes";
import WebinarInstructorRoutes from "../NewPages/WebinarInstructorRoutes";

export const roleRoutesLookup = {
  [roles.studentRole]: <StudentRoutes />,
  [roles.instructorRole]: <InstructorRoutes />,
  [roles.webinarInstructorRole]: <WebinarInstructorRoutes />,
  [roles.qaInstructorRole]: <QAInstructorRoutes />,
  [roles.admin]: <AdminRoutes />,
};
