import React, { useState } from "react";
import DelivrablesFilter from "../../Components/DelivrablesFilters/DelivrablesFilters";
import DeliverablesComponent from "../../Components/DelivrablesComponent/DelivrablesComponent";

function Reviews() {
  const [filters, setFilters] = useState({
    coaching_round: "",
    reviewed: "",
  });

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };
  return (
    <div>
      <DelivrablesFilter onFilterChange={handleFilterChange} />
      <DeliverablesComponent />
    </div>
  );
}

export default Reviews;
