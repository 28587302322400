import React, { useState } from 'react';
import { toast } from 'react-toastify';
import putWebinarRecord from '../../../../../Services/apiPutWebinarRecord';

const AddWebinarRecordModal = ({ isOpen, onClose, onSubmit, webinarId }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);

    if (!isOpen) return null;

    const handleSubmit = async (e) => {
        e.preventDefault();
        const link = e.target.record.value;

        setIsSubmitting(true);
        setError(null);

        try {
            await putWebinarRecord(webinarId, link); // Call API with webinarId and link
            toast.success("Webinar record added successfully!");
            onSubmit(link); // Notify parent component
        } catch (err) {
            const backendErrors = err.response?.data || {};
            const errorMsg = backendErrors.link?.join(" ") || "Failed to add webinar record.";
            toast.error(errorMsg)
            setError(errorMsg);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'>
            <div className='bg-white p-6 rounded-lg w-[90%] sm:w-[60%] lg:w-[40%]'>
                <h2 className='text-xl text-[#3F3F3F] font-bold mb-8'>Add Webinar Record</h2>
                <form onSubmit={handleSubmit}>
                    <input
                        type='text'
                        name='record'
                        id='record'
                        placeholder='Enter record URL'
                        className='w-full border rounded-lg p-2 mb-4'
                        required
                    />
                    {error && <p className="text-red-500 ml-3 mb-4">{error}</p>}
                    <div className='flex justify-end mt-6 gap-4'>
                        <button
                            type='submit'
                            disabled={isSubmitting}
                            className='p-3 bg-[#273C97] text-white rounded-lg hover:opacity-80'
                        >
                            {isSubmitting ? 'Submitting...' : 'Add Webinar'}
                        </button>
                        <button
                            type='button'
                            onClick={onClose}
                            className='p-3 border-solid border border-[#EE201C] rounded-lg hover:opacity-80 text-[#EE201C]'
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>

        </div>
    );
};

export default AddWebinarRecordModal;
