import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "./QA.module.css";
import useFetch from "../../../hooks/useFetch";
import arrow from "../../../assets/arrow-down.svg";

import {
  categoryEndPoint,
  questionEndPoint,
} from "../../../Services/endPoints";
import { useSearchParams } from "react-router-dom";
import postQuestion from "../../../Services/apiPostQuestion";
import { CircularProgress } from "@mui/material";
import Loading from "../../../Components/shared/Loading";

export default function QA() {
  const [expanded, setExpanded] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedCategory, setSelectedCategory] = useState("");

  const filter = searchParams.get("filter") === "all" ? "all" : "my";

  // Update endpoint based on filter and selected category
  const filteredQuestionEndPoint =
    filter === "all"
      ? `${questionEndPoint}?all=true${
          selectedCategory ? `&category=${selectedCategory}` : ""
        }`
      : `${questionEndPoint}${
          selectedCategory ? `?category=${selectedCategory}` : ""
        }`;
  // Fetch categories and questions
  const {
    data: categoryData,
    loading: categoryLoading,
    error: categoryError,
  } = useFetch(categoryEndPoint);

  const {
    data: questionData,
    loading: questionLoading,
    error: questionError,
  } = useFetch(filteredQuestionEndPoint);

  useEffect(() => {
    if (categoryData?.length > 0) {
      formik.setFieldValue("category", categoryData[0].id);
    }
  }, [categoryData]);

  const formik = useFormik({
    initialValues: {
      questionText: "",
      category: "",
    },
    validationSchema: Yup.object({
      questionText: Yup.string()
        .required("Question text is required")
        .min(5, "Question must be at least 5 characters"),
      category: Yup.string().required("Category is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await postQuestion(
          values.questionText,
          values.category
        );
        // console.log("Question posted successfully:", response);
        resetForm();
        setErrorMessage("");
        window.location.reload(); // Reload the page to fetch new questions
      } catch (error) {
        console.error("Failed to post question:", error);
        const message =
          error.response?.data || "An error occurred. Please try again.";
        setErrorMessage(
          typeof message === "string" ? message : JSON.stringify(message)
        );
      }
    },
  });

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // Category filter change handler
  const handleCategoryFilterChange = (event) => {
    const selectedCategory = event.target.value;
    setSelectedCategory(selectedCategory);
    // setSearchParams({ filter, category: selectedCategory });

    const params = {};
    if (filter !== "my") params.filter = filter;
    if (selectedCategory) params.category = selectedCategory;
    setSearchParams(params);
  };

  // Filter change handler for all/my questions
  const handleFilterChange = (event) => {
    const selectedFilter = event.target.value;
    // setSearchParams({ filter: selectedFilter, category: selectedCategory });

    const params = {};
    if (selectedFilter !== "my") params.filter = selectedFilter;
    if (selectedCategory) params.category = selectedCategory;
    setSearchParams(params);
  };

  const questions = questionData?.results || [];

  if (questionLoading) {
    return <Loading />;
  }

  if (questionError) {
    return (
      <p className="text-gray-5f text-center font-semibold w-full text-xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        Something went wrong !
      </p>
    );
  }

  return (
    <div className={styles.qaContainer}>
      {/* Submit your question section */}
      <form
        onSubmit={formik.handleSubmit}
        className={styles.submitQuestionSection}
      >
        <div className="w-full flex gap-3 items-center justify-center flex-wrap">
          <input
            type="text"
            name="questionText"
            className={` w-80 border border-solid ${
              formik.touched.questionText && formik.errors.questionText
                ? "border-[red]"
                : "border-[#9D9D9D]"
            } ${styles.inputQA}`}
            placeholder="Write your Question"
            value={formik.values.questionText}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <select
            name="category"
            className="w-80 select-input text-base"
            style={{
              backgroundImage: `url(${arrow})`,
            }}
            value={formik.values.category}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <option value="" disabled>
              Select Category
            </option>
            {categoryData?.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        {formik.touched.questionText && formik.errors.questionText ? (
          <div className="text-[red] text-[0.8rem] font-light ml-3">
            {formik.errors.questionText}
          </div>
        ) : null}
        {formik.touched.category && formik.errors.category ? (
          <div className="text-[red] text-[0.8rem] font-light ml-3">
            {formik.errors.category}
          </div>
        ) : null}

        <button type="submit" className={styles.submitQA}>
          Submit
        </button>
        <p className="text-gray-5f self-start text-base font-semibold mt-1">
          <strong>Note:</strong> The response will be within 48 hours.
        </p>
        {errorMessage && (
          <div className="border border-red-900 p-5 text-red-700">
            {errorMessage}
          </div>
        )}
      </form>

      {questions.length === 0 && (
        <p className="text-gray-5f text-center text-lg font-semibold mt-5">
          This is no questions yet.
        </p>
      )}
      {questions.length > 0 && (
        <>
          <div className="flex place-content-end mb-4 gap-4">
            <select
              name="categoryFilter"
              className="select-input w-60"
              style={{
                backgroundImage: `url(${arrow})`,
              }}
              onChange={handleCategoryFilterChange}
              value={selectedCategory}
            >
              <option value="">All</option>
              {categoryData.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
            <select
              name="allMyFilter"
              className="select-input w-60"
              style={{
                backgroundImage: `url(${arrow})`,
              }}
              onChange={handleFilterChange}
              value={filter}
            >
              <option value="my">My Questions</option>
              <option value="all">All Questions</option>
            </select>
          </div>
          <div className={styles.accordionQAContainer}>
            {questions?.map((question) => (
              <Accordion
                key={question.id}
                expanded={question.answer && expanded === `panel${question.id}`}
                onChange={handleChange(`panel${question.id}`)}
                className={`${styles.accordionQA} ${
                  !question.answer ? styles.disabledAccordion : ""
                }`}
                disableGutters
              >
                <AccordionSummary
                  expandIcon={
                    question.answer ? (
                      <ExpandMoreIcon />
                    ) : (
                      <div className="invisible">
                        <ExpandMoreIcon />
                      </div>
                    )
                  }
                  aria-controls={`panel${question.id}-content`}
                  id={`panel${question.id}-header`}
                  className={!question.answer ? styles.disabledSummary : ""}
                >
                  <div className={styles.accordionHeader}>
                    <h2>{question.question_text}</h2>
                    <div className="flex items-center gap-3">
                      {!question.answer && (
                        <h4 className="border-red-600 border rounded text-red-600 p-1 px-2 h-8  md:text-[1rem] text-[.4rem]">
                          Not Answered
                        </h4>
                      )}
                      <h3 className="border-[#273C97] me-2 border-2 rounded text-[#273C97] p-2 md:text-[1rem] text-[.5rem]">
                        {question.category.name}
                      </h3>
                    </div>
                  </div>
                </AccordionSummary>
                {question.answer && (
                  <AccordionDetails>
                    <p className={styles.accordionBody}>
                      {question.answer.answer}
                    </p>
                  </AccordionDetails>
                )}
              </Accordion>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
