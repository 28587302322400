import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../Helpers/constants";
import { isTokenExpired } from "../../Helpers/isTokenExpired";
import { refreshToken } from "../../Helpers/refreshToken";

const ProtectedLayout = ({ children }) => {
  const navigate = useNavigate();
  const token = getToken(); // Get the current access token

  useEffect(() => {
    // Check if the token is expired
    if (isTokenExpired(token)) {
      refreshToken(navigate); // Try to refresh the token if expired
    }

    if (!token) {
      navigate("/"); // Redirect to login if no token
      return;
    }
  }, [token, navigate]);

  return children; // Return the content if authorized
};

export default ProtectedLayout;
