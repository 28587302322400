import { useState } from "react";
import ArrowWhite from "../../../../../Components/svgs/ArrowWhite";
import ArrowDown from "../../../../../Components/svgs/ArrowDown";

const InfoAccordion = ({ infoName, infoData }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div
        className="flex items-center gap-2 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h4 className="text-primary block text-xs text-center font-semibold">
          {infoName}
        </h4>
        <ArrowDown
          className={`${isOpen ? "" : "rotate-[-90deg]"} text-primary`}
        />
      </div>
      <div className={`${isOpen ? "flex" : "hidden"} flex-col gap-2`}>
        {infoData?.map((info, index) => (
          <p className="block text-gray-5f font-normal text-xs" key={info.code}>
            {info?.code}
          </p>
        ))}
      </div>
    </>
  );
};

export default InfoAccordion;
