import React from 'react'

export default function QAIconAdmin({ className }) {
    return (
        <svg
            width={21}
            height={15}
            viewBox="0 0 21 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M19.3043 13.5151C18.5402 12.9328 18.0013 12.095 17.6837 11.4946C17.57 11.2794 17.4756 11.0784 17.4001 10.905H18.0894C18.8215 10.905 19.4172 10.3093 19.4172 9.57714V5.06416C19.4172 4.33204 18.8215 3.73633 18.0894 3.73633H14.0532V4.3091H18.0894C18.5057 4.3091 18.8444 4.64782 18.8444 5.06416V9.57714C18.8444 9.99349 18.5057 10.3322 18.0894 10.3322H16.6036L16.6091 10.4375L16.6198 10.4716C16.6236 10.4839 16.6749 10.6475 16.7787 10.905C16.8688 11.1286 16.9986 11.4231 17.1714 11.7508C17.3899 12.1655 17.6327 12.5484 17.8928 12.889C17.9835 13.0076 18.0766 13.1214 18.1717 13.2298C17.9126 13.1512 17.6146 13.0475 17.2926 12.9113C16.4819 12.5686 15.3749 11.9555 14.3631 10.905C14.1917 10.7268 14.023 10.5361 13.8589 10.3322H8.28236C7.86602 10.3322 7.5273 9.99349 7.5273 9.57714V7.7624H6.95453V9.57714C6.95453 10.3093 7.55023 10.905 8.28236 10.905H13.5899C14.0705 11.4673 14.6171 11.9697 15.2194 12.4023C15.7968 12.817 16.4261 13.1685 17.09 13.4476C18.2243 13.9242 19.0633 14.0234 19.0985 14.0274L20.1307 14.1448L19.3043 13.5151Z"
                fill="white"
            />
            <path
                d="M13.5426 5.9364V1.42354C13.5426 0.691414 12.9469 0.0957031 12.2148 0.0957031H2.4079C1.67578 0.0957031 1.08007 0.6914 1.08007 1.42354V5.9364C1.08007 6.66852 1.67576 7.26423 2.4079 7.26423H3.0971C3.02159 7.43772 2.92716 7.63882 2.81354 7.85382C2.49599 8.45422 1.95709 9.29208 1.19285 9.87437L0.366394 10.5041L1.39879 10.3867C1.43396 10.3827 2.27298 10.2834 3.40723 9.80687C4.07115 9.52778 4.70048 9.17623 5.27783 8.76154C5.69664 8.46069 6.08864 8.1261 6.44994 7.76103C6.60835 7.6012 6.7609 7.43548 6.9072 7.26423H12.2148C12.9469 7.26423 13.5426 6.66854 13.5426 5.9364ZM9.25381 6.09224C8.96483 6.09224 8.62154 6.06213 8.44088 6.01403C8.09358 5.91841 7.5401 5.54076 7.08294 5.2191C5.99116 5.21439 5.11695 4.36677 5.11695 3.20795C5.11695 2.13004 5.88166 1.13047 7.15233 1.13047C8.18202 1.13047 9.10326 1.91928 9.10326 3.11762C9.10326 4.11967 8.56214 4.82945 7.8039 5.09913C8.32268 5.46784 8.84864 5.78245 9.29603 5.94781L9.25381 6.09224Z"
                fill="white"
            />
            <path
                d="M8.48922 3.28559C8.48922 2.02104 7.82084 1.35254 7.03202 1.35254C6.35752 1.35254 5.74335 1.9186 5.74335 3.04472C5.74335 4.08029 6.26284 4.85605 7.01143 4.97402L7.43541 4.82711C7.48235 4.86287 7.52938 4.89828 7.57667 4.93332C8.08947 4.76761 8.48922 4.23237 8.48922 3.28559Z"
                fill="white"
            />
            <path
                d="M13.3561 9.21126H14.7471V9.04264C14.2534 8.98242 14.1691 8.93425 14.3015 8.54285C14.3858 8.29596 14.4762 8.06112 14.5725 7.79014H15.8732C15.9997 8.1695 16.1201 8.47059 16.1984 8.70543C16.2827 8.93425 16.2345 8.99447 15.8431 9.04264V9.21126H17.3304V9.04264C16.9029 8.99448 16.8487 8.9463 16.656 8.42241C16.2767 7.38067 15.8612 6.24259 15.4818 5.21289L15.2891 5.24903L14.0426 8.41037C13.8319 8.95232 13.7355 8.99447 13.3562 9.04264V9.21126H13.3561ZM15.2409 6.00173L15.7889 7.54327H14.6628C14.8435 7.06757 15.0482 6.5196 15.2409 6.00173Z"
                fill="white"
            />
        </svg>
    )
}
