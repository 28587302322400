import axios from 'axios';
import { InstructorWebinar } from './endPoints';

const updateWebinarStatus = async (id, isFinished) => {
    try {
        const response = await axios.put(
            `${InstructorWebinar}${id}/update-status/`,
            { is_finished: isFinished },
        );
        return response.data;
    } catch (error) {
        console.error('Error updating webinar status:', error);
        throw error;
    }
};

export default updateWebinarStatus;
