import React from 'react';
import { useTranslation } from 'react-i18next';
import heroImage from '../../../assets/heroPhoto.png';
import heroSmallImage from '../../../assets/hero2.png';
import ScrollDown from '../../../Components/svgs/ScrollDown';

export default function HeroSection() {
    const { t, i18n } = useTranslation();
    const isRTL = i18n.language === 'ar';

    return (
        <div className='relative'>
            <div className='flex flex-col-reverse sm:flex-row flex-wrap gap-4 mt-6 custom-container'>
                {/* Left Section */}
                <div className='sm:flex-1 mt-8 xl:mt-24 relative'>
                    {/* Text Content */}
                    <h1 className='text-2xl sm:text-5xl w-auto font-bold text-[#273C97] mb-5 sm:mb-8'>
                        {t('heroTitle')}
                    </h1>
                    <p className='text-xl sm:text-2xl xl:leading-loose w-auto font-normal text-[#767676] mb-8'>
                        {t('heroDescription')}
                    </p>

                    {/* Hero Small Image */}
                    <img
                        src={heroSmallImage}
                        alt='hero-other-photo'
                        className={`absolute hidden 2xl:block ${isRTL ? '-right-24 scale-x-[-1]' : '-left-24'
                            } top-[calc(55%+1rem)] w-auto`}
                    />
                </div>

                {/* Hero Image */}
                <div
                    className={`flex-shrink-0 xl:w-1/2 max-w-3xl ${isRTL ? 'scale-x-[-1] -ml-[5.5rem]' : '-mr-[5.5rem]'
                        }`}
                >
                    <img
                        src={heroImage}
                        alt='hero-photo'
                        className='w-full h-auto max-w-[100%] 2xl:max-w-[140%]'
                    />
                </div>
            </div>

            <div className='absolute hidden sm:block -bottom-7 left-1/2 transform -translate-x-1/2'>
                <ScrollDown className='hover:animate-bounce' />
            </div>
        </div>
    );
}