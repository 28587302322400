import React from 'react'

export default function Star({ className, starColor }) {
    return (
        <svg
            width={22}
            height={22}
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M10.6713 0.898319C10.7834 0.592006 11.2166 0.592007 11.3287 0.89832L13.8393 7.75991C13.8882 7.89349 14.013 7.98421 14.1552 7.98941L21.4568 8.25679C21.7827 8.26873 21.9166 8.68075 21.6599 8.88199L15.91 13.3901C15.798 13.4778 15.7503 13.6246 15.7893 13.7614L17.7913 20.7882C17.8807 21.1019 17.5302 21.3566 17.2595 21.1746L11.1952 17.0992C11.0772 17.0199 10.9228 17.0199 10.8048 17.0992L4.74051 21.1746C4.46979 21.3566 4.11931 21.1019 4.20868 20.7882L6.2107 13.7614C6.24968 13.6246 6.20198 13.4778 6.09004 13.3901L0.340104 8.88199C0.0834164 8.68074 0.21729 8.26873 0.543245 8.25679L7.84482 7.98941C7.98697 7.98421 8.11183 7.89349 8.1607 7.75991L10.6713 0.898319Z"
                fill={starColor}
            />
        </svg>
    )
}
