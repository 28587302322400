import React from "react";

const EmailIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="14"
      height="9"
      viewBox="0 0 14 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.90255 0H11.8401C12.7776 0 13.2501 0.4425 13.2501 1.3425V7.6575C13.2501 8.55 12.7776 9 11.8401 9H1.90255C0.965054 9 0.492554 8.55 0.492554 7.6575V1.3425C0.492554 0.4425 0.965054 0 1.90255 0ZM6.86755 6.45L11.9226 2.3025C12.1026 2.1525 12.2451 1.8075 12.0201 1.5C11.8026 1.1925 11.4051 1.185 11.1426 1.3725L6.86755 4.2675L2.60005 1.3725C2.33755 1.185 1.94005 1.1925 1.72255 1.5C1.49755 1.8075 1.64005 2.1525 1.82005 2.3025L6.86755 6.45Z"
        fill="currentcolor"
      />
    </svg>
  );
};

export default EmailIcon;
