import React from 'react'

export default function Details1({ className }) {
    return (
        <svg
            width={80}
            height={70}
            viewBox="0 0 58 66"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M57.7008 61.3802V51.1302C57.7008 48.2602 57.2908 45.3902 55.6508 42.5202C54.0108 39.6502 51.9608 37.1902 49.0908 35.5502C46.2208 33.5002 40.0708 33.0902 37.2008 33.0902L30.6408 40.0602L33.1008 45.3902V57.6902L29.0008 62.2002L24.9008 57.6902V45.3902L27.7708 40.0602L20.8008 33.0902C17.5208 33.0902 11.3708 33.5002 8.50078 35.5502C5.63078 37.1902 3.99078 39.6502 2.35078 42.5202C0.710781 45.3902 0.300781 47.8502 0.300781 51.1302V61.3802C0.300781 61.3802 10.9608 65.8902 29.0008 65.8902C47.0408 65.8902 57.7008 61.3802 57.7008 61.3802ZM29.0008 0.700195C21.2108 0.700195 16.7008 8.0802 17.9308 16.2802C19.1608 24.4802 23.2608 30.2202 29.0008 30.2202C34.7408 30.2202 38.8408 24.4802 40.0708 16.2802C41.3008 7.6702 36.7908 0.700195 29.0008 0.700195Z"
                fill="#202020"
            />
        </svg>
    )
}
