import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { CoachingRoundEndPoint, jobProfileRoundsEndPoint } from "../../../Services/endPoints";

function InstructorFilters() {
  const { data: coachingRoundsData } = useFetch(CoachingRoundEndPoint);
  const coachingRounds = Array.isArray(coachingRoundsData?.results)
    ? coachingRoundsData.results
    : [];

  const { data: jobProfileRoundData } = useFetch(jobProfileRoundsEndPoint);
  const jobRounds = Array.isArray(jobProfileRoundData?.results)
    ? jobProfileRoundData.results
    : [];

  const [searchParams, setSearchParams] = useSearchParams();

  const [isCoachingRoundsDropdownOpen, setCoachingRoundsDropdownOpen] =
    useState(false);
  const [isJobProfileDropdownOpen, setJobProfileDropdownOpen] = useState(false);

  const handleSelectCoachingRound = (round) => {
    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.set("coaching_round", round);
    setSearchParams(updatedParams);
    setCoachingRoundsDropdownOpen(false);
  };

  const handleSelectJobProfile = (job) => {
    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.set("job_profile_round", job);
    setSearchParams(updatedParams);
    setJobProfileDropdownOpen(false);
  };

  const handleClearFilters = () => {
    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.delete("coaching_round");
    updatedParams.delete("job_profile_round");
    setSearchParams(updatedParams);
  };

  const selectedCoachingRound = searchParams.get("coaching_round");
  const selectedJobProfile = searchParams.get("job_profile_round");

  const handleOutsideClick = (e) => {
    if (!e.target.closest(".dropdown")) {
      setCoachingRoundsDropdownOpen(false);
      setJobProfileDropdownOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, []);

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <div className="flex items-center gap-4 flex-wrap">
        <div className="relative dropdown">
          <button
            onClick={() =>
              setCoachingRoundsDropdownOpen(!isCoachingRoundsDropdownOpen)
            }
            className="px-4 py-2 bg-gray-100 border border-gray-300 rounded-full text-sm"
          >
            {"Coaching Rounds"}
            <span className="ml-2">&#9662;</span>
          </button>
          {isCoachingRoundsDropdownOpen && (
            <div className="absolute top-12 left-0 w-48 bg-white border border-gray-200 rounded-lg shadow-md z-10">
              {coachingRounds.map((round) => (
                <div
                  key={round.code}
                  onClick={() => handleSelectCoachingRound(round.code)}
                  className="px-4 py-2 text-sm cursor-pointer hover:bg-gray-100"
                >
                  {round.code}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="relative dropdown">
          <button
            onClick={() =>
              setJobProfileDropdownOpen(!isJobProfileDropdownOpen)
            }
            className="px-4 py-2 bg-gray-100 border border-gray-300 rounded-full text-sm"
          >
            {"Job Profiles"}
            <span className="ml-2">&#9662;</span>
          </button>
          {isJobProfileDropdownOpen && (
            <div className="absolute top-12 left-0 w-48 bg-white border border-gray-200 rounded-lg shadow-md z-10">
              {jobRounds.map((job) => (
                <div
                  key={job.code}
                  onClick={() => handleSelectJobProfile(job.code)}
                  className="px-4 py-2 text-sm cursor-pointer hover:bg-gray-100"
                >
                  {job.code}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="mt-4 flex items-center flex-wrap gap-2">
        {selectedCoachingRound && (
          <div className="flex items-center px-3 py-1 text-sm border border-gray-300 rounded-full">
            Coaching Round:{selectedCoachingRound}
            <button
              onClick={() => handleSelectCoachingRound("")}
              className="ml-2 text-gray-500 hover:text-red-500"
            >
              ✖
            </button>
          </div>
        )}
        {selectedJobProfile && (
          <div className="flex items-center px-3 py-1 text-sm border border-gray-300 rounded-full">
            Job Profile: {selectedJobProfile}
            <button
              onClick={() => handleSelectJobProfile("")}
              className="ml-2 text-gray-500 hover:text-red-500"
            >
              ✖
            </button>
          </div>
        )}

        {(selectedCoachingRound || selectedJobProfile) && (
          <button
            onClick={handleClearFilters}
            className="px-4 py-1 text-sm bg-gray-100 border border-gray-300 rounded-full hover:bg-gray-200"
          >
            Clear All
          </button>
        )}
      </div>
    </div>
  );
}

export default InstructorFilters;
