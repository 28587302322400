import React from 'react';
import DonutChart from '../components/DonutChart';

const StatisticsCard = ({
    title,
    description,
    averageTimeTitle,
    averageTimeValue,
    totalItemsLabel,
    totalItemsValue,
    chartData,
    chartLabels,
    chartColors,
}) => {
    return (
        <div className="sm:col-span-3 tablet:col-span-2 flex justify-between sm:items-start items-center sm:flex-row flex-col-reverse bg-white rounded-xl shadow-lg p-6">
            <div className="sm:w-1/2 my-auto">
                <p className="text-[#000000] text-base font-normal mt-6">
                    {description}
                </p>
                {averageTimeTitle && averageTimeValue && (
                    <div className="bg-[#F3F4F6] rounded-lg w-fit py-4 mt-6 mx-auto text-center">
                        <h3 className="text-[#273C97] rounded-md px-4 py-2 text-base font-medium bg-white">
                            {averageTimeTitle}
                        </h3>
                        <h3 className="text-[#202020] mt-2 text-base font-medium">
                            {averageTimeValue}
                        </h3>
                    </div>
                )}
            </div>
            <div>
                <h2 className="text-[#000000] text-xl font-medium mb-2">
                    {title}
                </h2>
                <h3 className="text-lg text-[#273C97] font-normal mb-4">
                    {totalItemsLabel}:{' '}
                    <span className="text-lg text-[#263238] font-medium">
                        {totalItemsValue}
                    </span>
                </h3>
                <DonutChart
                    data={chartData}
                    labels={chartLabels}
                    customOptions={{
                        colors: chartColors,
                    }}
                />
            </div>
        </div>
    );
};

export default StatisticsCard;