import MobileNavBar from "../../Common/MobileNavBar/MobileNavBar";
import NavSideBar from "../../Common/NavSideBar";
import ProtectedLayout from "../../layouts/ProtectedLayout";
import { Outlet } from "react-router-dom";
import MobileAdminInfo from './MobileAdminInfo';

const AdminLayout = () => {
  return (
    <ProtectedLayout>

      <div className="tablet:bg-[#f8f8f8] bg-white gap-x-7 items-start flex tablet:flex-nowrap flex-wrap">
        <NavSideBar />
        <MobileNavBar>
          <MobileAdminInfo />
        </MobileNavBar>

        <div
          className={`relative z-[98]  tablet:px-[1rem] px-[5%] w-[100%]  tablet:mt-[2rem] mt-[5rem] tablet:rounded-xl tablet:h-fit h-full min-h-[90vh] flex-1`}
        >
          <Outlet />
        </div>

      </div>
    </ProtectedLayout>
  );
};

export default AdminLayout;
