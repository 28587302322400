import React from 'react';
import eyouthLogo from '../../../assets/EYouthLogo.png';
import itidaLogo from '../../../assets/itida.jpeg';
import { useTranslation } from 'react-i18next';

export default function LandingFooter() {
    const { t, i18n } = useTranslation();
    const isRTL = i18n.language === 'ar';

    return (
        <div className='custom-container m-auto text-center py-8 my-8'>
            <div className='flex flex-col gap-4 justify-center items-center'>
                <div className=''>
                    <h1 className='text-[#273C97] text-xl sm:text-3xl font-semibold mb-2'>{t('providedBy')}</h1>
                    <img src={itidaLogo} alt="Ititad logo" className='cover-image' />
                </div>

                <div className=''>
                    <h2 className='text-[#273C97] text-xl sm:text-3xl font-semibold mb-4'>{t('footerTitle')}</h2>
                    <img src={eyouthLogo} alt="EYouth logo" className="cover-image" />
                </div>
            </div>
        </div >
    )
}
