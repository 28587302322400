import {
  Person as PersonIcon,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Alert,
  Button,
  IconButton,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { loginUser } from "../../../API/Api";
import logo1 from "../../../assets/EYouthLogo.png";
import ItidaLogo from "../../../assets/logo.png";
import { pageRoutes } from "../../../constants";
import styles from "./Login.module.css";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(4, "Password must be at least 4 characters")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const res = await loginUser(values.email, values.password);
        console.log('res', res);
        if (res.access) {
          localStorage.setItem("access_token", res.access);
          localStorage.setItem("refresh_token", res.refresh);
          localStorage.setItem("role", res.role);
          localStorage.setItem("user_email", values.email);
          window.location.href = `/${pageRoutes.dashboard}`;
        } else {
          setAlertMessage("Invalid credentials. Please try again.");
          setAlert(true);
        }
      } catch (error) {
        console.error("Login failed:", error);
        setAlertMessage("An error occurred. Please try again later.");
        setAlert(true);
      }
    },
  });

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();

  return (
    <div className={styles.loginContainer}>
      <div className={styles.overlay}></div>
      <div className={styles.loginBox}>
        <div className={styles.loginForm}>
          <h1>Login</h1>
          <h2>Welcome Back!</h2>
          <h3>Ready to Learn and Grow?</h3>
          <form
            onSubmit={formik.handleSubmit}
            className="w-100 d-flex justify-content-center flex-column align-items-center"
          >
            <div className="d-flex gap-2 flex-column w-50 justify-content-center align-items-center">
              <div className="d-flex justify-content-start w-100">
                <InputLabel htmlFor="email" className={styles.inputLabel}>
                  Email
                </InputLabel>
              </div>
              <TextField
                fullWidth
                focused
                color="primary"
                id="email"
                name="email"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                className={styles.test}
              />

              <div className="d-flex justify-content-start w-100">
                <InputLabel htmlFor="password" className={styles.inputLabel}>
                  Password
                </InputLabel>
              </div>
              <TextField
                fullWidth
                focused
                color="primary"
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        className={styles.togglePassword}
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </div>
            <div className={styles.loginFormBtn}>
              <Button
                type="submit"
                className={styles.submitBtn}
                fullWidth
                variant="contained"
              >
                Login
              </Button>
            </div>
            <div className={styles.logoContainer}>
              <img src={logo1} alt="Ititda" className={styles.logo2} />
              <a
                href="https://itida.gov.eg/English/Pages/default.aspx"
                target="_blank"
                rel="noreferrer"
              >
                <img src={ItidaLogo} alt="ITIDA" className={styles.logo1} />
              </a>
            </div>
          </form>
          {alert && (
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert severity="error">{alertMessage}</Alert>
            </Stack>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
