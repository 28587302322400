import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { CoachingRoundEndPoint } from "../../../Services/endPoints";

function ReviewsFilters() {
    // Fetch coaching rounds
    const { data: coachingRoundsData } = useFetch(CoachingRoundEndPoint);
    const coachingRounds = Array.isArray(coachingRoundsData?.results)
        ? coachingRoundsData.results
        : [];

    // Static mockups
    const staticMockups = ["Q2", "Q3"];

    const [searchParams, setSearchParams] = useSearchParams();

    const [isCoachingRoundsDropdownOpen, setCoachingRoundsDropdownOpen] =
        useState(false);
    const [isStaticMockupsDropdownOpen, setStaticMockupsDropdownOpen] =
        useState(false);

    // Handle filter selections
    const handleSelectCoachingRound = (round) => {
        const updatedParams = new URLSearchParams(searchParams);
        updatedParams.set("coaching_round", round);
        setSearchParams(updatedParams);
        setCoachingRoundsDropdownOpen(false);
    };

    const handleSelectStaticMockup = (mockup) => {
        const updatedParams = new URLSearchParams(searchParams);
        updatedParams.set("deliverable", mockup);
        setSearchParams(updatedParams);
        setStaticMockupsDropdownOpen(false);
    };

    const handleClearFilters = () => {
        const updatedParams = new URLSearchParams(searchParams);
        updatedParams.delete("coaching_round");
        updatedParams.delete("deliverable");
        setSearchParams(updatedParams);
    };

    // Get selected filters
    const selectedCoachingRound = searchParams.get("coaching_round");
    const selectedStaticMockup = searchParams.get("deliverable");

    // Close dropdowns when clicking outside
    const handleOutsideClick = (e) => {
        if (!e.target.closest(".dropdown")) {
            setCoachingRoundsDropdownOpen(false);
            setStaticMockupsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleOutsideClick);
        return () => document.removeEventListener("click", handleOutsideClick);
    }, []);

    return (
        <div className="p-4 bg-white rounded-lg shadow-md">
            <div className="flex items-center gap-4 flex-wrap">
                {/* Coaching Rounds Filter */}
                <div className="relative dropdown">
                    <button
                        onClick={() =>
                            setCoachingRoundsDropdownOpen(!isCoachingRoundsDropdownOpen)
                        }
                        className="px-4 py-2 bg-gray-100 border border-gray-300 rounded-full text-sm"
                    >
                        {"Coaching Rounds"}
                        <span className="ml-2">&#9662;</span>
                    </button>
                    {isCoachingRoundsDropdownOpen && (
                        <div className="absolute top-12 left-0 w-48 bg-white border border-gray-200 rounded-lg shadow-md z-10">
                            {coachingRounds.map((round) => (
                                <div
                                    key={round.code}
                                    onClick={() => handleSelectCoachingRound(round.code)}
                                    className="px-4 py-2 text-sm cursor-pointer hover:bg-gray-100"
                                >
                                    {round.code}
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                {/* Static Mockups Filter */}
                <div className="relative dropdown">
                    <button
                        onClick={() =>
                            setStaticMockupsDropdownOpen(!isStaticMockupsDropdownOpen)
                        }
                        className="px-4 py-2 bg-gray-100 border border-gray-300 rounded-full text-sm"
                    >
                        {" Mockups"}
                        <span className="ml-2">&#9662;</span>
                    </button>
                    {isStaticMockupsDropdownOpen && (
                        <div className="absolute top-12 left-0 w-48 bg-white border border-gray-200 rounded-lg shadow-md z-10">
                            {staticMockups.map((mockup) => (
                                <div
                                    key={mockup}
                                    onClick={() => handleSelectStaticMockup(mockup)}
                                    className="px-4 py-2 text-sm cursor-pointer hover:bg-gray-100"
                                >
                                    {mockup}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            {/* Selected Filters */}
            <div className="mt-4 flex items-center flex-wrap gap-2">
                {selectedCoachingRound && (
                    <div className="flex items-center px-3 py-1 text-sm border border-gray-300 rounded-full">
                        Coaching Round: {selectedCoachingRound}
                        <button
                            onClick={() => handleSelectCoachingRound("")}
                            className="ml-2 text-gray-500 hover:text-red-500"
                        >
                            ✖
                        </button>
                    </div>
                )}
                {selectedStaticMockup && (
                    <div className="flex items-center px-3 py-1 text-sm border border-gray-300 rounded-full">
                        Static Mockup: {selectedStaticMockup}
                        <button
                            onClick={() => handleSelectStaticMockup("")}
                            className="ml-2 text-gray-500 hover:text-red-500"
                        >
                            ✖
                        </button>
                    </div>
                )}

                {/* Clear All Button */}
                {(selectedCoachingRound || selectedStaticMockup) && (
                    <button
                        onClick={handleClearFilters}
                        className="px-4 py-1 text-sm bg-gray-100 border border-gray-300 rounded-full hover:bg-gray-200"
                    >
                        Clear All
                    </button>
                )}
            </div>
        </div>
    );
}

export default ReviewsFilters;
