import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the datepicker styles
import { format } from "date-fns"; // To format the date

function WebinarsFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [isStatusDropdownOpen, setStatusDropdownOpen] = useState(false);

  // State for filters
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(searchParams.get("status") || "");

  // Handle status filter
  const handleSelectStatus = (status) => {
    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.set("status", status);
    setSearchParams(updatedParams);
    setSelectedStatus(status);
    setStatusDropdownOpen(false);
  };

  // Handle date filter
  const handleDateChange = (date) => {
    const formattedDate = format(date, "yyyy-MM-dd"); 
    // console.log('formattedDate', formattedDate)
    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.set("date", formattedDate);
    setSearchParams(updatedParams);
    setSelectedDate(date);
  };

  // Clear all filters
  const handleClearFilters = () => {
    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.delete("status");
    updatedParams.delete("date");
    setSearchParams(updatedParams);
    setSelectedDate(null);
    setSelectedStatus("");
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <div className="flex items-center gap-4 flex-wrap">
        {/* Date Picker */}
        <div className="relative">
          <ReactDatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="yyyy-MM-dd"
            placeholderText="Select Date"
            className="px-4 py-2 bg-gray-100 border border-gray-300 rounded-full text-sm"
          />
        </div>

        {/* Status Filter */}
        <div className="relative dropdown">
          <button
            onClick={() => setStatusDropdownOpen(!isStatusDropdownOpen)}
            className="px-4 py-2 bg-gray-100 border border-gray-300 rounded-full text-sm"
          >
            {selectedStatus || "Select Status"}
            <span className="ml-2">&#9662;</span>
          </button>
          {isStatusDropdownOpen && (
            <div className="absolute top-12 left-0 w-48 bg-white border border-gray-200 rounded-lg shadow-md z-10">
              {["True", "False"].map((status) => (
                <div
                  key={status}
                  onClick={() => handleSelectStatus(status)}
                  className="px-4 py-2 text-sm cursor-pointer hover:bg-gray-100"
                >
                  {status}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Selected Filters */}
      <div className="mt-4 flex items-center flex-wrap gap-2">
        {selectedDate && (
          <div className="flex items-center px-3 py-1 text-sm border border-gray-300 rounded-full">
            Date: {format(selectedDate, "yyyy-MM-dd")}
            <button
              onClick={() => handleDateChange(null)}
              className="ml-2 text-gray-500 hover:text-red-500"
            >
              ✖
            </button>
          </div>
        )}
        {selectedStatus && (
          <div className="flex items-center px-3 py-1 text-sm border border-gray-300 rounded-full">
            Status: {selectedStatus}
            <button
              onClick={() => handleSelectStatus("")}
              className="ml-2 text-gray-500 hover:text-red-500"
            >
              ✖
            </button>
          </div>
        )}

        {/* Clear All Button */}
        {(selectedDate || selectedStatus) && (
          <button
            onClick={handleClearFilters}
            className="px-4 py-1 text-sm bg-gray-100 border border-gray-300 rounded-full hover:bg-gray-200"
          >
            Clear All
          </button>
        )}
      </div>
    </div>
  );
}

export default WebinarsFilter;
