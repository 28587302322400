import React, { useState } from 'react';
import imageRecorded from '../../../assets/icon (1).png';
import imageRecorded2 from '../../../assets/icon (2).png';
import AIInterviewingCards from '../components/AIInterviewingCards';
import { useParams } from 'react-router-dom';
import { dashboardAIInterveiwingEndPoint } from '../../../Services/endPoints';
import useFetch from '../../../hooks/useFetch';
import CloseIcon from '@mui/icons-material/Close';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import axios from 'axios';

function AIInterviewing() {
    const { id } = useParams();
    const [refetchTrigger, setRefetchTrigger] = useState(false);
    const { data: dashboardAIInterveiwingData, refetch } = useFetch(`${dashboardAIInterveiwingEndPoint}${id}/`, [refetchTrigger]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');


    if (!dashboardAIInterveiwingData) {
        return <p>Loading...</p>;
    }

    const {
        name,
        email,
        phone,
        status,
        first_interview,
        second_interview,
    } = dashboardAIInterveiwingData;

    const openModal = (url) => {
        setVideoUrl(url);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setVideoUrl('');
    };

    const handleStatusUpdate = async (id, newStatus) => {
        try {
            const response = await axios.patch(
                `https://dfppback.eyouthlearning.com/api/leads-status/${id}/`,
                { status: newStatus }
            );

            if (response.status === 200) {
                console.log("Status updated successfully");
                setRefetchTrigger((prev) => !prev);
                refetch(); // Re-fetch the data to update the status in the UI
            }
        } catch (error) {
            console.error("Error updating status:", error);
        }
    };


    return (
        <div className="p-6 space-y-8">
            <AIInterviewingCards
                id={id}
                handleStatusUpdate={handleStatusUpdate}
                email={email}
                phone={phone}
                status={status}
                name={name}
                englishScore={first_interview?.score}
                QuestionScore={second_interview?.score}
                category={second_interview?.category}
            />

            {first_interview && (
                <div className="bg-gray-50 p-6 rounded-lg shadow-lg">
                    <div className="flex items-center space-x-4">
                        <img
                            src={imageRecorded}
                            alt="Video Thumbnail"
                            className="w-16 h-16 object-cover rounded-full"
                        />
                        <div>
                            <h2 className="text-lg font-semibold">Recorded Video</h2>
                        </div>
                        <div className="flex justify-end w-[75%]">
                            <span className="px-3 py-1 text-sm font-medium text-white bg-blue-500 rounded">
                                English Score: {first_interview?.score}
                            </span>
                        </div>
                    </div>
                    <div className="relative" onClick={() => openModal(first_interview.video_file)}>
                        <video
                            src={first_interview.video_file}
                            className="w-[25%] h-full object-cover rounded-md"
                            controls={false}
                            muted
                        />
                        <PlayCircleFilledWhiteOutlinedIcon
                            className="absolute top-[50%] left-[10%] text-white cursor-pointer"
                            style={{
                                fontSize: '70px',
                            }}
                        />
                    </div>
                    <div className="mt-4">
                        <div className="flex items-center">
                            <img src={imageRecorded2} alt="Interview Comments" />
                            <h3 className="text-lg font-semibold border-b pb-2 ml-2">Interview Comments</h3>
                        </div>
                        <p className="mt-4 text-gray-700 text-sm whitespace-pre-wrap">{first_interview?.ai_response}</p>
                    </div>
                </div>
            )}

            {/* Technical Test Section */}
            {second_interview && (
                <div className="bg-gray-50 p-6 rounded-lg shadow-lg">
                    <div className="flex justify-between items-center border-b pb-2 mb-4">
                        <h3 className="text-lg font-semibold">Technical Test</h3>
                        <span className="px-3 py-1 text-sm font-medium text-white bg-green-500 rounded">
                            Technical Score: {second_interview?.score}
                        </span>
                    </div>
                    <div className="space-y-6">
                        {second_interview.questions_answers.map((qa) => {
                            const isCorrectAnswer = qa.correct_answer === qa.answer;

                            return (
                                <div key={qa.id} className="p-4 border rounded-lg bg-white">
                                    <p className="font-medium text-gray-800">{qa.question}</p>
                                    <ul className="mt-2 space-y-2">
                                        {qa.choices.map((choice, index) => {
                                            const choiceNumber = (index + 1).toString();
                                            const isCorrect = qa.correct_answer === choiceNumber;
                                            const isUserAnswer = qa.answer === choiceNumber;

                                            return (
                                                <li key={index} className="flex items-center space-x-2">
                                                    <span
                                                        className={`block w-4 h-4 rounded-full ${isCorrect
                                                            ? 'bg-green-500'
                                                            : isUserAnswer
                                                                ? 'bg-red-500'
                                                                : 'bg-gray-300'
                                                            }`}
                                                    ></span>
                                                    <span className="text-sm text-gray-700">{choice}</span>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}

            {/* Video Popup Modal */}
            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg shadow-lg p-6 max-w-2xl w-full relative">
                        <div className="flex justify-end my-2">
                            <CloseIcon className="cursor-pointer" onClick={closeModal} />
                        </div>
                        <h1>{name} Interview </h1>
                        <video controls src={videoUrl} className="w-full h-auto rounded-md" />
                        <div className="flex justify-center">
                            <button onClick={closeModal} className="btn btn-error my-2 mx-auto text-white">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AIInterviewing;
