import React from 'react'
import StatisticsCard from './StatisticsCard'
import InfoCard from './InfoCard'
import { Link } from 'react-router-dom'
import BackBtn from '../../../Components/svgs/BackBtn'
import Person from '../../../Components/svgs/Person'
import StudentsRatings from './StudentsRatings'

export default function WebinarInstructorDetails({ instructorDetails }) {
    const webinarsData = [
        instructorDetails?.finished_webinars,
        instructorDetails?.upcoming_webinars,
    ];
    const webinarsLabel = ['Finished Webinars', 'Upcoming Webinars'];
    return (
        <div className='m-4'>
            <div className='flex justify-between gap-4 items-center'>
                <Link to='/instructors' className='flex gap-3 items-center btn-primary px-4 py-2 text-xl font-semibold'>
                    <BackBtn />
                    Back
                </Link>
                <div className='flex gap-4 items-center'>
                    <Person className='bg-gray-500 w-12 h-12 rounded-full' />
                    <div>
                        <h2 className='text-lg text-[#151D48] font-medium'>Mohamed</h2>
                        <h3 className='text-base text-[#737791] font-normal'>Admin</h3>
                    </div>
                </div>
            </div>

            <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-8'>

                <StatisticsCard
                    title='Webinar Progress Tracker'
                    description='Track instructor activity with a quick overview of finished and upcoming webinars, ensuring effective schedule management.'
                    totalItemsLabel='Total number of Questions'
                    totalItemsValue={instructorDetails?.webinars_count}
                    chartData={webinarsData}
                    chartLabels={webinarsLabel}
                    chartColors={['#273C97', '#FFC107']}
                />

                <InfoCard
                    profileImage={instructorDetails.profile_image}
                    name={instructorDetails.name}
                    phone={instructorDetails.phone}
                    email={instructorDetails.user.email}
                />
                <StudentsRatings
                    averageRating={instructorDetails.average_feedbacks_rating}
                    feedbackCount={instructorDetails.student_feedback_count}
                    reviews={instructorDetails.student_reviews}
                />

            </div>
        </div>
    )
}
