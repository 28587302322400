import { pageRoutes, roles } from ".";
import BagIcon from "../Components/svgs/BagIcon";
import GraduatuedCap from "../Components/svgs/GraduatuedCap";
import GroupPeople from "../Components/svgs/GroupPeople";
import HomeIcon from "../Components/svgs/HomeIcon";
import QAIcon from "../Components/svgs/QAIcon";
import RobotIcon from "../Components/svgs/RobotIcon";
import Task from "../Components/svgs/Task";

const studentTabs = [
  {
    pageRoute: pageRoutes.dashboard,
    label: "Dashboard",
    icon: <HomeIcon className="w-[1.5rem] h-[1.5rem] flex-shrink-0" />,
  },
  {
    pageRoute: pageRoutes.webinars,
    label: "Webinars",
    icon: <GroupPeople className="w-[1.5rem] h-[1.5rem] flex-shrink-0" />,
  },
  {
    pageRoute: pageRoutes.gigs,
    label: "Gigs",
    icon: <BagIcon className="w-[1.5rem] h-[1.5rem] flex-shrink-0" />,
  },
  {
    pageRoute: pageRoutes.deliverables,
    label: "Deliverables",
    icon: <Task className="w-[1.5rem] h-[1.5rem] flex-shrink-0" />,
  },
  {
    pageRoute: pageRoutes.course,
    label: "Course",
    icon: <GraduatuedCap className="w-[1.5rem] h-[1.5rem] flex-shrink-0" />,
  },
  {
    pageRoute: pageRoutes.aiReviewer,
    label: "AI Reviewer",
    icon: <RobotIcon className="w-[1.5rem] h-[1.5rem] flex-shrink-0" />,
  },
  {
    pageRoute: pageRoutes.qa,
    label: "Q&A",
    icon: <QAIcon className="w-[1.5rem] h-[1.5rem] flex-shrink-0" />,
  },
];

const instructorTabs = [
  {
    pageRoute: pageRoutes.dashboard,
    label: "Dashboard",
    icon: <HomeIcon className="w-[1.5rem] h-[1.5rem] flex-shrink-0" />,
  },
  {
    pageRoute: pageRoutes.webinars,
    label: "Webinars",
    icon: <GroupPeople className="w-[1.5rem] h-[1.5rem] flex-shrink-0" />,
  },
  {
    pageRoute: pageRoutes.reviews,
    label: "Reviews",
    icon: <Task className="w-[1.5rem] h-[1.5rem] flex-shrink-0" />,
  },
  {
    pageRoute: pageRoutes.qa,
    label: "Q&A",
    icon: <QAIcon className="w-[1.5rem] h-[1.5rem] flex-shrink-0" />,
  },
];

const webinarInstructorTabs = [
  {
    pageRoute: pageRoutes.dashboard,
    label: "Dashboard",
    icon: <HomeIcon className="w-[1.5rem] h-[1.5rem] flex-shrink-0" />,
  },
  {
    pageRoute: pageRoutes.webinars,
    label: "Webinars",
    icon: <GroupPeople className="w-[1.5rem] h-[1.5rem] flex-shrink-0" />,
  },
];

const qaInstructorTabs = [
  {
    pageRoute: pageRoutes.dashboard,
    label: "Dashboard",
    icon: <HomeIcon className="w-[1.5rem] h-[1.5rem] flex-shrink-0" />,
  },
  {
    pageRoute: pageRoutes.reviews,
    label: "Reviews",
    icon: <Task className="w-[1.5rem] h-[1.5rem] flex-shrink-0" />,
  },
  {
    pageRoute: pageRoutes.qa,
    label: "Q&A",
    icon: <QAIcon className="w-[1.5rem] h-[1.5rem] flex-shrink-0" />,
  },
];
const adminTabs = [
  {
    pageRoute: pageRoutes.dashboard,
    label: "Dashboard",
    icon: <HomeIcon className="w-[1.5rem] h-[1.5rem] flex-shrink-0" />,
  },
  {
    pageRoute: pageRoutes.students,
    label: "Students",
    icon: <GroupPeople className="w-[1.5rem] h-[1.5rem] flex-shrink-0" />,
  },
  {
    pageRoute: pageRoutes.instructors,
    label: "Instructors",
    icon: <GroupPeople className="w-[1.5rem] h-[1.5rem] flex-shrink-0" />,
  },
  {
    pageRoute: pageRoutes.webinars,
    label: "Webinars",
    icon: <GroupPeople className="w-[1.5rem] h-[1.5rem] flex-shrink-0" />,
  },
  {
    pageRoute: pageRoutes.reviews,
    label: "Reviews",
    icon: <Task className="w-[1.5rem] h-[1.5rem] flex-shrink-0" />,
  },
  {
    pageRoute: pageRoutes.aiReviewer,
    label: "AI Reviewer",
    icon: <RobotIcon className="w-[1.5rem] h-[1.5rem] flex-shrink-0" />,
  },
  {
    pageRoute: pageRoutes.qa,
    label: "Q&A",
    icon: <QAIcon className="w-[1.5rem] h-[1.5rem] flex-shrink-0" />,
  }
];
export const sidebarLookup = {
  [roles.studentRole]: studentTabs,
  [roles.instructorRole]: instructorTabs,
  [roles.webinarInstructorRole]: webinarInstructorTabs,
  [roles.qaInstructorRole]: qaInstructorTabs,
  [roles.admin]: adminTabs,
};
