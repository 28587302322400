import React from 'react'

export default function Details2({ className }) {
    return (
        <svg
            width={80}
            height={80}
            viewBox="0 0 82 83"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M61.4985 24.9628C61.2935 24.9286 61.0543 24.9286 60.8493 24.9628C56.1343 24.7919 52.376 20.9311 52.376 16.1478C52.376 11.2619 56.3051 7.33276 61.191 7.33276C66.0768 7.33276 70.006 11.2961 70.006 16.1478C69.9718 20.9311 66.2135 24.7919 61.4985 24.9628Z"
                stroke="#202020"
                strokeWidth={6.46875}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M57.9785 49.8366C62.6593 50.6225 67.8185 49.8025 71.4402 47.3766C76.2577 44.165 76.2577 38.9033 71.4402 35.6917C67.7843 33.2658 62.5568 32.4458 57.876 33.2658"
                stroke="#202020"
                strokeWidth={6.46875}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.3991 24.9628C20.6041 24.9286 20.8433 24.9286 21.0483 24.9628C25.7633 24.7919 29.5216 20.9311 29.5216 16.1478C29.5216 11.2619 25.5924 7.33276 20.7066 7.33276C15.8208 7.33276 11.8916 11.2961 11.8916 16.1478C11.9258 20.9311 15.6841 24.7919 20.3991 24.9628Z"
                stroke="#202020"
                strokeWidth={6.46875}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M23.9166 49.8366C19.2358 50.6225 14.0766 49.8025 10.4549 47.3766C5.63742 44.165 5.63742 38.9033 10.4549 35.6917C14.1108 33.2658 19.3382 32.4458 24.0191 33.2658"
                stroke="#202020"
                strokeWidth={6.46875}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M41.0034 50.4852C40.7984 50.4511 40.5592 50.4511 40.3542 50.4852C35.6392 50.3144 31.8809 46.4536 31.8809 41.6702C31.8809 36.7844 35.81 32.8552 40.6959 32.8552C45.5817 32.8552 49.5109 36.8186 49.5109 41.6702C49.4767 46.4536 45.7184 50.3486 41.0034 50.4852Z"
                stroke="#202020"
                strokeWidth={6.46875}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M31.0594 61.2477C26.2419 64.4594 26.2419 69.7211 31.0594 72.9327C36.5261 76.5886 45.4777 76.5886 50.9444 72.9327C55.7619 69.7211 55.7619 64.4594 50.9444 61.2477C45.5119 57.6261 36.5261 57.6261 31.0594 61.2477Z"
                stroke="#202020"
                strokeWidth={6.46875}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
