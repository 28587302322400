import React from 'react';
import Clock from '../../../Components/svgs/Clock';
import Person from '../../../Components/svgs/Person';
import Proposals from '../../../Components/svgs/Proposals';
import QAIconAdmin from '../../../Components/svgs/QAIconAdmin';
import InfoCard from './InfoCard';
import StatisticsCard from './StatisticsCard';
import { Link } from 'react-router-dom';
import BackBtn from '../../../Components/svgs/BackBtn';

export default function TechInstructorDetails({ instructorDetails }) {
    const answeredData = [
        instructorDetails?.answers_count,
        instructorDetails?.not_answered_count,
    ];
    const answeredLabels = ['Answered', 'Not Answered'];

    const reviewedData = [
        instructorDetails?.reviews_count,
        instructorDetails?.pending_count,
    ];
    const reviewedLabels = ['Reviewed', 'Pending'];

    const stats = [
        {
            icon: QAIconAdmin,
            label: 'Q&A Answer',
            value: `${instructorDetails.answers_count} Out of ${instructorDetails.questions_count}`,
        },
        {
            icon: Clock,
            label: 'Average Response Time',
            value: instructorDetails.answers_avg_response_time || 'N/A',
        },
        {
            icon: Proposals,
            label: 'Review Proposals',
            value: `${instructorDetails.reviews_count} Out of ${instructorDetails.proposal_count}`,
        },
        {
            icon: Clock,
            label: 'Average Review Time',
            value: instructorDetails.reviews_avg_response_time || 'N/A',
        },
    ];

    return (
        <div className='m-4'>
            <div className='flex justify-between gap-4 items-center'>
                <Link to='/instructors' className='flex gap-3 items-center btn-primary px-4 py-2 text-xl font-semibold'>
                    <BackBtn />
                    Back
                </Link>
                <div className='flex gap-4 items-center'>
                    <Person className='bg-gray-500 w-12 h-12 rounded-full' />
                    <div>
                        <h2 className='text-lg text-[#151D48] font-medium'>Mohamed</h2>
                        <h3 className='text-base text-[#737791] font-normal'>Admin</h3>
                    </div>
                </div>
            </div>

            <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-8'>
                <StatisticsCard
                    title='Q & A Statistics'
                    description='This section provides insights into instructor engagement by
                            tracking the number of student questions answered, unanswered, and
                            the average response time. Use this data to monitor performance
                            and enhance student support.'
                    averageTimeTitle='Average Response Time'
                    averageTimeValue={
                        instructorDetails?.answers_avg_response_time || 'N/A'
                    }
                    totalItemsLabel='Total number of Questions'
                    totalItemsValue={instructorDetails?.questions_count}
                    chartData={answeredData}
                    chartLabels={answeredLabels}
                    chartColors={['#273C97', '#FFC107']}
                />

                <InfoCard
                    profileImage={instructorDetails.profile_image}
                    name={instructorDetails.name}
                    phone={instructorDetails.phone}
                    email={instructorDetails.user.email}
                    stats={stats}
                />

                <StatisticsCard
                    title='Review Proposals Statistics'
                    description='This section highlights the progress of student proposal reviews. Track the percentage of reviewed and pending proposals, and monitor the average review time to ensure timely feedback.'
                    averageTimeTitle='Average Review Time'
                    averageTimeValue={
                        instructorDetails.reviews_avg_response_time || 'N/A'
                    }
                    totalItemsLabel='Total number of Proposals'
                    totalItemsValue={instructorDetails?.proposal_count}
                    chartData={reviewedData}
                    chartLabels={reviewedLabels}
                    chartColors={['#273C97', '#FFC107']}
                />
            </div>
        </div>
    );
}
