import React, { createContext, useContext, useState } from "react";
import { CourseStudentEndPoint } from "../../../Services/endPoints";
import useFetch from "../../../hooks/useFetch";

// Create the context
const CourseLayoutContext = createContext(undefined);

// Create a provider component
export const CourseLayoutProvider = ({ children }) => {
  const [revalidate, setRevalidate] = useState(false);
  const {
    data: courseData,
    loading: courseLoading,
    error: courseError,
  } = useFetch(CourseStudentEndPoint, [revalidate]);

  return (
    <CourseLayoutContext.Provider
      value={{ courseData, courseLoading, courseError, setRevalidate }}
    >
      {children}
    </CourseLayoutContext.Provider>
  );
};

export const useCourseLayoutContext = () => {
  const context = useContext(CourseLayoutContext);
  if (!context) {
    throw new Error(
      "useCourseLayoutContext must be used within a CourseLayoutProvider"
    );
  }
  return context;
};
