import React from 'react';
import AdminUserInfo from './AdminUserInfo';
import imageRecorded from '../../../assets/icon (1).png';
import imageRecorded2 from '../../../assets/icon (2).png';

function AIInterviewingCards({ id, email, name, phone, status, englishScore, QuestionScore, category, handleStatusUpdate }) {
  return (
    <div className="flex flex-col md:flex-row gap-4 p-4 bg-gray-50 rounded-lg shadow-md">
      {/* User Info Card */}
      <div className="flex flex-col bg-white p-4 rounded-lg shadow w-full md:w-1/2">
        <div className="flex items-center gap-4">
          <div className="flex justify-between w-full items-center">
            <div className="flex items-start">
              <img src={imageRecorded} alt="" />
              <div>
                <h2 className="text-lg font-semibold">{name}</h2>
                <p className="text-sm text-gray-500">{category}</p>
                <span
                  className={`text-sm font-medium ${
                    status === 'Accepted'
                      ? 'text-green-500'
                      : status === 'Rejected'
                      ? 'text-red-500'
                      : 'text-yellow-500'
                  }`}
                >
                  ● {status}
                </span>
              </div>
            </div>
            <div className="mt-4 md:mt-0">
              <select
                className="px-3 py-1 border border-gray-300 rounded text-sm"
                value={status}
                onChange={(e) => handleStatusUpdate(id, e.target.value)} // Pass id and newStatus
              >
                <option value="Pending">Pending</option>
                <option value="Accepted">Accept</option>
                <option value="Rejected">Reject</option>
              </select>
            </div>
          </div>
        </div>

        <div className="flex justify-between">
          <p className="text-sm font-medium text-gray-500">
            Phone:
            <span className="text-gray-800">{phone}</span>
          </p>
          <p className="text-sm font-medium text-gray-500">
            Email:
            <span className="text-gray-800">{email}</span>
          </p>
        </div>
      </div>

      {/* Interview Results Card */}
      <div className="bg-white p-4 rounded-lg shadow w-full md:w-1/2">
        <div className="flex items-center gap-4">
          <img src={imageRecorded2} alt="" />
          <div>
            <h2 className="text-lg font-semibold text-blue-600">Interview Result</h2>
          </div>
        </div>
        <div className="ml-auto flex justify-around gap-2 text-center mt-4">
          <div>
            <span className="block text-sm text-gray-500">English Score</span>
            <span className="block text-lg font-bold text-gray-800">{englishScore}</span>
          </div>
          <div>
            <span className="block text-sm text-gray-500">Technical Score</span>
            <span className="block text-lg font-bold text-gray-800">{QuestionScore}</span>
          </div>
        </div>
      </div>
      <div className="mb-4 tablet:block hidden">
        <AdminUserInfo />
      </div>
    </div>
  );
}

export default AIInterviewingCards;
