import { Route, Routes, Navigate } from "react-router-dom";
import { pageRoutes } from "../constants";
import InstructorLayout from "./InsturctorDashboard/Components/Common/InstructorLayout";
import Webinars from "./InsturctorDashboard/Pages/Webinars/Webinars";
import Settings from "./Students/Settings/Settings";
import Dashboard from "./InsturctorDashboard/Pages/Dashboard/Dashboard";
import Reviews from "./InsturctorDashboard/Pages/Reviews/Reviews";
import QA from "./InsturctorDashboard/Pages/QA/QA";
import NotFoundPage from "./Common/NotFoundPage";

const InstructorRoutes = () => {
  return (
    <Routes>
      <Route index element={<Navigate to={`/${pageRoutes.dashboard}`} />} />
      <Route element={<InstructorLayout />}>
        <Route path={pageRoutes.dashboard} element={<Dashboard />} />
        <Route path={pageRoutes.webinars} element={<Webinars />} />
        <Route path={pageRoutes.qa} element={<QA />} />
        <Route path={pageRoutes.reviews} element={<Reviews />} />

        <Route path={pageRoutes.settings} element={<Settings />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default InstructorRoutes;
