export const pageRoutes = {
  studentDashboard: "student-dashboard",
  dashboard: "dashboard",
  webinars: "webinars",
  gigs: "gigs",
  deliverables: "deliverables",
  reviews: "reviews",
  course: "course",
  lesson: "lesson",
  aiReviewer: "aiReviewer",
  history: "history",
  historyDetail: "history-detail",
  qa: "qa",
  settings: "settings",
  unauthorized: "unauthorized",
  login: "login",
  students: "students",
  instructors: "instructors",
  instructorDetails: "details",
  aiInterviewing: "aiInterviewing",
};
export const pageLinks = {
  login: "/login",
  landingPage: "/",
};
export const roles = {
  studentRole: "student",
  instructorRole: "instructor-All",
  webinarInstructorRole: "instructor-Webinar",
  qaInstructorRole: "instructor-Q/A",
  admin: "staff",
};
