import React, { useState } from "react";
// import AddWebinarModal from "./AddWebinarModal/AddWebinarModal";
import Image from "../../../../assets/WebinarInstructor.png";
import AddIcon2 from "../../../../Components/svgs/AddIcon2";
import AddWebinarModal from '../Webinars/AddWebinarModal/AddWebinarModal';

const WebinarInstructorDashboard = () => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false); // State for Add Webinar Modal

  const handleAddWebinar = () => {
    setIsAddModalOpen(true); // Open the Add Webinar Modal
  };

  const handleAddModalClose = () => {
    setIsAddModalOpen(false); // Close the Add Webinar Modal
  };

  const handleAddModalSubmit = () => {
    setIsAddModalOpen(false); // Close the modal after submission
    console.log("Webinar added successfully!");
  };

  return (
    <div className="w-full flex justify-around items-center bg-primary  rounded-lg md:p-1  p-5">
      <div className="md:w-[50%] w-[100%] ">
        <h1 className="text-white text-[2rem] my-2">Share Your Expertise! 🌟</h1>
        <p className="text-white my-2 leading-7">
          Easily create webinar links and provide all the important details to engage your audience. Share your expertise,
          inspire aspiring freelancers, and help them take their first steps toward success in the freelancing world.
        </p>
        <button
          onClick={handleAddWebinar}
          className="bg-white border-2 rounded-lg flex items-center p-2 text-primary font-medium"
        >
          <AddIcon2 className="mr-3 my-2" /> Add New Webinar
        </button>
      </div>
      <div>
        <img src={Image} alt="OBJECTS" className="md:block hidden" />
      </div>

      {/* Add Webinar Modal */}
      <AddWebinarModal
        isOpen={isAddModalOpen}
        onClose={handleAddModalClose}
        onSubmit={handleAddModalSubmit}
      />
    </div>
  );
};

export default WebinarInstructorDashboard;
