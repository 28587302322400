import { API_URL, getToken } from "../../../../Helpers/constants";
import { useState } from "react";

import Fiver from "../../../../../src/assets/images 1.svg";
import Upwork from "../../../../../src/assets/upwork 1.svg";
import PeoplePerHr from "../../../../../src/assets/pepoleperhr.svg";
import Mostaqal from "../../../../../src/assets/Ellipse 378.svg";
import FreelanceYard from "../../../../../src/assets/Mostaqel.svg";
import Khamsat from "../../../../../src/assets/5msat.svg";
import DefaultFreelanceIcon from "../../../../../src/assets/JOB icon.svg";

import useFetch from "../../../../hooks/useFetch";
import {
  freelancePlatformsEndpoint,
  freelanceProfileLinksEndpoint,
} from "../../../../Services/endPoints";

const platformLogos = {
  upwork: Upwork,
  fiverr: Fiver,
  peopleperhour: PeoplePerHr,
  mostaql: Mostaqal,
  freelanceyard: FreelanceYard,
  khamsat: Khamsat,
  tasmeemme: DefaultFreelanceIcon,
  other: DefaultFreelanceIcon,
};

export const useFreelanceAccounts = () => {
  const [newPlatform, setNewPlatform] = useState("");
  const [showInputs, setShowInputs] = useState(false);
  const [platformId, setPlatformId] = useState("");
  const [urlError, setUrlError] = useState("");
  const [refetch, setRefetch] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const dynamicToken = getToken();
  const normalizePlatformName = (name) => name.toLowerCase();
  const {
    data: freelancePlatforms,
    loading: freelancePlatformsLoading,
    error: freelancePlatformsError,
  } = useFetch(freelancePlatformsEndpoint);

  const {
    data: freelanceProfileLinks,
    loading,
    error,
  } = useFetch(freelanceProfileLinksEndpoint, [refetch]);

  const postPlatformLink = () => {
    setUrlError("");
    setSubmitError("");

    if (!newPlatform.trim()) {
      setUrlError("Platform URL is required.");
      return;
    }
    if (!newPlatform.startsWith("https://")) {
      setUrlError('Platform URL must start with "https://".');
      return;
    }
    if (!platformId) {
      setSubmitError("You must select a platform.");
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `jwt ${dynamicToken}`);

    const raw = JSON.stringify({ url: newPlatform, platform: platformId });

    fetch(`${API_URL}/freelance-profile-links/`, {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((data) => {
            if (data.url && Array.isArray(data.url)) {
              setUrlError(data.url[0]);
            }
            throw new Error("Validation error occurred");
          });
        }
        return response.json();
      })
      .then(() => {
        setNewPlatform("");
        setPlatformId("");
        setShowInputs(false);
        setRefetch(!refetch);
      })
      .catch((error) =>
        setSubmitError(error.message || "An error occurred while submitting.")
      );
  };

  return {
    newPlatform,
    setNewPlatform,
    showInputs,
    setShowInputs,
    postPlatformLink,
    platformId,
    setPlatformId,
    urlError,
    setUrlError,
    submitError,
    setSubmitError,
    freelancePlatforms,
    freelanceProfileLinks,
    platformLogos, // Updated object with SVG components
    normalizePlatformName,
  };
};
