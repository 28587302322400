import React from 'react';
import backgroundImage from '../../../assets/NubmersBG.png';
import Numbers1 from '../../../Components/svgs/Numbers1';
import Numbers2 from '../../../Components/svgs/Numbers2';
import Numbers3 from '../../../Components/svgs/Numbers3';
import Numbers4 from '../../../Components/svgs/Numbers4';
import { useTranslation } from 'react-i18next';

export default function NumbersSection() {
    const { t } = useTranslation();
    return (
        <div
            className='w-full bg-cover bg-center'
            style={{ backgroundImage: `url(${backgroundImage})` }}
        >
            <div className='custom-container text-center py-8'>
                <h1 className='text-base sm:text-5xl mb-8 font-semibold text-[#273C97]'>
                    {t('numbersTitle')}
                </h1>

                <div className='grid grid-cols-2 lg:grid-cols-4 gap-8 sm:gap-4 my-8 sm:my-16'>
                    <div className='flex flex-col items-center gap-6 text-center'>
                        <div className='sm:w-20 sm:h-20'>
                            <Numbers1 className='w-full h-full' />
                        </div>
                        <h2 className='text-lg sm:text-4xl font-medium bg-gradient-to-r from-[#136CB5] to-[#49BBBD] text-transparent bg-clip-text'>
                            20,000
                        </h2>
                        <h3 className='text-sm sm:text-xl font-normal text-[#202020CC]'>
                            {t('numbers1')}
                        </h3>
                    </div>
                    <div className='flex flex-col items-center gap-6 text-center'>
                        <div className='sm:w-20 sm:h-20'>
                            <Numbers2 className='w-full h-full' />
                        </div>
                        <h2 className='text-lg sm:text-4xl font-medium bg-gradient-to-r from-[#136CB5] to-[#49BBBD] text-transparent bg-clip-text'>
                            6000
                        </h2>
                        <h3 className='text-sm sm:text-xl font-normal text-[#202020CC]'>
                            {t('numbers2')}
                        </h3>
                    </div>
                    <div className='flex flex-col items-center gap-6 text-center'>
                        <div className='sm:w-20 sm:h-20'>
                            <Numbers3 className='w-full h-full' />
                        </div>
                        <h2 className='text-lg sm:text-4xl font-medium bg-gradient-to-r from-[#136CB5] to-[#49BBBD] text-transparent bg-clip-text'>
                            27
                        </h2>
                        <h3 className='text-sm sm:text-xl font-normal text-[#202020CC]'>
                            {t('numbers3')}
                        </h3>
                    </div>
                    <div className='flex flex-col items-center gap-6 text-center'>
                        <div className='sm:w-20 sm:h-20'>
                            <Numbers4 className='w-full h-full' />
                        </div>
                        <h2 className='text-lg sm:text-4xl font-medium bg-gradient-to-r from-[#136CB5] to-[#49BBBD] text-transparent bg-clip-text'>
                            20
                        </h2>
                        <h3 className='text-sm sm:text-xl font-normal text-[#202020CC]'>
                            {t('numbers4')}
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    );
}
