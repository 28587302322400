import React from "react";
import { Link, useParams } from "react-router-dom";
import { AiReviewerGetAll } from "../../../../Services/endPoints";
import useFetch from "../../../../hooks/useFetch";
import { pageRoutes } from "../../../../constants";
import BackBtn from "../../../../Components/svgs/BackBtn";

export default function AIReviewerHistoryDetail() {
  const { id } = useParams();
  const detailHistoryForID = `${AiReviewerGetAll}${id}/`;
  const {
    data: aiReviewerData,
    loading: aiReviewerDataLoading,
    error: aiReviewerDataError,
  } = useFetch(detailHistoryForID);

  if (aiReviewerDataLoading) return <div>Loading...</div>;
  if (aiReviewerDataError) return <div>Error: {aiReviewerDataError}</div>;

  const {
    job_title,
    job_description,
    prompt,
    enhanced_text,
    uploaded_file,
    uploaded_at,
  } = aiReviewerData;

  return (
    <div className="p-6">
      <div className="flex justify-between">
        <div>
          <h1 className="text-2xl font-bold text-gray-800 mb-4">{job_title}</h1>
          <p className="text-gray-600 italic mb-6">
            Uploaded on: {new Date(uploaded_at).toLocaleString()}
          </p>
        </div>
        <Link
          to={`/${pageRoutes.aiReviewer}/${pageRoutes.history}`}
          className="bg-[#FFC107] p-4 h-fit border rounded-xl text-[#273C97] text-base font-semibold flex w-fit gap-4 justify-center items-center"
        >
          <BackBtn />
          Back To AI History
        </Link>
      </div>

      <div className="bg-gray-100 p-4 rounded-lg shadow-md mb-6">
        <h2 className="text-xl font-semibold text-gray-700 mb-2">
          Job Description
        </h2>
        <p className="text-gray-600">{job_description}</p>
      </div>
      <div className="bg-gray-100 p-4 rounded-lg shadow-md mb-6">
        <h2 className="text-xl font-semibold text-gray-700 mb-2">
          The Prompt Answer
        </h2>
        <p className="text-gray-600 whitespace-pre-line">{enhanced_text}</p>
      </div>

      <div className="flex items-center justify-between bg-gray-100 p-4 rounded-lg shadow-md">
        <p className="text-gray-600">Download the uploaded file:</p>
        <a
          href={uploaded_file}
          target="_blank"
          rel="noopener noreferrer"
          className="btn-primary p-4 hover:text-blue-700 text-lg"
        >
          Download PDF
        </a>
      </div>
    </div>
  );
}
