import React from 'react';
import TableSkeleton from './TableSkeleton';

function Table({
  columns,
  data = [],
  rowsPerPageOptions = [5, 10, 20],
  isLoading,
  currentPage,
  totalPages,
  rowsPerPage,
  setRowsPerPage,
  setCurrentPage,
  handlePageChange,
  prevPage,
  nextPage,
  onRowClick,
  handleActionChange,
}) {
  // Function to generate page numbers with ellipses
  const getPageNumbers = () => {
    const range = 5; // Number of page buttons to show
    const pages = [];

    if (totalPages <= range) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      const start = Math.max(2, currentPage - 2); // Start from the second page or currentPage - 2
      const end = Math.min(totalPages - 1, currentPage + 2); // End before the last page or currentPage + 2

      pages.push(1); // Always include the first page

      if (start > 2) {
        pages.push('...'); // Add ellipses if there are skipped pages at the start
      }

      for (let i = start; i <= end; i++) {
        pages.push(i);
      }

      if (end < totalPages - 1) {
        pages.push('...'); // Add ellipses if there are skipped pages at the end
      }

      pages.push(totalPages); // Always include the last page
    }

    return pages;
  };

  if (isLoading) {
    return <TableSkeleton columns={columns.length} rows={5} />;
  }

  if (!data || data.length === 0) {
    return <p className='text-center p-4'>No data available</p>;
  }

  return (
    <div className='overflow-x-auto p-4'>
      <table className='w-full table-auto border-collapse rounded-8xl'>
        <thead className='bg-[#273C97] text-white'>
          <tr>
            {columns.map((col, index) => (
              <th
                key={index}
                className='text-center px-1 py-2 text-sm font-medium text-white'
              >
                {col.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              className={`bg-white ${onRowClick ? 'hover:bg-gray-100  cursor-pointer' : ''
                }`}
              onClick={(e) => {
                const isActionColumn = e.target.tagName === 'SELECT' || e.target.closest('select');
                if (!isActionColumn && onRowClick) {
                  onRowClick(row);
                }
              }}
            >
              {columns.map((col, colIndex) => (
                <td
                  key={colIndex}
                  className='px-4 py-2 border border-gray-200 text-sm text-center'
                >
                  {col.accessor === 'status' ? (
                    <span
                      className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${row[col.accessor] === 'Active'
                        ? 'bg-green-100 text-green-700'
                        : row[col.accessor] === 'Inactive'
                          ? 'bg-red-100 text-red-700'
                          : 'bg-gray-100 text-gray-700'
                        }`}
                    >
                      {row[col.accessor]}
                    </span>
                  ) : col.accessor === "action" ? (
                    <select
                      className="border border-gray-300 rounded p-1 text-sm"
                      value={row.status}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleActionChange(row.id, e.target.value);
                      }}
                    >
                      <option value="Pending">Pending</option>
                      <option value="Accepted">Accepted</option>
                      <option value="Rejected">Rejected</option>
                    </select>
                  ) : (
                    row[col.accessor]
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <div className='flex items-center mt-4'>
        <div className='flex items-center gap-2'>
          <span>Show</span>
          <select
            value={rowsPerPage}
            onChange={(e) => setRowsPerPage(Number(e.target.value))}
            className='border border-gray-300 rounded p-1 text-sm'
          >
            {rowsPerPageOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <span>Rows</span>
        </div>

        <div className='flex items-center justify-center gap-2 w-[60%]'>
          <button
            onClick={() => handlePageChange('previous')}
            className={`px-2 py-1 rounded ${!prevPage ? '' : 'bg-gray-400 hover:bg-gray-400'
              }`}
            disabled={!prevPage}
          >
            &lt;
          </button>

          {getPageNumbers().map((page, index) =>
            page === '...' ? (
              <span key={index} className='px-2 py-1'>
                ...
              </span>
            ) : (
              <button
                key={page}
                onClick={() => setCurrentPage(page)}
                className={`px-2 py-1 rounded ${page === currentPage
                  ? 'bg-[#273C97] text-white'
                  : 'bg-gray-300 hover:bg-gray-400'
                  }`}
              >
                {page}
              </button>
            )
          )}

          <button
            onClick={() => handlePageChange('next')}
            className={`px-2 py-1 rounded ${!nextPage ? '' : 'bg-gray-400 hover:bg-gray-400'
              }`}
            disabled={!nextPage}
          >
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
}

export default Table;
