import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function LangBtn() {
    const { i18n } = useTranslation();
    const [isEnglish, setIsEnglish] = useState(i18n.language === 'en'); // Determine the initial state

    useEffect(() => {
        // Update language direction and toggle state on language change
        const savedLanguage = localStorage.getItem('i18nextLng') || 'en';
        setIsEnglish(savedLanguage === 'en');
    }, [i18n.language]);

    const handleToggle = () => {
        const newLanguage = isEnglish ? 'ar' : 'en';
        i18n.changeLanguage(newLanguage);
        localStorage.setItem('i18nextLng', newLanguage);
        setIsEnglish(!isEnglish); // Toggle the language state
    };

    return (
        <div className="flex items-center gap-4">
            {/* <span className="text-base font-medium">EN</span> */}
            <span className="text-base font-medium">ع</span>
            <input
                type="checkbox"
                className="toggle border-none bg-[#FFC107] [--tglbg:#273C97] hover:bg-[#ffc10796]"
                checked={isEnglish}
                onChange={handleToggle} // Handle toggle change
            />
            <span className="text-base font-medium">EN</span>
        </div>
    );
}
