import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Header from "../components/Header";
import CardsInfo from "../components/CardsInfo";
import group from "../../../assets/Group2.png";
import Table from '../Common/Table/Table';
import usePaginationNumbers from "../../../hooks/usePaginationNumbers";
import { dashboardWebinars, dashboardWebinarsStatisticsEndPoint } from "../../../Services/endPoints";
import TableSkeleton from "../Common/Table/TableSkeleton";
import WebinarsFilter from "../components/WebinarsFilter";
import useFetch from '../../../hooks/useFetch';

function Webinars() {
  const [searchParams] = useSearchParams();

  // Extract filter parameters from the URL
  const date = searchParams.get("date");
  const status = searchParams.get("status");

  // Construct the dynamic API URL with filters
  const queryParams = new URLSearchParams();
  if (date) queryParams.append("date", date);
  if (status) queryParams.append("status", status);

  const dynamicWebinarsEndpoint = `${dashboardWebinars}?${queryParams.toString()}`;

  // Fetch paginated data using the dynamic endpoint
  const {
    data: dashboardWebinarsData,
    page,
    totalPages,
    isLoading,
    error,
    rowsPerPage,
    handlePageChange,
    setCurrentPage,
    nextPage,
    prevPage,
    setRowsPerPage: handleRowsPerPageChange,
  } = usePaginationNumbers(dynamicWebinarsEndpoint);

  // Fetch webinar statistics
  const { data: dashboardWebinarsStatisticsData } = useFetch(dashboardWebinarsStatisticsEndPoint);

  // Cards info
  const studentCards = [
    {
      img: <img src={group} alt="group" />,
      title: "Webinars",
      value: dashboardWebinarsStatisticsData?.total_webinars,
    },
    {
      img: <img src={group} alt="instructor" />,
      title: "Completed Webinars",
      value: dashboardWebinarsStatisticsData?.completed_webinars,
    },
    {
      img: <img src={group} alt="instructor" />,
      title: "Upcoming Webinars",
      value: dashboardWebinarsStatisticsData?.upcoming_webinars,
    },
  ];

  // Table columns
  const tableColumns = [
    { header: "Coach ID", accessor: "id" },
    { header: "Name", accessor: "name" },
    { header: "Email", accessor: "email" },
    { header: "Phone", accessor: "phone" },
    { header: "Webinar Title", accessor: "webinarTitle" },
    { header: "Webinar Date", accessor: "webinarDate" },
    { header: "Job Profile", accessor: "jobProfile" },
    { header: "Status", accessor: "status" },
  ];

  // Format table data
  const tableData = dashboardWebinarsData?.map((webinar) => ({
    id: webinar.id,
    name: webinar.instructor_name,
    email: webinar.instructor_email,
    phone: webinar.instructor_phone,
    webinarTitle: webinar.name,
    webinarDate: webinar.date,
    jobProfile: webinar.job_profile_round,
    status: webinar.is_finished ? "Completed" : "Upcoming",
  })) || [];

  return (
    <div className="p-4">
      <Header>
        <CardsInfo cards={studentCards} />
      </Header>

      {/* Pass searchParams and setSearchParams to WebinarsFilter */}
      <WebinarsFilter  />

      <div className="mt-6">
        {isLoading ? (
          <TableSkeleton columns={tableColumns.length} rows={5} />
        ) : error ? (
          <p>Error fetching data</p>
        ) : (
          <Table
            columns={tableColumns}
            data={tableData}
            rowsPerPageOptions={[5, 10, 20]}
            isLoading={isLoading}
            currentPage={page}
            totalPages={totalPages}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={handleRowsPerPageChange}
            setCurrentPage={setCurrentPage}
            handlePageChange={handlePageChange}
            prevPage={prevPage}
            nextPage={nextPage}
          />
        )}
      </div>
    </div>
  );
}

export default Webinars;
