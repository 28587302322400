import React from 'react';
import LandingNavBar from './LandingNavBar/LandingNavBar';
import HeroSection from './HeroSection/HeroSection';
import DetailsSection from './DetailsSection/DetailsSection';
import Benefits from './Benefits/Benefits';
import SelectionCriteria from './SelectionCriteria/SelectionCriteria';
import NumbersSection from './NumbersSection/NumbersSection';
import LandingFooter from './LandingFooter/LandingFooter';

export default function LandingPage() {

    return (
        <div>
            <LandingNavBar />
            <HeroSection />
            <DetailsSection />
            <Benefits />
            <SelectionCriteria />
            <NumbersSection />
            <LandingFooter />
        </div>
    )
}
