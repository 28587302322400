import AssignmentIcon from "@mui/icons-material/Assignment";
import useFetch from "../../../hooks/useFetch";
import { GetDeliverableResultsById } from "../../../Services/endPoints";
import styles from "./Tasks.module.css";

const DeliverableFeedback = ({ hideFeedback, deliverableResult, taskName }) => {
  const { data: feedbackData } = useFetch(
    `${GetDeliverableResultsById}${deliverableResult}/`
  );
  return (
    <div className={styles.taskCard}>
      <p className={styles.description}>
        <AssignmentIcon /> {taskName}
      </p>

      <div className={styles.reviewSection}>
        <div className={styles.reviewComment}>
          <h4>Review Comment</h4>
          <p>{feedbackData && feedbackData[0]?.description}</p>
        </div>
        <div className={styles.reviewDegree}>
          <h4>Review Degree</h4>
          <p>{feedbackData && feedbackData[0]?.degree}</p>
        </div>
      </div>
      <div className="flex justify-center items-center gap-5 mt-5">
        <button className="btn-primary py-2 px-3 w-40" onClick={hideFeedback}>
          Back to Task
        </button>
      </div>
    </div>
  );
};

export default DeliverableFeedback;
