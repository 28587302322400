import React, { useState } from "react";

function AIReviewerFilter({ searchParams, setSearchParams }) {
  // Static filter options
  const statuses = ["Pending", "Approved", "Rejected"];
  const scores = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const [isStatusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [isVideoScoreDropdownOpen, setVideoScoreDropdownOpen] = useState(false);
  const [isQuestionsScoreDropdownOpen, setQuestionsScoreDropdownOpen] =
    useState(false);

  // Handle filter selections
  const handleSelect = (key, value) => {
    const updatedParams = new URLSearchParams(searchParams);
    if (value) {
      updatedParams.set(key, value);
    } else {
      updatedParams.delete(key);
    }
    setSearchParams(updatedParams);
  };

  const handleClearFilters = () => {
    setSearchParams(new URLSearchParams());
  };

  // Extract selected filters
  const selectedStatus = searchParams.get("status");
  const selectedVideoScore = searchParams.get("video_score");
  const selectedQuestionsScore = searchParams.get("questions_score");

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <div className="flex items-center gap-4 flex-wrap">
        {/* Status Filter */}
        <div className="relative dropdown">
          <button
            onClick={() => setStatusDropdownOpen(!isStatusDropdownOpen)}
            className="px-4 py-2 bg-gray-100 border border-gray-300 rounded-full text-sm"
          >
            {selectedStatus || "Status"}
            <span className="ml-2">&#9662;</span>
          </button>
          {isStatusDropdownOpen && (
            <div className="absolute top-12 left-0 w-48 bg-white border border-gray-200 rounded-lg shadow-md z-10">
              {statuses.map((status) => (
                <div
                  key={status}
                  onClick={() => handleSelect("status", status)}
                  className="px-4 py-2 text-sm cursor-pointer hover:bg-gray-100"
                >
                  {status}
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Video Score Filter */}
        <div className="relative dropdown">
          <button
            onClick={() =>
              setVideoScoreDropdownOpen(!isVideoScoreDropdownOpen)
            }
            className="px-4 py-2 bg-gray-100 border border-gray-300 rounded-full text-sm"
          >
            {selectedVideoScore || "Video Score"}
            <span className="ml-2">&#9662;</span>
          </button>
          {isVideoScoreDropdownOpen && (
            <div className="absolute top-12 left-0 w-48 bg-white border border-gray-200 rounded-lg shadow-md z-10">
              {scores.map((score) => (
                <div
                  key={score}
                  onClick={() => handleSelect("video_score", score)}
                  className="px-4 py-2 text-sm cursor-pointer hover:bg-gray-100"
                >
                  {score}
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Questions Score Filter */}
        <div className="relative dropdown">
          <button
            onClick={() =>
              setQuestionsScoreDropdownOpen(!isQuestionsScoreDropdownOpen)
            }
            className="px-4 py-2 bg-gray-100 border border-gray-300 rounded-full text-sm"
          >
            {selectedQuestionsScore || "Questions Score"}
            <span className="ml-2">&#9662;</span>
          </button>
          {isQuestionsScoreDropdownOpen && (
            <div className="absolute top-12 left-0 w-48 bg-white border border-gray-200 rounded-lg shadow-md z-10">
              {scores.map((score) => (
                <div
                  key={score}
                  onClick={() => handleSelect("questions_score", score)}
                  className="px-4 py-2 text-sm cursor-pointer hover:bg-gray-100"
                >
                  {score}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Selected Filters */}
      <div className="mt-4 flex items-center flex-wrap gap-2">
        {selectedStatus && (
          <div className="flex items-center px-3 py-1 text-sm border border-gray-300 rounded-full">
            Status: {selectedStatus}
            <button
              onClick={() => handleSelect("status", "")}
              className="ml-2 text-gray-500 hover:text-red-500"
            >
              ✖
            </button>
          </div>
        )}
        {selectedVideoScore && (
          <div className="flex items-center px-3 py-1 text-sm border border-gray-300 rounded-full">
            Video Score: {selectedVideoScore}
            <button
              onClick={() => handleSelect("video_score", "")}
              className="ml-2 text-gray-500 hover:text-red-500"
            >
              ✖
            </button>
          </div>
        )}
        {selectedQuestionsScore && (
          <div className="flex items-center px-3 py-1 text-sm border border-gray-300 rounded-full">
            Questions Score: {selectedQuestionsScore}
            <button
              onClick={() => handleSelect("questions_score", "")}
              className="ml-2 text-gray-500 hover:text-red-500"
            >
              ✖
            </button>
          </div>
        )}
        {(selectedStatus || selectedVideoScore || selectedQuestionsScore) && (
          <button
            onClick={handleClearFilters}
            className="px-4 py-1 text-sm bg-gray-100 border border-gray-300 rounded-full hover:bg-gray-200"
          >
            Clear All
          </button>
        )}
      </div>
    </div>
  );
}

export default AIReviewerFilter;
