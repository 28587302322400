import React from 'react'

export default function Numbers2({ className }) {
    return (
        <svg
            width={33}
            height={33}
            viewBox="0 0 50 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M9.30719 44.6793C6.54665 44.6793 4.30078 42.4334 4.30078 39.6729H47.0221C47.0221 42.4334 44.7762 44.6793 42.0157 44.6793H27.3302V48.0169H33.2556C34.7757 48.0169 36.008 49.2491 36.008 50.7692H15.3149C15.3149 49.2491 16.5472 48.0169 18.0672 48.0169H23.9926V44.6793H9.30719Z"
                fill="#202020"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M45.3516 9.09278H28.5076L35.0321 2.56821C35.476 2.12437 35.476 1.4364 35.0321 0.992554C34.5883 0.548706 33.9003 0.548706 33.4565 0.992554L25.3784 9.07058L17.3004 0.992554C16.8566 0.548706 16.1686 0.548706 15.7247 0.992554C15.2809 1.4364 15.2809 2.12437 15.7247 2.56821L22.2493 9.09278H5.40528C2.96411 9.09278 0.966797 11.0679 0.966797 13.5313V40.1621C0.966797 42.6033 2.96411 44.6006 5.40528 44.6006H45.3516C47.7928 44.6006 49.7901 42.6033 49.7901 40.1621V13.5313C49.7901 11.0679 47.7928 9.09278 45.3516 9.09278ZM22.0505 33.8372L30.8831 28.7773C32.37 27.934 32.37 25.7813 30.8831 24.9158L22.0505 19.8559C20.5636 19.0126 18.7217 20.1001 18.7217 21.7867V31.8842C18.7217 33.593 20.5636 34.6805 22.0505 33.8372ZM7.62556 40.1624H43.1334C44.354 40.1624 45.3526 39.1638 45.3526 37.9432V15.7508C45.3526 14.5302 44.354 13.5315 43.1334 13.5315H7.62556C6.40498 13.5315 5.40632 14.5302 5.40632 15.7508V37.9432C5.40632 39.1638 6.40498 40.1624 7.62556 40.1624Z"
                fill="#202020"
            />
        </svg>
    )
}
