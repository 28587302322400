import React from 'react';

function NoteIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.05 8.69968L17.2333 12.183C16.5333 15.1913 15.15 16.408 12.55 16.158C12.1333 16.1247 11.6833 16.0497 11.2 15.933L9.79999 15.5997C6.32499 14.7747 5.24999 13.058 6.06665 9.57468L6.88332 6.08301C7.04999 5.37468 7.24999 4.75801 7.49999 4.24968C8.47499 2.23301 10.1333 1.69135 12.9167 2.34968L14.3083 2.67468C17.8 3.49135 18.8667 5.21635 18.05 8.69968Z" stroke="#D3D2D2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12.55 16.1581C12.0334 16.5081 11.3834 16.7998 10.5917 17.0581L9.27504 17.4914C5.96671 18.5581 4.22504 17.6664 3.15004 14.3581L2.08337 11.0664C1.01671 7.75809 1.90004 6.00809 5.20837 4.94142L6.52504 4.50809C6.86671 4.39976 7.19171 4.30809 7.50004 4.24976C7.25004 4.75809 7.05004 5.37476 6.88337 6.08309L6.06671 9.57476C5.25004 13.0581 6.32504 14.7748 9.80004 15.5998L11.2 15.9331C11.6834 16.0498 12.1334 16.1248 12.55 16.1581Z" stroke="#D3D2D2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.5333 7.10864L14.575 8.13364" stroke="#D3D2D2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M9.71667 10.3337L12.1333 10.9504" stroke="#D3D2D2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}

export default NoteIcon;