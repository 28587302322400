const DirectedArrow = ({ className }) => {
  return (
    <svg
      className={className}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.58879 10.4109L4.01129 6.83339H11.9996V5.16672H4.01129L7.58879 1.58922L6.41046 0.410889L0.821289 6.00005L6.41046 11.5892L7.58879 10.4109Z"
        fill="currentcolor"
      />
    </svg>
  );
};

export default DirectedArrow;
