import React from 'react';
import EditIcon from '../../../../../Components/svgs/EditIcon';

function Card({ content, onClick, buttonClassName, buttonContent, disabledButton, editIcon, onEditClick }) {
  return (
    <div className="rounded-lg border-gray-300 border px-7 py-4 md:w-[47%] sm:w-[100%] w-[100%] shadow-md relative">
      {editIcon && (
        <div className="absolute top-2 right-2 cursor-pointer" onClick={onEditClick}>
          <EditIcon />
        </div>
      )}

      {content.map((item, index) => (
        <div key={index} className="flex items-center mb-4 text-gray-400">
          <div className="flex items-center mr-1 ml-1">
            <item.icon className="mr-2 text-sm" />
            <p className="text-sm font-semibold">{item.title} </p>
          </div>
          <p className="text-sm">{item.content}</p>
        </div>
      ))}

      <button
        disabled={disabledButton}
        className={`${buttonClassName} ${disabledButton ? 'opacity-50 cursor-not-allowed' : ''}`}
        onClick={!disabledButton ? onClick : undefined}
      >
        {buttonContent}
      </button>
    </div>
  );
}

export default Card;
