import React from 'react'

export default function Numbers3({ className }) {
    return (
        <svg
            width={33}
            height={33}
            viewBox="0 0 33 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.9525 32.209C21.2044 32.209 25.2821 30.52 28.2886 27.5135C31.2951 24.507 32.9842 20.4292 32.9842 16.1774C32.9842 11.9255 31.2951 7.84783 28.2886 4.84131C25.2821 1.8348 21.2044 0.145752 16.9525 0.145752C12.7007 0.145752 8.62297 1.8348 5.61646 4.84131C2.60994 7.84783 0.920898 11.9255 0.920898 16.1774C0.920898 20.4292 2.60994 24.507 5.61646 27.5135C8.62297 30.52 12.7007 32.209 16.9525 32.209ZM5.59412 12.2236C6.33817 10.0928 7.66578 8.21388 9.42568 6.80089C9.96274 7.6205 10.8886 8.16157 11.9427 8.16157C12.7399 8.16157 13.5044 8.47827 14.0682 9.04199C14.6319 9.60571 14.9486 10.3703 14.9486 11.1675V12.1695C14.9486 13.2324 15.3708 14.2519 16.1225 15.0035C16.8741 15.7551 17.8935 16.1774 18.9565 16.1774C20.0195 16.1774 21.0389 15.7551 21.7905 15.0035C22.5421 14.2519 22.9644 13.2324 22.9644 12.1695C22.9641 11.2726 23.2646 10.4014 23.818 9.69554C24.3713 8.98962 25.1454 8.48971 26.0164 8.2758C27.9288 10.4635 28.9808 13.2717 28.9763 16.1774C28.9763 16.8587 28.9202 17.5301 28.8099 18.1813H26.9723C25.9094 18.1813 24.8899 18.6036 24.1383 19.3552C23.3867 20.1069 22.9644 21.1263 22.9644 22.1893V26.5919C21.1374 27.649 19.0633 28.2042 16.9525 28.2011V24.1932C16.9525 23.1302 16.5303 22.1108 15.7786 21.3592C15.027 20.6076 14.0076 20.1853 12.9446 20.1853C11.8817 20.1853 10.8622 19.763 10.1106 19.0114C9.35898 18.2598 8.93672 17.2404 8.93672 16.1774C8.93707 15.2295 8.60147 14.3122 7.98951 13.5883C7.37755 12.8645 6.52884 12.3809 5.59412 12.2236Z"
                fill="#202020"
            />
        </svg>
    )
}
