import React from 'react';
import eyouthLogo from '../../../assets/EYouthLogo.png';
import itidaLogo from '../../../assets/itida.jpeg';
import LangBtn from '../../../Components/LangBtn/LangBtn';
import { Link } from 'react-router-dom';
import { pageLinks } from '../../../constants';
import { useTranslation } from 'react-i18next';

export default function LandingNavBar() {
    const { t } = useTranslation();
    return (
        <div className='flex justify-between items-center mx-12'>
            <div className='flex gap-3'>
                <div className='w-28'>
                    <img src={eyouthLogo} alt="EYouth logo" className="cover-image" />
                </div>
                <div className='w-24'>
                    <img src={itidaLogo} alt="Ititad logo" className="cover-image" />
                </div>
            </div>
            <div className='flex gap-6'>
                <LangBtn />
                <Link to={pageLinks.login} className='btn-primary rounded-2xl font-medium px-4 py-2 w-fit h-fit'>{t("login")}</Link>
            </div>
        </div>
    )
}
