import React from 'react';
import ReactApexChart from 'react-apexcharts';

export default function DonutChart({ data, labels, customOptions = {} }) {
    // Check if data is empty or contains all zeros
    const isDataEmpty = !data || data.every((value) => value === 0);

    // Default data and labels when there's no data
    const defaultData = [1]; // A single slice to represent 'No Data'
    const defaultLabels = ['No Data'];
    const defaultColors = ['#d3d3d3']; // Gray color for 'No Data'

    // Use default data if data is empty or all zeros
    const chartData = isDataEmpty ? defaultData : data;
    const chartLabels = isDataEmpty ? defaultLabels : labels;

    // Ensure colors are provided
    const chartColors = isDataEmpty
        ? defaultColors
        : customOptions.colors || ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0'];

    const defaultOptions = {
        chart: {
            type: 'donut',
            animations: {
                enabled: !isDataEmpty, // Disable animations when there's no data
            },
            events: {
                dataPointSelection: function (event, chartContext, config) {
                    if (isDataEmpty) {
                        event.stopPropagation();
                    }
                },
            },
        },
        labels: chartLabels || [],
        legend: {
            position: 'bottom',
            show: true,
            labels: {
                useSeriesColors: false,
            },
            fontSize: '16px',
            fontWeight: 'bold',
            formatter: function (seriesName, opts) {
                const seriesIndex = opts.seriesIndex;
                const value = opts.w.globals.series[seriesIndex];
                return isDataEmpty
                    ? `<span style="color: #273C97;">${seriesName}</span>`
                    : `<span style="color: #273C97;">${seriesName}:</span> <span style="color: #FFC107;">${value}</span>`;
            },
        },
        tooltip: {
            enabled: !isDataEmpty, // Disable tooltip when there's no data
        },
        plotOptions: {
            pie: {
                expandOnClick: !isDataEmpty, // Prevent expansion when there's no data
                donut: {
                    labels: {
                        show: false, // Hide labels by default
                        total: {
                            show: false, // Hide the 'No Data' label in the center
                        },
                    },
                },
            },
        },
        states: isDataEmpty
            ? {
                normal: {
                    filter: {
                        type: 'none',
                    },
                },
                hover: {
                    filter: {
                        type: 'none',
                    },
                },
                active: {
                    filter: {
                        type: 'none',
                    },
                },
            }
            : {},
        colors: chartColors,
    };

    const options = {
        ...defaultOptions,
        ...customOptions,
        colors: chartColors, // Ensure colors are set properly
    };

    return (
        <div
            style={{
                pointerEvents: isDataEmpty ? 'none' : 'auto', // Disable pointer events when there's no data
                cursor: isDataEmpty ? 'default' : 'pointer', // Set cursor style
            }}
        >
            <ReactApexChart
                width={300}
                height={300}
                options={options}
                series={chartData}
                type="donut"
            />
        </div>
    );
}
