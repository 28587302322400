import React from "react";
import { Outlet } from "react-router-dom";
import NavSideBar from "../../../Common/NavSideBar";
import ProtectedLayout from "../../../layouts/ProtectedLayout";
import { useGetInstructorProfile } from "../../../../hooks/useGetInstructorProfile";
import MobileNavBar from "../../../Common/MobileNavBar/MobileNavBar";
import ProfileMobileMenu from "../../../Common/Profile/ProfileMobileMenu";
import InstructorInfo from "./InstructorProfile/InstructorInfo";
import ProfileCard from "../../../Common/Profile/ProfileCard";
import instructorPlaceholderImage from "../../../../assets/instructorProfileImage.png";

const InstructorLayout = () => {
  const { instructorProfileData, instructorSystemInfo } =
    useGetInstructorProfile();
  return (
    <ProtectedLayout>
      <div className="tablet:bg-[#f8f8f8] bg-white gap-x-7 flex tablet:flex-nowrap flex-wrap">
        <NavSideBar />
        <MobileNavBar>
          <ProfileMobileMenu
            placeholderImage={instructorPlaceholderImage}
            userImage={instructorProfileData?.profile_image}
          >
            <InstructorInfo
              instructorProfileData={instructorProfileData}
              instructorSystemInfo={instructorSystemInfo}
            />
          </ProfileMobileMenu>
        </MobileNavBar>

        <div
          className={`relative z-[98] tablet:border border-gray-100 tablet:px-[1rem] px-[5%] py-[1.5rem] tablet:shadow-md tablet:mt-[2rem] mt-[5rem] bg-white tablet:rounded-xl tablet:h-fit h-full min-h-[90vh] flex-1`}
        >
          <Outlet />
        </div>
        <ProfileCard>
          <InstructorInfo
            instructorProfileData={instructorProfileData}
            instructorSystemInfo={instructorSystemInfo}
          />
        </ProfileCard>
      </div>
    </ProtectedLayout>
  );
};

export default InstructorLayout;
