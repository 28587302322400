import React from 'react';

export default function AddIcon({ className }) {
    return (
        <svg
            width={20}
            height={20}
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className={className}
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4.54184 0.368993C2.34504 0.659124 0.659124 2.34504 0.368993 4.54184C0.169534 6.05208 0 7.94127 0 10C0 12.0587 0.169534 13.9479 0.368993 15.4582C0.659124 17.655 2.34504 19.3409 4.54184 19.631C6.05208 19.8305 7.94127 20 10 20C12.0587 20 13.9479 19.8305 15.4582 19.631C17.655 19.3409 19.3409 17.655 19.631 15.4582C19.8305 13.9479 20 12.0587 20 10C20 7.94127 19.8305 6.05208 19.631 4.54184C19.3409 2.34504 17.655 0.659124 15.4582 0.368993C13.9479 0.169534 12.0587 0 10 0C7.94127 0 6.05208 0.169534 4.54184 0.368993ZM10 5C9.44772 5 9 5.44771 9 6V9H6C5.44772 9 5 9.44772 5 10C5 10.5523 5.44771 11 6 11H9V14C9 14.5523 9.44772 15 10 15C10.5523 15 11 14.5523 11 14V11H14C14.5523 11 15 10.5523 15 10C15 9.44772 14.5523 9 14 9H11V6C11 5.44772 10.5523 5 10 5Z'
                fill='white'
            />
        </svg>
    );
}
