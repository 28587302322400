import axios from "axios";
import { useState, useEffect } from "react";

const usePagination = (url, refetch, disabled = false) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [nextPage, setNextPage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [link, setLink] = useState("");
  const [refetchState, setRefetchState] = useState(null);

  const fetchData = async (pageNumber) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${url}&page=${pageNumber}&page_size=5`);
      setData((prev) => [...prev, ...response.data.results]);
      setNextPage(response.data.next);
    } catch (err) {
      console.error("Error fetching data:", err);
      setError(err.message || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };
  // console.log("data", data);
  // console.log("url", url);

  useEffect(() => {
    if (disabled) return;
    if (url === link && refetchState === refetch) return;
    setLink(url);
    setRefetchState(refetch);
    setData([]);
    setPage(1);
    setNextPage("");
    fetchData(1);
    window.scrollTo(0, 0);
  }, [url, refetch, disabled]);

  useEffect(() => {
    if (disabled) return;
    if (page === 1) return;
    fetchData(page);
  }, [page, disabled]);

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - 20 &&
      !isLoading &&
      nextPage
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };
  useEffect(() => {
    if (disabled) return;
    if (
      window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - 20 &&
      !isLoading &&
      nextPage
    ) {
      setPage((prevPage) => prevPage + 1);
    }
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isLoading, disabled]);

  return { data, isLoading, error };
};

export default usePagination;
