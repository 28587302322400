const CloseIcon = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="46"
      height="45"
      viewBox="0 0 46 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.28809 7.75635L37.8033 37.2715M37.8033 7.75635L8.28809 37.2715"
        stroke="currentcolor"
        strokeWidth="3.6894"
        strokeLinecap="round"
        fill="currentcolor"
      />
    </svg>
  );
};

export default CloseIcon;
