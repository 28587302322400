import React from 'react';

function AddIcon2({ className }) {
  return (
    <svg className={className} width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.17075 1.16587C2.97395 1.456 1.28803 3.14192 0.997899 5.33871C0.79844 6.84896 0.628906 8.73815 0.628906 10.7969C0.628906 12.8556 0.79844 14.7448 0.997899 16.255C1.28803 18.4518 2.97395 20.1378 5.17075 20.4279C6.68099 20.6273 8.57018 20.7969 10.6289 20.7969C12.6876 20.7969 14.5768 20.6273 16.0871 20.4279C18.2839 20.1378 19.9698 18.4518 20.2599 16.255C20.4594 14.7448 20.6289 12.8556 20.6289 10.7969C20.6289 8.73815 20.4594 6.84896 20.2599 5.33871C19.9698 3.14192 18.2839 1.456 16.0871 1.16587C14.5768 0.966409 12.6876 0.796875 10.6289 0.796875C8.57018 0.796875 6.68099 0.966409 5.17075 1.16587ZM10.6289 5.79688C10.0766 5.79688 9.62891 6.24459 9.62891 6.79688V9.79688H6.62891C6.07662 9.79688 5.62891 10.2446 5.62891 10.7969C5.62891 11.3492 6.07662 11.7969 6.62891 11.7969H9.62891V14.7969C9.62891 15.3492 10.0766 15.7969 10.6289 15.7969C11.1812 15.7969 11.6289 15.3492 11.6289 14.7969V11.7969H14.6289C15.1812 11.7969 15.6289 11.3492 15.6289 10.7969C15.6289 10.2446 15.1812 9.79688 14.6289 9.79688H11.6289V6.79688C11.6289 6.24459 11.1812 5.79688 10.6289 5.79688Z" fill="#273C97" />
    </svg>

  );
}

export default AddIcon2;