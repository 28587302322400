import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import apiPostWebinar from '../../../../../Services/apiPostWebinar';
import CalenderIcon from '../../../../../Components/svgs/CalenderIcon';
import useFetch from '../../../../../hooks/useFetch';
import { UserProfileURL } from '../../../../../Services/endPoints';

const WebinarSchema = Yup.object().shape({
    name: Yup.string().required('Webinar Topic is required'),
    job_profile_round: Yup.string().required('Job Profile Code is required'),
    link: Yup.string()
        .url('Enter a valid URL https://....')
        .matches(/^https:\/\//, 'URL must start with https://')
        .required('Webinar Link is required'),
    date: Yup.date()
        .required('Date is required')
        .min(new Date(), 'Date cannot be in the past'),
    time: Yup.date().required('Start Time is required'),
    group: Yup.string()
        .oneOf(['1', '2'], 'Invalid group selected')
        .required('Group is required'),
});

const AddWebinarModal = ({ isOpen, onClose, onSubmit }) => {
    const { data: instructorProfileData } = useFetch(UserProfileURL);
    const jobProfileRounds =
        instructorProfileData?.job_profile_rounds || [];

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-lg w-[90%] sm:w-[60%] lg:w-[40%]">
                <h2 className="text-xl text-[#3F3F3F] font-bold mb-8">
                    Add New Webinar
                </h2>
                <Formik
                    initialValues={{
                        name: '',
                        job_profile_round: '',
                        link: '',
                        date: null,
                        time: null,
                        group: '',
                        is_finished: false,
                    }}
                    validationSchema={WebinarSchema}
                    onSubmit={async (values, { setSubmitting, setErrors }) => {
                        try {
                            // Format the date and time
                            const formattedDate = values.date
                                .toISOString()
                                .split('T')[0]; // "YYYY-MM-DD"
                            const formattedTime = values.time
                                .toISOString()
                                .split('T')[1]
                                .split('.')[0]; // "HH:mm:ss"

                            const webinarData = {
                                name: values.name,
                                job_profile_round: values.job_profile_round,
                                link: values.link,
                                date: formattedDate,
                                time: formattedTime,
                                group: values.group,
                            };

                            await apiPostWebinar(webinarData);
                            toast.success('Webinar added successfully!');
                            onSubmit(); // Notify parent component to update the list
                            onClose(); // Close modal after submission
                        } catch (error) {
                            const backendErrors =
                                error.response?.data || {};
                            setErrors(backendErrors);
                            const errorMsg =
                                backendErrors.detail ||
                                'Failed to add webinar.';
                            toast.error(errorMsg);
                        } finally {
                            setSubmitting(false);
                        }
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        isSubmitting,
                        setFieldValue,
                    }) => (
                        <Form>
                            {/* Name */}
                            <div className="mb-4">
                                <label
                                    htmlFor="name"
                                    className="block text-gray-700 font-medium mb-2"
                                >
                                    Webinar Topic
                                </label>
                                <Field
                                    type="text"
                                    name="name"
                                    id="name"
                                    className={`w-full border rounded-lg p-2 ${
                                        errors.name && touched.name
                                            ? 'border-red-500'
                                            : ''
                                    }`}
                                />
                                <ErrorMessage
                                    name="name"
                                    component="div"
                                    className="text-red-500 text-sm"
                                />
                            </div>

                            {/* Webinar Link */}
                            <div className="mb-4">
                                <label
                                    htmlFor="link"
                                    className="block text-gray-700 font-medium mb-2"
                                >
                                    Webinar Link
                                </label>
                                <Field
                                    type="url"
                                    name="link"
                                    id="link"
                                    className={`w-full border rounded-lg p-2 ${
                                        errors.link && touched.link
                                            ? 'border-red-500'
                                            : ''
                                    }`}
                                />
                                <ErrorMessage
                                    name="link"
                                    component="div"
                                    className="text-red-500 text-sm"
                                />
                            </div>

                            {/* Date */}
                            <div className="mb-4 relative">
                                <label
                                    htmlFor="date"
                                    className="block text-gray-700 font-medium mb-2"
                                >
                                    Date
                                </label>
                                <DatePicker
                                    selected={values.date}
                                    onChange={(date) =>
                                        setFieldValue('date', date)
                                    }
                                    minDate={new Date()}
                                    dateFormat="yyyy-MM-dd"
                                    className={`w-full border rounded-lg p-2 cursor-pointer ${
                                        errors.date && touched.date
                                            ? 'border-red-500'
                                            : ''
                                    }`}
                                />
                                <CalenderIcon className="absolute right-3 top-[3rem] pointer-events-none" />
                                <ErrorMessage
                                    name="date"
                                    component="div"
                                    className="text-red-500 text-sm"
                                />
                            </div>

                            {/* Time */}
                            <div className="mb-4">
                                <label
                                    htmlFor="time"
                                    className="block text-gray-700 font-medium mb-2 w-full"
                                >
                                    Start Time
                                </label>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        value={values.time}
                                        onChange={(time) =>
                                            setFieldValue('time', time)
                                        }
                                        renderInput={({ inputProps }) => (
                                            <input
                                                {...inputProps}
                                                className={`w-full border rounded-lg p-2 ${
                                                    errors.time && touched.time
                                                        ? 'border-red-500'
                                                        : ''
                                                }`}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                                <ErrorMessage
                                    name="time"
                                    component="div"
                                    className="text-red-500 text-sm"
                                />
                            </div>

                            {/* Job Profile Code */}
                            <div className="mb-4">
                                <label
                                    htmlFor="job_profile_round"
                                    className="block text-gray-700 font-medium mb-2"
                                >
                                    Job Profile Code
                                </label>
                                <Field
                                    as="select"
                                    name="job_profile_round"
                                    id="job_profile_round"
                                    className={`w-full border rounded-lg p-2 ${
                                        errors.job_profile_round &&
                                        touched.job_profile_round
                                            ? 'border-red-500'
                                            : ''
                                    }`}
                                >
                                    <option value="">
                                        Select Job Profile Code
                                    </option>
                                    {jobProfileRounds.map((round, index) => (
                                        <option
                                            key={index}
                                            value={round.code}
                                        >
                                            {round.code}
                                        </option>
                                    ))}
                                </Field>
                                <ErrorMessage
                                    name="job_profile_round"
                                    component="div"
                                    className="text-red-500 text-sm"
                                />
                            </div>

                            {/* Group */}
                            <div className="mb-4">
                                <label
                                    htmlFor="group"
                                    className="block text-gray-700 font-medium mb-2"
                                >
                                    Group
                                </label>
                                <Field
                                    as="select"
                                    name="group"
                                    className={`w-full border rounded-lg p-2 ${
                                        errors.group && touched.group
                                            ? 'border-red-500'
                                            : ''
                                    }`}
                                >
                                    <option value="">Select Group</option>
                                    <option value="1">Group 1</option>
                                    <option value="2">Group 2</option>
                                </Field>
                                <ErrorMessage
                                    name="group"
                                    component="div"
                                    className="text-red-500 text-sm"
                                />
                            </div>

                            <div className="flex justify-end gap-4">
                                <button
                                    type="submit"
                                    className="bg-blue-600 text-white px-4 py-2 rounded-md"
                                >
                                    {isSubmitting
                                        ? 'Submitting...'
                                        : 'Add Webinar'}
                                </button>
                                <button
                                    type="button"
                                    className='p-3 border-solid border border-[#EE201C] rounded-lg hover:opacity-80 text-[#EE201C]'
                                    onClick={onClose}
                                >
                                    Cancel
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default AddWebinarModal;
