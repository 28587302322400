import React from "react";

export default function ArrowWhite({
  className,
  color = "#273C97",
  text = "hello",
}) {
  return (
    <svg
      width={109}
      height={57}
      viewBox="0 0 109 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect y={13.1455} width={67.6071} height={30.0476} rx={3} fill={color} />
      <path
        d="M68.5186 55.879L107.59 30.7136C109.47 29.5386 109.47 26.8007 107.59 25.6256L68.5186 0.460299C66.5204 -0.788541 63.9286 0.647991 63.9286 3.0043L63.9286 53.335C63.9286 55.6913 66.5204 57.1278 68.5186 55.879Z"
        fill={color}
      />

      <text x="10" y="33" fill="white" fontSize="12" fontWeight="bold">
        {text}
      </text>
    </svg>
  );
}
