import React, { useState, useEffect } from "react";
import Card from "../../Components/Common/Card/Card";
import Person from "../../../../Components/svgs/Person";
import CalenderIcon from "../../../../Components/svgs/CalenderIcon";
import NetworkingIcon from "../../../../Components/svgs/NetworkingIcon";
import QuestionIcon from "../../../../Components/svgs/QuestionIcon";
import EditIcon from "../../../../Components/svgs/EditIcon";
import {
  DeliverablesFeedBackEndPoint,
  DeliverablesInstructorEndPoint,
  DeliverablesUpdateFeedBackEndPoint,
} from "../../../../Services/endPoints";
import useFetch from "../../../../hooks/useFetch";
import { Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSearchParams } from "react-router-dom";
import usePagination from "../../../../hooks/usePagination";
import Loading from "../../../../Components/shared/Loading";
import CardSkeleton from "../Common/Card/CardSkeleton";

const DeliverablesComponent = ({ isPaginationDisabled = false, pageTitle }) => {
  const [isReviewDialogOpen, setReviewDialogOpen] = useState(false);
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);
  const [description, setDescription] = useState("");
  const [grade, setGrade] = useState("");
  const [error, setError] = useState("");
  const [searchParams] = useSearchParams();
  const [isStatusChanged, setIsStatusChanged] = useState(false);

  const {
    data: deliverablesDataPaginated,
    isLoading: paginationLoading,
    error: paginationError,
  } = usePagination(
    `${DeliverablesInstructorEndPoint}?${searchParams.toString()}`,
    isStatusChanged,
    isPaginationDisabled
  );
  const {
    data: deliverablesData,
    loading: deliverablesLoading,
    error: fetchError,
  } = useFetch(
    `${DeliverablesInstructorEndPoint}?page=1&page_size=5`,
    [isStatusChanged],
    !isPaginationDisabled
  );

  if (deliverablesLoading && isPaginationDisabled) {
    return <Loading />;
  }
  if (paginationError || fetchError) {
    return (
      <p className="text-gray-5f text-center font-semibold w-full text-xl my-3">
        Something went wrong !
      </p>
    );
  }
  const dataToRender = isPaginationDisabled
    ? deliverablesData?.results
    : deliverablesDataPaginated;

  if (dataToRender?.length === 0) {
    return (
      <p className="text-gray-5f text-center font-semibold w-full text-xl absolute-center">
        No Deliverables submitted yet
      </p>
    );
  }

  const handleReviewClick = (review) => {
    setSelectedReview(review);
    setDescription("");
    setGrade("");
    setError("");
    setReviewDialogOpen(true);
  };

  const handleEditClick = async (review) => {
    setSelectedReview(review);
    setDescription(review.feedback?.description || "");
    setGrade(review.feedback?.degree || "");
    setError("");
    setReviewDialogOpen(true);
    const { data } = await axios.get(
      `${DeliverablesUpdateFeedBackEndPoint}${review.feedback_id}/`
    );
    setDescription(data?.description);
    setGrade(data?.degree);
  };

  const handleSaveReview = () => {
    if (!description.trim() || !grade.trim()) {
      setError("Both Comment and Grade are required.");
      return;
    }
    setError("");
    setReviewDialogOpen(false);
    setConfirmDialogOpen(true);
  };

  const handleConfirmSave = async () => {
    const payload = {
      deliverable_result: selectedReview?.id,
      description,
      degree: parseInt(grade, 10),
    };

    try {
      if (selectedReview?.has_feedback) {
        // PATCH request for editing feedback
        const response = await axios.patch(
          `${DeliverablesUpdateFeedBackEndPoint}${selectedReview.feedback_id}/`,
          {
            description,
            degree: parseInt(grade, 10),
          }
        );
        if (response.status === 200) {
          toast.success("Feedback updated successfully!");
        } else {
          toast.error("Failed to update feedback.");
        }
      } else {
        // POST request for new feedback
        const response = await axios.post(
          DeliverablesFeedBackEndPoint,
          payload
        );
        if (response.status === 201) {
          toast.success("Feedback saved successfully!");
        } else {
          toast.error("Failed to save feedback.");
        }
      }
      setIsStatusChanged((prev) => !prev);
      setConfirmDialogOpen(false);
    } catch (error) {
      toast.error("Error saving feedback: " + error.message);
    }
  };

  return (
    <div>
      {pageTitle && (
        <h1 className="mt-5 mb-3 font-medium text-base">{pageTitle}</h1>
      )}

      <div className="flex gap-10 flex-wrap ml-4">
        {dataToRender?.map((review, index) => (
          <Card
            key={index}
            content={[
              {
                icon: Person,
                title: "Student : ",
                content: review.student_email || "N/A",
              },
              {
                icon: CalenderIcon,
                title: "Created On : ",
                content:
                  new Date(review.created_on).toLocaleDateString("en-GB") ||
                  "N/A",
              },
              {
                icon: NetworkingIcon,
                title: "Coaching Round : ",
                content: review.coaching_rounds || "N/A",
              },
              {
                icon: QuestionIcon,
                title: "Task : ",
                content: review.deliverable_task_name || "N/A",
              },
            ]}
            buttonClassName={`bg-blue-800 ${
              review.has_feedback
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-blue-800"
            } text-white py-2 w-full text-center rounded-xl`}
            buttonContent={
              review.has_feedback ? "Feedback Given" : "Add Your Review"
            }
            disabledButton={review.has_feedback}
            editIcon={review.has_feedback ? EditIcon : null}
            onEditClick={
              review.has_feedback ? () => handleEditClick(review) : undefined
            }
            onClick={
              !review.has_feedback ? () => handleReviewClick(review) : undefined
            }
          />
        ))}
        {paginationLoading && !isPaginationDisabled && (
          <>
            <CardSkeleton />
            <CardSkeleton />
          </>
        )}
      </div>

      {/* Review Dialog */}
      <Dialog
        open={isReviewDialogOpen}
        onClose={() => setReviewDialogOpen(false)}
      >
        <DialogContent className="p-5">
          <h2 className="text-center p-5">
            {selectedReview?.deliverable_task_name || "Task Name"}
          </h2>
          <p>
            <strong>Student:</strong> {selectedReview?.student_email}
          </p>
          {selectedReview?.deliverable_attachments?.map((attachment, index) => (
            <div key={index}>
              <p className="my-2">
                <strong>File:</strong>
                <a
                  className="text-primary"
                  href={attachment.attachment}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View File
                </a>
              </p>
              <p className="my-2">
                <strong>URL:</strong>{" "}
                <a
                  className="text-primary underline"
                  href={attachment.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit Link
                </a>
              </p>
            </div>
          ))}
          <TextField
            fullWidth
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Your Comment..."
            error={!!error}
            helperText={error && "This field is required."}
            style={{ marginBottom: "20px" }}
          />
          <TextField
            fullWidth
            value={grade}
            onChange={(e) => setGrade(e.target.value)}
            placeholder="Grade"
            error={!!error}
            helperText={error && "This field is required."}
          />
        </DialogContent>
        <DialogActions>
          <button className="btn btn-primary" onClick={handleSaveReview}>
            Submit
          </button>
          <button
            style={{ border: "1px solid #EE201C" }}
            className="btn bg-white hover:bg-white text-[#EE201C]"
            onClick={() => setReviewDialogOpen(false)}
          >
            Cancel
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isConfirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <DialogContent>
          <h3 className="flex items-center py-5">
            Are you sure you want to save this feedback?
          </h3>
          <p>
            <strong>Task:</strong> {selectedReview?.deliverable_task_name}
          </p>
          <p>
            <strong>Student:</strong> {selectedReview?.student_email}
          </p>
          <p>
            <strong>Task Grade:</strong> {grade}
          </p>
          <p>
            <strong>Your Comment:</strong> {description}
          </p>
        </DialogContent>
        <DialogActions>
          <button
            style={{ border: "1px solid #EE201C" }}
            className="btn bg-white hover:bg-white text-[#EE201C]"
            onClick={() => setConfirmDialogOpen(false)}
          >
            Cancel
          </button>
          <button className="btn btn-primary" onClick={handleConfirmSave}>
            Confirm
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeliverablesComponent;
