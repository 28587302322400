import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from '../Translations/en.json';
import ar from '../Translations/ar.json';

const resources = {
    en: { translation: en },
    ar: { translation: ar },
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: localStorage.getItem('i18nextLng') || 'en',
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        },
        // debug: true,
    });

// Set `dir` attribute on initial load
const savedLanguage = localStorage.getItem("i18nextLng") || "en";
document.documentElement.lang = savedLanguage; // Set `lang` attribute
document.documentElement.dir = savedLanguage === "ar" ? "rtl" : "ltr"; // Set `dir` attribute

i18n.on("languageChanged", (lng) => {
    document.documentElement.lang = lng;
    document.documentElement.dir = lng === "ar" ? "rtl" : "ltr";
});

export default i18n;