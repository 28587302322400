// Student profile
export const UserProfileURL = "/user/profile/";
export const freelanceProfileLinksEndpoint = "/freelance-profile-links/";
export const freelancePlatformsEndpoint = "/platforms/";
export const CoachingRoundEndPoint = "/coaching-rounds/";
// next Webinars
export const NextWebinars = "/webinars/next/";
// QA
export const categoryEndPoint = "/category/";
export const questionEndPoint = "/question/";
// webinar
export const allWebinarsURL = "/webinars/all";
export const allWebinarsFeedbackURL = "/webinar-feedback/";
export const webinarAttendanceEndPoint = "/webinar-attendance/";
//Jobs
export const getJobsCategoryEndPoint = "/freelance-category/";
export const postFreelanceJobEndPoint = "/freelance-jobs/";
export const getFreelanceJobMyJobsEndPoint = "/freelance-jobs/";
export const patchStatusMyJobsEndPoint = "/freelance-jobs";
// Course student view
export const CourseHomeEndPoint = "/course/home/";
export const CourseStudentEndPoint = "/course/student/";
export const CompleteLessonEndPoint = "/course/complete-lesson/";

// Settings
export const UserChangeProfilePicture = "/user/change-profile-image/";
export const UserChangePassword = "/user/change-password/";
export const UserRequestSupportEndPoint = "/user/request-support/";

// Deliverables
export const UserDeliverablesStudents = "/deliverable-results/student/";
export const GetDeliverablesById = "/deliverables/";
export const PostDeliverableAttachments = "/deliverable-attachments/";
export const GetDeliverableResultsById = "/deliverable-feedback/";
export const TasksName = "/deliverable-needfeedback/";

//Ai Reviewer History
export const AiReviewerGetAll = "/proposal/";
// Instructor Deliverables
// Instructor Review
export const DeliverablesInstructorEndPoint = "/deliverable-results/";
export const DeliverablesFeedBackEndPoint = "/deliverable-feedback/";
export const DeliverablesUpdateFeedBackEndPoint =
  "/deliverable-feedback-details/";

// question Instructor
export const QAInstructorEndPoint = "/question/filter/";
export const QAAnsweEndPoint = "question/answers/";

// instructor webinar
export const InstructorWebinar = "/webinars/";

// admin
export const dashboardInstructorsEndPoint = "/dashboard/instructors/";
export const dashboardInstructorsStatisticsEndPoint =
  "/dashboard/instructor-statistics/";
export const dashboardStudentsEndPoint = "/dashboard/students/";
export const jobProfileRoundsEndPoint = "/job-profile-rounds/";
export const dashboardStudentsTechProvider = "/student-technical-provider/";
export const dashboardStudentsTrack = "/track/";
export const dashboardStudentsStatisticsEndPoint =
  "/dashboard/student-statistics/";
export const dashboardWebinarsStatisticsEndPoint =
  "/dashboard/webinar-statistics/";

export const dashboardWebinars = "/dashboard/webinars/";
export const dashboardLeadsEndPoint = "/api/leads/";
export const dashboardLeadsStatisticsEndPoint = "/api/leads-statistics/";
export const dashboardAIInterveiwingEndPoint = "/api/leads-details/";
export const dashboardReviewsEndPoint = "/dashboard/reviews/";
export const dashboardReviewsStatisticsEndPoint = "/dashboard/reviews-statistics/";



