import { UserProfileURL } from "../Services/endPoints";
import useFetch from "./useFetch";

const instructorSystemInfo = [
  {
    name: `Coach 
ID`,
    fieldName: "id",
  },
  {
    name: "Coaching Rounds",
    fieldName: "coaching_rounds",
  },
  {
    name: "Job Profiles",
    fieldName: "job_profile_rounds",
  },
];
export const useGetInstructorProfile = () => {
  const { data: instructorProfileData } = useFetch(UserProfileURL);

  return { instructorProfileData, instructorSystemInfo };
};
