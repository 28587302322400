import { Navigate, Route, Routes } from "react-router-dom";
import AdminLayout from "./AdminDashboard/components/AdminLayout";
import { pageRoutes } from "../constants";
import NotFoundPage from "./Common/NotFoundPage";
import Students from './AdminDashboard/pages/Students';
import Instructors from './AdminDashboard/pages/Instructors';
import Webinars from './AdminDashboard/pages/Webinars';
import InstructorDetails from "./AdminDashboard/pages/InstructorDetails";
import Reviews from './AdminDashboard/pages/Reviews';
import AIReviewer from './AdminDashboard/pages/AIReviewer';
import AIInterviewing from "./AdminDashboard/pages/AIInterviewing";

const AdminRoutes = () => {
  return (
    <Routes>
      <Route index element={<Navigate to={`/${pageRoutes.dashboard}`} />} />
      <Route element={<AdminLayout />}>
        <Route path={pageRoutes.dashboard} element={<></>} />
        <Route path={pageRoutes.students} element={<Students />} />
        <Route path={pageRoutes.instructors} element={<Instructors />} />
        <Route path={`${pageRoutes.instructors}/${pageRoutes.instructorDetails}/:id`} element={<InstructorDetails />} />
        <Route path={`ai-interviewing/:id`} element={<AIInterviewing />} />

        <Route path={pageRoutes.webinars} element={<Webinars />} />
        <Route path={pageRoutes.reviews} element={<Reviews />} />
        <Route path={pageRoutes.aiReviewer} element={<AIReviewer />} />
        {/* <Route path={pageRoutes.aiInterviewing} element={<AIInterviewing />} /> */}
        <Route path={pageRoutes.qa} element={<></>} />

        <Route path={pageRoutes.settings} element={<></>} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default AdminRoutes;
