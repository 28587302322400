import React from "react";
import { useSearchParams } from "react-router-dom";
import Header from "../components/Header";
import CardsInfo from "../components/CardsInfo";
import group from "../../../assets/Group2.png";
import student from "../../../assets/student.png";
import Table from "../Common/Table/Table";
import ReviewerFilters from "../components/ReviewsFilter";
import usePaginationNumbers from "../../../hooks/usePaginationNumbers";
import { dashboardReviewsEndPoint, dashboardReviewsStatisticsEndPoint } from "../../../Services/endPoints";
import TableSkeleton from "../Common/Table/TableSkeleton";
import useFetch from '../../../hooks/useFetch';

function Reviews() {
  const [searchParams] = useSearchParams();

  const coachingRound = searchParams.get("coaching_round");
  const deliverable = searchParams.get("deliverable");

  const queryParams = new URLSearchParams();
  if (coachingRound) queryParams.append("coaching_round", coachingRound);
  if (deliverable) queryParams.append("deliverable", deliverable);

  const dynamicReviewsEndpoint = `${dashboardReviewsEndPoint}?${queryParams.toString()}`;

  const {
    data: dashboardReviewsData,
    page,
    totalPages,
    isLoading,
    error,
    rowsPerPage,
    handlePageChange,
    setCurrentPage,
    nextPage,
    prevPage,
    setRowsPerPage: handleRowsPerPageChange,
  } = usePaginationNumbers(dynamicReviewsEndpoint);

  const totalReviews = dashboardReviewsData?.count || 0;
  const {
    data: dashboardReviewsStatisticsData,
  } = useFetch(dashboardReviewsStatisticsEndPoint);


  const tableData = dashboardReviewsData.map((review) => ({
    id: review.id,
    student: review.student,
    studentEmail: review.student_email,
    instructorEmail: review.instructor_email,
    deliverable: review.deliverable,
    deliverableTaskName: review.deliverable_task_name,
    coachingRounds: review.coaching_rounds,
    completed: review.completed ? "Yes" : "No",
    hasFeedback: review.has_feedback ? "Yes" : "No",
    createdOn: new Date(review.created_on).toLocaleDateString(),
  }));

  const tableColumns = [
    { header: "Review ID", accessor: "id" },
    { header: "Student ID", accessor: "student" },
    { header: "Student Email", accessor: "studentEmail" },
    { header: "Instructor Email", accessor: "instructorEmail" },
    { header: "Deliverable", accessor: "deliverable" },
    { header: "Task Name", accessor: "deliverableTaskName" },
    { header: "Coaching Rounds", accessor: "coachingRounds" },
    { header: "Completed", accessor: "completed" },
    { header: "Feedback", accessor: "hasFeedback" },
    { header: "Created On", accessor: "createdOn" },
  ];

  const reviewCards = [
    {
      img: <img src={student} alt="student" />,
      title: "Total Reviews",
      value: dashboardReviewsStatisticsData?.deliverables_count,
    },
    {
      img: <img src={group} alt="group" />,
      title: "Pending Feedback",
      value: dashboardReviewsStatisticsData?.reviewed_deliverables,
    },
    {
      img: <img src={student} alt="student" />,
      title: "Average Review Time",
      value: dashboardReviewsStatisticsData?.average_feedback_response_time || 0
    },
  ];

  return (
    <div className="p-4">
      <Header>
        <CardsInfo cards={reviewCards} />
      </Header>

      <ReviewerFilters />

      <div className="mt-6">
        {isLoading ? (
          <TableSkeleton columns={tableColumns.length} rows={5} />
        ) : error ? (
          <p>Error fetching data</p>
        ) : dashboardReviewsData.length > 0 ? (
          <Table
            columns={tableColumns}
            data={tableData}
            rowsPerPageOptions={[5, 10, 20]}
            isLoading={isLoading}
            currentPage={page}
            totalPages={totalPages}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={handleRowsPerPageChange}
            setCurrentPage={setCurrentPage}
            handlePageChange={handlePageChange}
            prevPage={prevPage}
            nextPage={nextPage}
          />
        ) : (
          <p>No data available</p>
        )}
      </div>
    </div>
  );
}

export default Reviews;
