import React from 'react'

export default function Details3({ className }) {
    return (
        <svg
            width={80}
            height={80}
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <g clipPath="url(#clip0_805_5413)">
                <path
                    opacity={0.2}
                    d="M13.75 13.75V61.25L18.75 66.25H66.25V13.75H13.75Z"
                    fill="black"
                />
                <path
                    d="M13.75 44.7727V16.25C13.75 15.587 14.0134 14.9511 14.4822 14.4822C14.9511 14.0134 15.587 13.75 16.25 13.75H63.75C64.413 13.75 65.0489 14.0134 65.5178 14.4822C65.9866 14.9511 66.25 15.587 66.25 16.25V63.75C66.25 64.413 65.9866 65.0489 65.5178 65.5178C65.0489 65.9866 64.413 66.25 63.75 66.25H42.3862"
                    stroke="black"
                    strokeWidth={4}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M40 47.5L20 67.5L10 57.5"
                    stroke="black"
                    strokeWidth={4}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_805_5413">
                    <rect width={80} height={80} fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
