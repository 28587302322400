import React from 'react'

export default function Details4({ className }) {
    return (
        <svg
            width={80}
            height={80}
            viewBox="0 0 81 73"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M73.704 0.744973C77.3334 0.744973 80.4044 3.81598 80.4044 7.44534V52.1145C80.4044 55.8834 77.3334 58.8148 73.704 58.8148H46.9026L49.136 65.5152H59.1866C61.0013 65.5152 62.5368 67.0507 62.5368 68.8654C62.5368 70.8197 61.0013 72.2156 59.1866 72.2156H21.2178C19.2636 72.2156 17.8676 70.8197 17.8676 68.8654C17.8676 67.0507 19.2636 65.5152 21.2178 65.5152H31.2684L33.5018 58.8148H6.70037C2.93141 58.8148 0 55.8834 0 52.1145V7.44534C0 3.81598 2.93141 0.744973 6.70037 0.744973H73.704ZM71.4706 49.881V9.6788H8.93382V49.881H71.4706Z"
                fill="#0A0338"
            />
        </svg>
    )
}
