import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import axios from "axios";
import './Services/i18n';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import i18n from "./Services/i18n";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.headers.common["Authorization"] =
  `jwt ${localStorage.getItem("access_token")}` || "";
axios.defaults.headers.common["accept"] = "application/json";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <App />
    <ToastContainer position="top-center" />
  </>
);

// Listen to language changes and adjust `dir` dynamically
i18n.on("languageChanged", (lng) => {
  const htmlElement = document.documentElement;
  htmlElement.lang = lng; 
  htmlElement.dir = lng === "ar" ? "rtl" : "ltr"; // Set `dir` based on the language
});