import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { pageLinks } from "./constants";
import { roleRoutesLookup } from "./constants/roleRoutesLookup";
import { getRole, getToken } from "./Helpers/constants";
import Login from "./NewPages/Common/Login/Login";
import LandingPage from "./NewPages/LandingPage/LandingPage";

function App() {
  const role = getRole();
  const token = getToken();

  // if ((!role || !token) && window.location.pathname !== pageLinks.login) {
  //   window.location.href = `${pageLinks.login}`;
  // }

  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path={pageLinks.landingPage} element={<LandingPage />} />
        <Route path={pageLinks.login} element={<Login />} />

        {/* Protected Routes */}
        {role && token ? (
          <Route path="/*" element={roleRoutesLookup[role]} />
        ) : (
          // Redirect unauthenticated users to Landing Page
          <Route path="/*" element={<Navigate to={pageLinks.landingPage} />} />
        )}
      </Routes>
    </Router>
  );
}

export default App;
