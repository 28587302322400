import React from 'react'

export default function ScrollDown({ className }) {
    return (
        <svg
            width={34}
            height={34}
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M12.8197 18.9131L12.4661 18.5595L11.759 19.2666L12.1126 19.6202L12.8197 18.9131ZM16.9995 23.8L16.6459 24.1535L16.9995 24.5071L17.353 24.1535L16.9995 23.8ZM21.8864 19.6202L22.2399 19.2666L21.5328 18.5595L21.1793 18.9131L21.8864 19.6202ZM1.13281 17L0.632813 17L1.13281 17ZM16.9995 1.1333L16.9995 0.633301L16.9995 1.1333ZM16.9995 32.8666L16.9995 32.3666L16.9995 32.8666ZM32.8661 17L32.3661 17L32.8661 17ZM12.1126 19.6202L16.6459 24.1535L17.353 23.4464L12.8197 18.9131L12.1126 19.6202ZM17.353 24.1535L21.8864 19.6202L21.1793 18.9131L16.6459 23.4464L17.353 24.1535ZM17.4995 23.8L17.4995 9.06663L16.4995 9.06663L16.4995 23.8L17.4995 23.8ZM1.63281 17C1.63281 8.51319 8.5127 1.6333 16.9995 1.6333L16.9995 0.633301C7.96042 0.6333 0.632813 7.9609 0.632813 17L1.63281 17ZM16.9995 32.3666C8.5127 32.3666 1.63281 25.4867 1.63281 17L0.632813 17C0.632812 26.039 7.96041 33.3666 16.9995 33.3666L16.9995 32.3666ZM32.3661 17C32.3661 25.4867 25.4862 32.3666 16.9995 32.3666L16.9995 33.3666C26.0385 33.3666 33.3661 26.039 33.3661 17L32.3661 17ZM33.3661 17C33.3661 7.96091 26.0385 0.633301 16.9995 0.633301L16.9995 1.6333C25.4862 1.6333 32.3661 8.51319 32.3661 17L33.3661 17Z"
                fill="#273C97"
            />
        </svg>
    )
}
