const ProfileCard = ({ children }) => {
  return (
    <div
      className={`w-[30%] max-[1500px]-w-[25%] max-w-[25rem] h-fit ms-auto mr-5 border border-gray-100 px-[1.2rem] py-[1.5rem] shadow-md mt-[2rem] bg-white rounded-xl sticky top-4 hidden tablet:flex flex-col items-center`}
    >
      {children}
    </div>
  );
};

export default ProfileCard; /*  */
