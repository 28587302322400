import React, { useEffect } from "react";
import DashboardBag from "../../../Components/svgs/DashboardBag";
import DashboardCalender from "../../../Components/svgs/DashboardCalender";
import DashboardPerson from "../../../Components/svgs/DashboardPerson";
import useFetch from "../../../hooks/useFetch";
import {
  CourseHomeEndPoint,
  UserDeliverablesStudents,
} from "../../../Services/endPoints";
import NextWebinarCard from "../../Common/NextWebinarCard/NextWebinarCard";
import styles from "./DashboardOverview.module.css";
import { Link } from "react-router-dom";
import Loading from "../../../Components/shared/Loading";

function DashboardOverview() {
  const {
    data: UserDeliverablesData,
    loading: UserDeliverablesLoading,
    error: UserDeliverablesError,
  } = useFetch(UserDeliverablesStudents);
  const { data: CourseData } = useFetch(CourseHomeEndPoint);

  useEffect(() => {
    console.log(UserDeliverablesData?.every(checkIsCompleted));
  }, [UserDeliverablesData]);
  if (UserDeliverablesLoading) return <Loading />;
  // if (UserDeliverablesError) return <p>Error loading data</p>;

  const checkIsCompleted = (deliverable) => deliverable.completed;
  const isAllDeliverablesCompleted =
    UserDeliverablesData?.every(checkIsCompleted);
  const getIconForCard = (index) => {
    if (index === 0) return <DashboardPerson className="w-8 h-8" />;
    if (index >= 1 && index <= 3)
      return <DashboardCalender className="w-8 h-8" />;
    if (index >= 4 && index <= 5) return <DashboardBag className="w-8 h-8" />;
    return null;
  };

  const getStatusText = (completed) => {
    if (completed === true)
      return { text: "Completed", className: styles.completed };
    if (completed === false)
      return { text: "Not Completed", className: styles.notCompleted };
    return { text: "Not Started", className: styles.notStarted };
  };

  return (
    <div className={styles.dashboardOverview}>
      <NextWebinarCard />
      {/* Status Tiles */}
      {isAllDeliverablesCompleted && (
        <div className="w-full flex flex-col gap-3 items-center mt-3">
          <h2 className="text-[#4caf50] text-center font-semibold text-[1.25rem] ">
            🎉 Well Done! 🎉
          </h2>
          <p className="text-black text-center font-semibold text-[1.125rem]">
            You’ve accomplished all your tasks—what an outstanding effort!
          </p>
        </div>
      )}
      <div className={styles.statusTiles}>
        {UserDeliverablesData?.map((tile, index) => {
          const { text, className } = getStatusText(tile.completed);
          return (
            <div
              key={index}
              className={`flex items-center gap-3 w-fi ${styles.tile}`}
            >
              <div className="rounded-[50%] p-3 overflow-hidden bg-[#273C9733] flex items-center justify-center">
                {getIconForCard(index)}
              </div>
              <div>
                <p className={className}>{text}</p>
                <p className={styles.tileLabel}>{tile.task_name}</p>
              </div>
            </div>
          );
        })}
      </div>

      {/* Continue Watching Section */}
      <Link
        className={styles.continueWatching}
        to={`/course/lesson/${CourseData?.last_lesson_id}`}
      >
        <h4 className="my-4 ml-2 text-[#202020] text-xl font-semibold">
          Continue Watching
        </h4>
        <div className={styles.courseCard}>
          <div className={styles.courseImage}>
            <img src={CourseData?.course_image} alt="Course Thumbnail" />
          </div>
          <div className={styles.courseInfo}>
            <h3>{CourseData?.name}</h3>
            <p>2:30hr • 14 Lesson</p>
            <div className={styles.instructorInfo}>
              <p>{CourseData?.instructor_name}</p>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default DashboardOverview;
