import React, { useState } from 'react';
import userDefultImage from '../../../assets/profile.png';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
function MobileAdminInfo() {
  const email = localStorage.getItem("user_email");
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="relative">
      <div className='flex justify-around items-center' onClick={toggleMenu}>

        <img
          className="w-12 h-12 rounded-full cursor-pointer"
          src={userDefultImage}
          alt="Profile"
        />
        <KeyboardArrowDownIcon className='text-gray-400 mr-2' />
      </div>


      {menuOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg p-4 z-10">
          <p className="text-sm font-medium text-gray-700">{email}</p>
          <p className="text-sm text-gray-400">Admin</p>
        </div>
      )}
    </div>
  );
}

export default MobileAdminInfo;
