import React from 'react'

export default function Yellow({ className }) {
    return (
        <svg
            width={12}
            height={13}
            viewBox="0 0 12 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <circle cx={6} cy={6.5} r={6} fill="#FFC107" />
        </svg>
    )
}
