import { useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LogoutIcon from "../../Components/svgs/LogoutIcon";
import SettingsIcon from "../../Components/svgs/SettingsIcon";
import { pageRoutes } from "../../constants";
import { sidebarLookup } from "../../constants/sidebarLookup";
import { getRole } from "../../Helpers/constants";

const NavigationTabs = ({ isSideBarOpen = true, closeDrawer = () => { } }) => {
  const location = useLocation();
  const role = getRole();
  const tabs = useMemo(() => sidebarLookup[role], [role]);
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("role");
    navigate("/");
  };
  return (
    <>
      {tabs.map((tab) => (
        <li
          key={tab.pageRoute}
          className="w-full list-none"
          onClick={closeDrawer}
        >
          <Link
            className={`${location.pathname.includes(`/${tab.pageRoute}`)
                ? "bg-primary text-white hover:bg-primary"
                : "hover:bg-blue-600 hover:bg-opacity-25"
              } flex gap-2 items-center h-10 px-3 rounded-lg transition-colors duration-150 ease-in-out  overflow-hidden`}
            to={`/${tab.pageRoute}`}
          >
            {tab.icon}
            <span
              className={`duration-300 ease-in-out ${isSideBarOpen ? "opacity-100" : "opacity-0"
                }`}
            >
              {tab.label}
            </span>
          </Link>
        </li>
      ))}
      <li className="w-full list-none t mt-auto" onClick={closeDrawer}>
        <Link
          className={`${location.pathname === pageRoutes.settings
              ? "bg-primary text-white"
              : "hover:bg-blue-600 hover:bg-opacity-25"
            } flex gap-2 items-center h-10 px-3 rounded-lg transition-colors duration-150 ease-in-ou overflow-hidden`}
          to={pageRoutes.settings}
        >
          <SettingsIcon className="w-[1.5rem] h-[1.5rem] flex-shrink-0 text-[#202020]" />
          <span
            className={`duration-300 ease-in-out ${isSideBarOpen ? "opacity-100" : "opacity-0"
              }`}
          >
            Settings
          </span>
        </Link>
      </li>
      <button
        className={`flex gap-2 items-center h-10 px-3 rounded-lg transition-colors duratio  n-150 ease-in-out overflow-hidden text-red-250 hover:bg-red-100 `}
        onClick={logout}
      >
        <LogoutIcon className="w-[1.5rem] h-[1.5rem] flex-shrink-0" />
        <span
          className={`text-red-250 duration-300 ease-in-out ${isSideBarOpen ? "opacity-100" : "opacity-0"
            }`}
        >
          Logout
        </span>
      </button>
    </>
  );
};

export default NavigationTabs;
