import React, { useState } from "react";
import QAComponent from "../../Components/QAComponent/QAComponent";
import QAFilter from "../../Components/QAFilter/QAFilter";

function QA() {
  const [filters, setFilters] = useState({
    category: "",
    coaching_round: "",
    answered: "",
  });

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };

  return (
    <div>
      <QAFilter onFilterChange={handleFilterChange} />
      <QAComponent filters={filters} />
    </div>
  );
}

export default QA;
