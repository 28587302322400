import { Link } from "react-router-dom";
import errorImage from "../../assets/error-page.png";
import DirectedArrow from "../../Components/svgs/DirectedArrow";

const NotFoundPage = () => {
  return (
    <div className="custom-container  pt-[5rem] sm:pt-0 sm:h-screen h-auto sm:justify-between  flex items-center gap-3 flex-wrap justify-center">
      <div className="flex flex-col gap-4">
        <h1 className="text-[3rem] text-primary font-semibold">Oops....</h1>
        <h2 className="text-[2.5rem] text-black-12 font-[400]">
          Page not found
        </h2>
        <p className="text-[#4B4B4B] text-base w-[20rem] leading-7">
          This Page doesn`t exist or was removed! We suggest you back to home.
        </p>
        <Link
          to="/"
          className="btn-primary w-fit py-3 px-6 flex items-center justify-center gap-2"
        >
          <DirectedArrow className="text-white" />
          <span className="text-white block text-base">Back to home</span>
        </Link>
      </div>
      <img className="h-[20rem] sm:h-[30rem]" src={errorImage} alt="" />
    </div>
  );
};

export default NotFoundPage;
