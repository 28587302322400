import React from 'react';
import userDefultImage from '../../../assets/profile.png';

function AdminUserInfo() {
  const email = localStorage.getItem("user_email");

  return (
    <div className='flex items-center gap-2 mr-3'>
      <img width="25%" src={userDefultImage} alt="userDefultImage" />
      <div className='text-sm'>
        <p >{email}</p>
        <p className='text-gray-400 font-normal'>Admin</p>
      </div>
    </div>
  );
}

export default AdminUserInfo;