import React from 'react'

export default function BackBtn({ className }) {
    return (
        <svg
            width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <rect width={20} height={20} rx={10} fill="white" />
            <path
                d="M12.6667 10V8.81334C12.6667 7.34001 11.6233 6.73667 10.3467 7.47334L9.31666 8.06667L8.28666 8.66001C7.00999 9.39667 7.00999 10.6033 8.28666 11.34L9.31666 11.9333L10.3467 12.5267C11.6233 13.2633 12.6667 12.66 12.6667 11.1867V10Z"
                stroke="#202020"
                strokeMiterlimit={10}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
