import React from 'react'

export default function Numbers1({ className }) {
    return (
        <svg
            width={34}
            height={33}
            viewBox="0 0 34 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M16.7522 0.0284068C16.9759 -0.00946895 17.2067 -0.00946895 17.4304 0.0284068L31.8592 2.55346C32.6672 2.69234 33.2516 3.31729 33.2516 4.03693C33.2516 4.75657 32.6672 5.38152 31.8592 5.5204L26.3258 6.49254V10.0971C26.3258 14.5601 22.1919 18.1772 17.0913 18.1772C11.9907 18.1772 7.8568 14.5601 7.8568 10.0971V6.49254L4.39387 5.88653V9.99605L5.52654 14.9452C5.59147 15.2418 5.5049 15.5512 5.28847 15.7847C5.07203 16.0183 4.74017 16.1572 4.39387 16.1572H2.08526C1.73896 16.1572 1.41431 16.0246 1.19067 15.7847C0.967018 15.5449 0.880445 15.2418 0.952589 14.9452L2.08526 9.99605V5.46358C1.39988 5.25526 0.930946 4.68713 0.930946 4.03693C0.930946 3.31729 1.51532 2.69234 2.32333 2.55346L16.7522 0.0284068ZM9.0039 20.6833C9.76142 20.4687 10.5766 20.7086 11.1249 21.2199L16.2472 25.9859C16.7017 26.4089 17.4736 26.4089 17.9282 25.9859L23.0504 21.2199C23.5987 20.7086 24.4139 20.4687 25.1715 20.6833C29.8608 22.0027 33.2516 25.8408 33.2516 30.3795C33.2516 31.4527 32.256 32.3175 31.0368 32.3175H3.14578C1.92654 32.3175 0.930946 31.4464 0.930946 30.3795C0.930946 25.8408 4.32173 22.0027 9.0039 20.6833Z"
                fill="#202020"
            />
        </svg>
    )
}
