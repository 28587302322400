import React from 'react';
import Yellow from '../../../Components/svgs/Yellow';
import { useTranslation } from 'react-i18next';

export default function SelectionCriteria() {
    const { t } = useTranslation();
    return (
        <div className='custom-container'>
            <h1 className='font-bold text-lg sm:text-[2.75rem] sm:text-center sm:mb-8 text-[#273C97]'>
                {t('selectionCriteriaTitle')}
            </h1>
            <div className='border rounded-t-md rounded-b-3xl border-[#FFC107] my-4 p-6 w-fit m-auto sm:px-12'>
                <h2 className='text-[#0A0338] font-normal text-sm sm:text-lg mb-5'>
                    {t('selectionCriteriaDescription')}
                </h2>
                <ul className='space-y-4'>
                    <li className='flex items-start sm:items-center gap-2 text-sm sm:text-lg'>
                        <Yellow className='flex-shrink-0' />
                        <span>
                            <span className='font-semibold text-[#273C97]'>{t('age')}</span>{' '}
                            {t('ageDescription')}
                        </span>
                    </li>
                    <li className='flex items-start sm:items-center  gap-2 text-sm sm:text-lg'>
                        <Yellow className='flex-shrink-0' />
                        <span>
                            <span className='font-semibold text-[#273C97]'>
                                {t('education')}
                            </span>{' '}
                            {t('educationDescription')}
                        </span>
                    </li>
                    <li className='flex items-start sm:items-center  gap-2 text-sm sm:text-lg'>
                        <Yellow className='flex-shrink-0' />
                        <span>
                            <span className='font-semibold text-[#273C97]'>
                                {t('itSkills')}
                            </span>{' '}
                            {t('itSkillsDescription')}
                        </span>
                    </li>
                    <li className='flex items-start sm:items-center  gap-2 text-sm sm:text-lg'>
                        <Yellow className='flex-shrink-0' />
                        <span>
                            <span className='font-semibold text-[#273C97]'>
                                {t('commandOfEnglish')}
                            </span>{' '}
                            {t('commandOfEnglishDescription')}
                        </span>
                    </li>
                    <li className='flex items-start sm:items-center  gap-2 text-sm sm:text-lg'>
                        <Yellow className='flex-shrink-0' />
                        <span>
                            <span className='font-semibold text-[#273C97]'>
                                {t('freelancingEducation')}
                            </span>{' '}
                            {t('freelancingEducationDescription')}
                        </span>
                    </li>
                    <li className='flex items-start sm:items-center  gap-2 text-sm sm:text-lg'>
                        <Yellow className='flex-shrink-0' />
                        <span>
                            <span className='font-semibold text-[#273C97]'>
                                {t('freelancingExperience')}
                            </span>{' '}
                            {t('freelancingExperienceDescription')}
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    );
}
