import React, { useState } from "react";
import styles from "./Jobs.module.css";
import AddIcon from "@mui/icons-material/Add";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Button,
  IconButton,
  Select,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  getFreelanceJobMyJobsEndPoint,
  getJobsCategoryEndPoint,
  patchStatusMyJobsEndPoint,
  postFreelanceJobEndPoint,
  freelancePlatformsEndpoint,
} from "../../../Services/endPoints";
import useFetch from "../../../hooks/useFetch";
import axios from "axios";
import CalenderIcon from "../../../Components/svgs/CalenderIcon";
import PriceIcon from "../../../Components/svgs/PriceIcon";
import BuildinTwoIcon from "../../../Components/svgs/BuildingTwoIcon";
import EditIcon from "../../../Components/svgs/EditIcon";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import NoteIcon from "../../../Components/svgs/NoteIcon";
import Loading from "../../../Components/shared/Loading";

function Jobs() {
  const [formOpen, setFormOpen] = useState(false);
  const [commentsOpen, setCommentsOpen] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    url: "",
    category: "",
    status: "",
    price: "",
    proposal_file: "",
    platformId: "",
  });

  // accepted status
  const [fileDialogOpen, setFileDialogOpen] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [statusProofFile, setStatusProofFile] = useState(null);
  const [fileNameStatusProofFile, setFileNameStatusProofFile] = useState("");
  const [fileUploadErrorStatusProofFile, setFileUploadErrorStatusProofFile] =
    useState("");
  // edits jobs
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isStatusChanged, setIsStatusChanged] = useState(false);

  const [errors, setErrors] = useState({});
  // eslint-disable-next-line
  const { data: jobCatigoryData } = useFetch(getJobsCategoryEndPoint);
  const {
    data: myJobsData,
    loading: myJobsDataLoading,
    error: myJobsDataError,
  } = useFetch(getFreelanceJobMyJobsEndPoint, [isStatusChanged]);
  const { data: UserFreelanceplatformsExternal } = useFetch(
    freelancePlatformsEndpoint
  );

  const validateForm = () => {
    let errors = {};

    if (!formData.title) errors.title = "Job title is required";
    if (!formData.url) errors.url = "Job URL is required";
    if (!formData.category) errors.category = "Category is required";
    if (!formData.status) errors.status = "Status is required";
    if (!formData.price || isNaN(formData.price))
      errors.price = "Valid price is required";
    if (!formData.proposal_file) {
      errors.proposal_file = "File upload is required";
    } else {
      const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];
      const maxFileSize = 5 * 1024 * 1024;
      if (!allowedTypes.includes(formData.proposal_file.type)) {
        errors.proposal_file = "Only PDF, JPG, or PNG files are allowed";
      } else if (formData.proposal_file.size > maxFileSize) {
        errors.proposal_file = "File size must be less than 5MB";
      }
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const submitPostJob = async () => {
    if (!validateForm()) return;
    const formDataRequest = new FormData();
    const data = {
      title: formData.title,
      url: formData.url,
      category: formData.category,
      price: formData.price,
      proposal_file: formData.proposal_file,
      platform: formData.platformId,
    };
    Object.entries(data).forEach(([key, value]) => {
      formDataRequest.append(key, value);
    });
    try {
      // eslint-disable-next-line
      const response = await axios.post(
        postFreelanceJobEndPoint,
        formDataRequest
      );
      // console.log("Job posted successfully:", response.data);
      closeForm();
      setIsStatusChanged((prev) => !prev);
    } catch (error) {
      console.error("Error posting job:", error);
      if (error.response && error.response.status === 400) {
        const backendErrors = error.response.data;
        const newErrors = {};

        for (const [field, messages] of Object.entries(backendErrors)) {
          newErrors[field] = Array.isArray(messages)
            ? messages.join(" ")
            : messages;
        }
        setErrors(newErrors);
      } else {
        setErrors({
          general: "An unexpected error occurred. Please try again later.",
        });
      }
    }
  };

  const openForm = () => {
    setFormOpen(true);
  };

  const closeForm = () => {
    setFormOpen(false);
    setFormData({
      title: "",
      url: "",
      category: "",
      status: "",
      price: "",
      proposal_file: "",
      platform: "",
    });
    setErrors({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, proposal_file: e.target.files[0] });
  };

  const handleCommentsOpen = () => {
    setCommentsOpen(true);
  };

  const handleCommentsClose = () => {
    setCommentsOpen(false);
  };

  const updateJobStatus = async (jobId, newStatus) => {
    try {
      const response = await axios.patch(
        `${patchStatusMyJobsEndPoint}/${jobId}/`,
        { status: newStatus }
      );
      console.log("Status updated successfully:", response.data);
      setIsStatusChanged((prev) => !prev);

      return response.data;
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleStatusChange = async (index, event, jobId) => {
    const newStatus = event.target.value;

    if (newStatus === "Accepted") {
      setFileDialogOpen(true);
      setSelectedJobId(jobId);
    } else {
      setIsStatusChanged((prev) => !prev);
      await updateJobStatus(jobId, newStatus);
      const updatedJobs = [...myJobsData.results];
      updatedJobs[index].status = newStatus;
      myJobsData.results = updatedJobs;
    }
  };

  const handleFileChangeStatusProofFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileNameStatusProofFile(file.name);
      setStatusProofFile(file);
      setFileUploadErrorStatusProofFile("");
    }
  };

  const handleAcceptedFileSubmit = async () => {
    if (!statusProofFile) {
      setFileUploadErrorStatusProofFile(
        "Please submit file to review for accepted Jobs."
      );
      return;
    }

    const formData = new FormData();
    formData.append("status", "Accepted");
    formData.append("status_proof_screenshot", statusProofFile);

    try {
      await axios.patch(
        `${patchStatusMyJobsEndPoint}/${selectedJobId}/`,
        formData
      );
      setFileDialogOpen(false);
      setStatusProofFile(null);
      setFileNameStatusProofFile("");
      setSelectedJobId(null);
      setFileUploadErrorStatusProofFile("");
      setIsStatusChanged((prev) => !prev);
    } catch (error) {
      console.error("Error updating job status with file:", error);
    }
  };

  // handle  handle
  const handleEditClick = (job) => {
    setSelectedJob(job);
    setEditDialogOpen(true);
  };

  const handleEditJobInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedJob((prevJob) => ({ ...prevJob, [name]: value }));
  };

  const handleEditSubmit = async () => {
    try {
      const formData = new FormData();

      formData.append("price", selectedJob.price);

      if (statusProofFile) {
        formData.append("status_proof_screenshot", statusProofFile);
      }

      const response = await axios.patch(
        `${patchStatusMyJobsEndPoint}/${selectedJob.id}/`,
        formData
      );

      console.log("Job updated successfully:", response.data);
      setEditDialogOpen(false);
      setIsStatusChanged((prev) => !prev);
    } catch (error) {
      console.error("Error updating job:", error);
    }
  };

  return (
    <>
      <div className={styles.topSection}>
        <div className={styles.addOpportunity} onClick={openForm}>
          <div className={styles.addIconContainer}>
            <AddIcon className={styles.addIcon} fontSize="small" />
          </div>
          <span className={styles.addOpportunityText}>
            Add any freelance opportunities you've had or completed!
          </span>
        </div>
        <div className={`${styles.guideSection} w-[60%]`}>
          <h2>Job Status Guide:</h2>
          <div className={styles.statusGuide}>
            <div className={styles.yellow}></div>
            <h3>Pending Review: Job submitted, awaiting instructor review.</h3>
          </div>
          <div className={styles.statusGuide}>
            <div className={styles.green}></div>
            <h3>Completed: Reviewed and approved by instructor.</h3>
          </div>
          <div className={styles.statusGuide}>
            <div className={styles.red}></div>
            <h3>Rejected: Reviewed by instructor, not approved.</h3>
          </div>
        </div>
      </div>
      {myJobsData?.results?.length === 0 && (
        <p className="text-gray-5f text-center font-semibold w-full text-lg">
          This is no gigs yet.
        </p>
      )}
      <div className={styles.jobCards}>
        {myJobsDataLoading ? (
          <Loading />
        ) : myJobsDataError ? (
          <p className="text-gray-5f text-center font-semibold w-full text-xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            Something went wrong !
          </p>
        ) : (
          myJobsData?.results?.map((job, index) => (
            <div key={job.id} className={styles.jobCard}>
              <div className="flex justify-between">
                <h3 className="text-black font-medium text-[1.3rem]">
                  {job.title}
                </h3>
                <div className={styles.cardHeader}>
                  {job.status === "Accepted" ? (
                    <>
                      {job.is_approved === false &&
                      job.waiting_for_approval === true ? (
                        <div className={styles.statusTextInReview}>
                          In Review
                        </div>
                      ) : job.is_approved === true &&
                        job.waiting_for_approval === false ? (
                        <div className={styles.statusTextAccepted}>
                          Accepted
                        </div>
                      ) : (
                        <div className={styles.statusTextRejected}>
                          Rejected
                        </div>
                      )}
                    </>
                  ) : (
                    <Select
                      value={job.status}
                      onChange={(e) => handleStatusChange(index, e, job.id)}
                      variant="outlined"
                      className={styles.JobSelect}
                      style={{
                        border: "1px solid blue",
                        color: "blue",
                        borderRadius: "5px",
                        padding: "0px",
                        marginInline: "0px",
                      }}
                    >
                      {job.status !== "In Progress" &&
                        job.status !== "Accepted" &&
                        job.status !== "Rejected" && (
                          <MenuItem value="Proposal Submitted">
                            Proposal Submitted
                          </MenuItem>
                        )}
                      {job.status !== "Accepted" &&
                        job.status !== "Rejected" && (
                          <MenuItem value="In Progress">In Progress</MenuItem>
                        )}
                      <MenuItem value="Accepted">Accepted</MenuItem>
                      <MenuItem value="Rejected">Rejected</MenuItem>
                    </Select>
                  )}
                  {job.status === "Accepted" &&
                    job.is_approved === false &&
                    job.waiting_for_approval === false && (
                      <EditIcon
                        onClick={() => handleEditClick(job)}
                        className="w-[2.4rem] h-[2.4rem] ml-2"
                      />
                    )}
                </div>
              </div>

              <div className={styles.cardDetails}>
                <div className="flex justify-between">
                  <p>
                    <BuildinTwoIcon className="w-[1rem] h-[1rem]" />{" "}
                    {job.platform}
                  </p>
                  <p className="w-[50%]">
                    <CalenderIcon className="w-[1rem] h-[1rem]" />{" "}
                    {new Date(job.created_on).toLocaleDateString()}
                  </p>
                </div>
                <div className="flex justify-between">
                  <p className="w-[50%]">
                    <NoteIcon className="w-[1rem] h-[1rem]" /> {job.category}
                  </p>
                  <p className="w-[50%]">
                    <PriceIcon className="w-[1.2rem] h-[1.2rem] text-gray-7e " />
                    {job.price} USD
                  </p>
                </div>
              </div>
              <button className={styles.viewLinkButton}>
                <a href={job.url} target="_blank" rel="noopener noreferrer">
                  View Link
                </a>
              </button>
              {job.is_approved === false &&
                job.waiting_for_approval === false && (
                  <button
                    className={styles.commentsBtn}
                    onClick={handleCommentsOpen}
                  >
                    See Instructor Comments
                  </button>
                )}
            </div>
          ))
        )}
      </div>

      {/* Add New Job Dialog  */}
      <Dialog
        open={formOpen}
        onClose={closeForm}
        className="rounded-xl"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          Add New Job
          <IconButton
            onClick={closeForm}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="dense"
            label="Job Title"
            name="title"
            variant="outlined"
            value={formData.title}
            onChange={handleInputChange}
            error={!!errors.title}
            helperText={errors.title}
          />
          <TextField
            fullWidth
            margin="dense"
            label="Job URL"
            name="url"
            variant="outlined"
            value={formData.url}
            onChange={handleInputChange}
            error={!!errors.url}
            helperText={errors.url}
          />
          <TextField
            fullWidth
            select
            margin="dense"
            label="Category"
            name="category"
            variant="outlined"
            value={formData.category}
            onChange={handleInputChange}
            error={!!errors.category}
            helperText={errors.category}
          >
            {jobCatigoryData &&
              jobCatigoryData.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
          </TextField>
          <TextField
            fullWidth
            select
            margin="dense"
            label="Status"
            name="status"
            variant="outlined"
            value={formData.status}
            onChange={handleInputChange}
            error={!!errors.status}
            helperText={errors.status}
          >
            <MenuItem value="Proposal Submitted">Proposal Submitted</MenuItem>
          </TextField>
          <TextField
            fullWidth
            margin="dense"
            label="Price (USD)"
            name="price"
            variant="outlined"
            value={formData.price}
            onChange={handleInputChange}
            error={!!errors.price}
            helperText={errors.price}
          />
          <select
            name="platformId"
            value={formData.platformId}
            onChange={handleInputChange}
            className="w-[100%] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option value="">Select Platform</option>
            {UserFreelanceplatformsExternal &&
              UserFreelanceplatformsExternal.map((platform) => (
                <option key={platform.id} value={platform.id}>
                  {platform.name}
                </option>
              ))}
          </select>

          <p className="my-2">Upload Proposal File</p>
          <input
            type="file"
            accept=".pdf"
            onChange={(e) => handleFileChange(e)}
          />
          {errors.proposal_file && (
            <p style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
              {errors.proposal_file}
            </p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={submitPostJob} variant="contained" color="primary">
            Add Job
          </Button>
          <Button onClick={closeForm} color="secondary" variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={commentsOpen}
        onClose={handleCommentsClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          Instructor Comment
          <IconButton
            onClick={handleCommentsClose}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <p>
            These are the comments from the instructor regarding your job
            application...
          </p>
        </DialogContent>
        <DialogActions className={styles.commentsBtnSection}>
          <button
            className={styles.viewLinkButton}
            onClick={handleCommentsClose}
          >
            OK
          </button>
        </DialogActions>
      </Dialog>

      {/* Upload Proof of Acceptance Dialog */}
      <Dialog
        open={fileDialogOpen}
        onClose={() => setFileDialogOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <h2 className="p-3 text-xl text-center text-[#273C97] font-bold  my-5">
          Amazing Work! 🚀
        </h2>
        <p className="font-[1.5rem] text-center w-[85%] m-auto ">
          Upload proof of your client’s acceptance to celebrate this milestone
          and keep moving forward in your journey!
        </p>
        <DialogContent>
          <div className="w-full max-w-xs mx-auto">
            {fileNameStatusProofFile && (
              <p className="my-4 flex items-center  ">
                <TaskOutlinedIcon /> {fileNameStatusProofFile}
              </p>
            )}
            <label className="flex flex-col items-center px-4 py-2 bg-[#EBEBEB] text-black rounded-lg cursor-pointer ">
              <span className="text-base leading-normal">
                {!fileNameStatusProofFile ? (
                  <div className="flex items-center">Upload File</div>
                ) : (
                  <div className="flex items-center">Upload Other file</div>
                )}
              </span>
              <input
                type="file"
                accept=".pdf"
                onChange={handleFileChangeStatusProofFile}
                className="hidden"
              />
            </label>
            <p className="text-center text-[.8rem] text-[#999898]">
              You can upload a file (PDF, JPG, PNG, etc...)
            </p>

            {fileUploadErrorStatusProofFile && (
              <p className="mt-2 text-sm text-red-600 text-center">
                {fileUploadErrorStatusProofFile}
              </p>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <button
            onClick={handleAcceptedFileSubmit}
            className="btn btn-primary"
          >
            Submit
          </button>
          <button
            style={{ border: "1px solid red" }}
            onClick={() => setFileDialogOpen(false)}
            className="text-red-700  font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
          >
            Close
          </button>
        </DialogActions>
      </Dialog>

      {/* edit job  Dialog */}
      <Dialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Edit Job</DialogTitle>
        <DialogContent>
          <div className="my-5">
            <TextField
              fullWidth
              label="Price"
              name="price"
              value={selectedJob?.price || ""}
              onChange={handleEditJobInputChange}
            />
          </div>
          <div className="my-5">
            <div className="w-full max-w-xs mx-auto">
              <label className="flex flex-col items-center px-4 py-2 bg-blue-600 text-white rounded-lg cursor-pointer hover:bg-blue-700 transition duration-200">
                <span className="text-base leading-normal">
                  Upload Proof Screenshot
                </span>
                <input
                  type="file"
                  accept=".pdf"
                  onChange={handleFileChangeStatusProofFile}
                  className="hidden"
                />
              </label>

              {fileNameStatusProofFile && (
                <span className="mt-2 text-sm text-gray-700">
                  Selected file: <strong>{fileNameStatusProofFile}</strong>
                </span>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditSubmit} color="primary">
            Save
          </Button>
          <Button onClick={() => setEditDialogOpen(false)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Jobs;
