import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CoachingRoundEndPoint, TasksName } from '../../../../Services/endPoints';
import useFetch from '../../../../hooks/useFetch';

const DelivrablesFilter = ({ onFilterChange }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedAnswerStatus, setSelectedAnswerStatus] = useState(searchParams.get('reviewed') || '');
  const [selectedCoachingRound, setSelectedCoachingRound] = useState(searchParams.get('coachingRound') || '');
  const [selectedTask, setSelectedTask] = useState(searchParams.get('deliverable') || '');

  const { data: coachingRoundsData } = useFetch(CoachingRoundEndPoint);
  const { data: tasksNameData } = useFetch(TasksName);

  const coachingRounds = Array.isArray(coachingRoundsData?.results) ? coachingRoundsData.results : [];
  const tasks = Array.isArray(tasksNameData) ? tasksNameData : []; // Use tasksNameData directly

  const updateFilters = (newFilter) => {
    const currentParams = Object.fromEntries(searchParams.entries());
    const updatedFilters = { ...currentParams, ...newFilter };

    Object.keys(updatedFilters).forEach((key) => {
      if (!updatedFilters[key]) delete updatedFilters[key];
    });

    setSearchParams(updatedFilters);
    onFilterChange(updatedFilters);
  };

  const handleCoachingRoundChange = (e) => {
    const value = e.target.value;
    setSelectedCoachingRound(value);
    updateFilters({ coachingRound: value });
  };

  const handleAnswerStatusChange = (e) => {
    const value = e.target.value;
    setSelectedAnswerStatus(value);
    updateFilters({ reviewed: value });
  };

  const handleTaskChange = (e) => {
    const value = e.target.value;
    setSelectedTask(value);
    updateFilters({ deliverable: value });
  };

  const clearFilters = () => {
    setSelectedTask('');
    setSelectedAnswerStatus('');
    setSelectedCoachingRound('');
    setSearchParams({});
    onFilterChange({});
  };

  const removeFilter = (filterKey) => {
    const updatedFilters = {
      coachingRound: selectedCoachingRound,
      reviewed: selectedAnswerStatus,
      deliverable: selectedTask,
    };
    updatedFilters[filterKey] = '';
    if (filterKey === 'coachingRound') setSelectedCoachingRound('');
    if (filterKey === 'reviewed') setSelectedAnswerStatus('');
    if (filterKey === 'deliverable') setSelectedTask('');

    updateFilters(updatedFilters);
  };

  return (
    <div className="flex flex-col gap-4 mb-4">
      <div className="flex gap-4">
        <select
          value={selectedTask}
          onChange={handleTaskChange}
          className="border p-2 bg-white border-gray-300 text-gray-900 text-sm rounded-xl"
        >
          <option value="">Tasks</option>
          {tasks.map((task) => (
            <option key={task.id} value={task.id}>
              {task.id}
            </option>
          ))}
        </select>
        <select
          value={selectedCoachingRound}
          onChange={handleCoachingRoundChange}
          className="border p-2 bg-white border-gray-300 text-gray-900 text-sm rounded-xl"
        >
          <option value="">Coaching Rounds</option>
          {coachingRounds.map((round, index) => (
            <option key={index} value={round.code}>
              {round.code}
            </option>
          ))}
        </select>

        <select
          value={selectedAnswerStatus}
          onChange={handleAnswerStatusChange}
          className="border p-2 bg-white border-gray-300 text-gray-900 text-sm rounded-xl"
        >
          <option value="">Review Status</option>
          <option value="true">Reviewed</option>
          <option value="false">Not Reviewed</option>
        </select>



        <button onClick={clearFilters} className="bg-[#EFEFEF] text-black px-4 py-2 rounded-xl">
          Clear All
        </button>
      </div>

      <div className="flex gap-2 flex-wrap mt-2">

        {selectedCoachingRound && (
          <span className="border text-gray-700 px-3 py-1 rounded-full flex items-center gap-2">
            Coaching Round: {selectedCoachingRound}
            <button
              onClick={() => removeFilter('coachingRound')}
              className="text-gray-500 hover:text-gray-800"
            >
              &times;
            </button>
          </span>
        )}

        {selectedAnswerStatus && (
          <span className="border text-gray-700 px-3 py-1 rounded-full flex items-center gap-2">
            Reviewed Status: {selectedAnswerStatus === 'true' ? 'Reviewed' : 'Not Reviewed'}
            <button
              onClick={() => removeFilter('reviewed')}
              className="text-gray-500 hover:text-gray-800"
            >
              &times;
            </button>
          </span>
        )}

        {selectedTask && (
          <span className="border text-gray-700 px-3 py-1 rounded-full flex items-center gap-2">
            Task: {selectedTask}
            <button
              onClick={() => removeFilter('deliverable')}
              className="text-gray-500 hover:text-gray-800"
            >
              &times;
            </button>
          </span>
        )}
      </div>
    </div>
  );
};

export default DelivrablesFilter;
