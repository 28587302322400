import React from 'react'

export default function Proposals({ className }) {
    return (
        <svg
            width={18}
            height={18}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M3.98553 4.05542H14.1493C14.268 4.05536 14.3866 4.06289 14.5044 4.07797C14.4644 3.79776 14.3682 3.52853 14.2214 3.28653C14.0746 3.04452 13.8804 2.83474 13.6503 2.66985C13.4203 2.50495 13.1592 2.38834 12.8829 2.32706C12.6066 2.26578 12.3207 2.2611 12.0425 2.31329L3.68379 3.74034H3.67426C3.14958 3.84068 2.683 4.13753 2.36981 4.57027C2.84166 4.23466 3.40649 4.05467 3.98553 4.05542V4.05542Z"
                fill="white"
            />
            <path
                d="M14.1494 4.81934H3.98569C3.44675 4.81992 2.93006 5.03428 2.54897 5.41536C2.16788 5.79645 1.95353 6.31315 1.95294 6.85208V12.9503C1.95353 13.4893 2.16788 14.006 2.54897 14.387C2.93006 14.7681 3.44675 14.9825 3.98569 14.9831H14.1494C14.6884 14.9825 15.2051 14.7681 15.5861 14.387C15.9672 14.006 16.1816 13.4893 16.1822 12.9503V6.85208C16.1816 6.31315 15.9672 5.79645 15.5861 5.41536C15.2051 5.03428 14.6884 4.81992 14.1494 4.81934V4.81934ZM12.6407 10.9176C12.4397 10.9176 12.2432 10.858 12.0761 10.7463C11.9089 10.6346 11.7787 10.4759 11.7017 10.2902C11.6248 10.1044 11.6047 9.90008 11.6439 9.70292C11.6831 9.50576 11.7799 9.32466 11.9221 9.18252C12.0642 9.04038 12.2453 8.94358 12.4425 8.90436C12.6396 8.86514 12.844 8.88527 13.0297 8.9622C13.2154 9.03913 13.3742 9.1694 13.4858 9.33654C13.5975 9.50368 13.6571 9.70019 13.6571 9.90121C13.6571 10.1708 13.55 10.4293 13.3594 10.6199C13.1688 10.8105 12.9103 10.9176 12.6407 10.9176Z"
                fill="white"
            />
            <path
                d="M1.96875 8.99426V5.83397C1.96875 5.1457 2.34989 3.99179 3.67276 3.74183C4.79554 3.53125 5.9072 3.53125 5.9072 3.53125C5.9072 3.53125 6.63772 4.03944 6.03425 4.03944C5.43077 4.03944 5.44666 4.8176 6.03425 4.8176C6.62184 4.8176 6.03425 5.564 6.03425 5.564L3.668 8.24786L1.96875 8.99426Z"
                fill="white"
            />
        </svg>
    )
}
