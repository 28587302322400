import * as React from "react";
const PriceIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={19}
    fill="none"
    viewBox="0 0 18 19"
    className={className}
  >
    <g clipPath="url(#a)">
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.234}
        d="M14.178 1.334H9.856a.508.508 0 0 0-.358.148L.858 10.12a1.014 1.014 0 0 0 0 1.43l4.113 4.114a1.015 1.015 0 0 0 1.431 0L15.04 7.03a.508.508 0 0 0 .148-.359V2.347a1.01 1.01 0 0 0-1.01-1.013Z"
      />
      <path
        fill="currentcolor"
        d="M12.375 5.272a1.125 1.125 0 1 1 0-2.25 1.125 1.125 0 0 1 0 2.25Z"
      />
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.234}
        d="m8.086 17.085 9.21-9.211a.486.486 0 0 0 .142-.352v-4.5"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .21h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default PriceIcon;
