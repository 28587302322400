import { UserProfileURL } from "../Services/endPoints";
import useFetch from "./useFetch";

const userSystemInfo = [
  {
    name: `Student 
ID`,
    fieldName: "id",
  },
  {
    name: "Coaching Round Code",
    fieldName: "coaching_rounds",
  },
  {
    name: `Job Profile 
    Code`,
    fieldName: "job_profile_round",
  },
];
export const useGetStudentProfile = () => {
  const { data: userProfileData } = useFetch(UserProfileURL);

  return { userProfileData, userSystemInfo };
};
