import Dropdown from "../../../Components/shared/Dropdown";
import arrowDown from "../../../assets/arrow-down.svg";

const ProfileButton = ({ userProfileImage, placeholderImage, toggleMenu }) => {
  return (
    <button className="flex items-center gap-2" onClick={toggleMenu}>
      <div className="w-[2.5rem] circle">
        <img
          className="cover-image"
          src={userProfileImage || placeholderImage}
          alt="default-user-image"
        />
      </div>
      <img className="w-[1rem]" src={arrowDown} alt="" />
    </button>
  );
};
const ProfileMobileMenu = ({ userImage, children, placeholderImage }) => {
  return (
    <Dropdown
      renderOpenButton={(toggleMenu) => (
        <ProfileButton
          userProfileImage={userImage}
          placeholderImage={placeholderImage}
          toggleMenu={toggleMenu}
        />
      )}
      containerClassName="z-[-1]"
      menuClassName="w-[20rem] px-4 items-center"
    >
      {children}
    </Dropdown>
  );
};

export default ProfileMobileMenu;
