import React from "react";
import Header from "../components/Header";
import CardsInfo from "../components/CardsInfo";
import group from "../../../assets/Group2.png";
import student from "../../../assets/student.png";
import Table from "../Common/Table/Table";
import { dashboardStudentsEndPoint, dashboardStudentsStatisticsEndPoint } from "../../../Services/endPoints";
import usePaginationNumbers from "../../../hooks/usePaginationNumbers";
import TableSkeleton from "../Common/Table/TableSkeleton";
import { useSearchParams } from "react-router-dom";
import StudentFilters from "../components/StudentFilters";
import useFetch from '../../../hooks/useFetch';

function Students() {
  const [searchParams] = useSearchParams();

  // Extract filter parameters from the URL
  const coachingRound = searchParams.get("coaching_round");
  const jobProfileRound = searchParams.get("job_profile_round");
  const technicalProvider = searchParams.get("technical_provider");
  const track = searchParams.get("track");

  // Construct the dynamic API URL with filters
  const queryParams = new URLSearchParams();
  if (coachingRound) queryParams.append("coaching_round", coachingRound);
  if (jobProfileRound) queryParams.append("job_profile_round", jobProfileRound);
  if (technicalProvider)
    queryParams.append("technical_provider", technicalProvider);
  if (track) queryParams.append("track", track);

  const dynamicStudentsEndpoint = `${dashboardStudentsEndPoint}?${queryParams.toString()}`;

  const {
    data: dashboardStudentsData,
    page,
    totalPages,
    isLoading,
    error,
    rowsPerPage,
    handlePageChange,
    setCurrentPage,
    nextPage,
    prevPage,
    setRowsPerPage: handleRowsPerPageChange,
  } = usePaginationNumbers(dynamicStudentsEndpoint);

  const results = dashboardStudentsData || [];

  // const 
  const {
    data: dashboardStudentStatisticsData,
  } = useFetch(dashboardStudentsStatisticsEndPoint);


  // Cards Data
  const studentCards = [
    {
      img: <img src={group} alt="group" />,
      title: "Students",
      value: dashboardStudentStatisticsData?.total_students,
      active: dashboardStudentStatisticsData?.joined_students,
    },
    {
      img: <img src={student} alt="student" />,
      title: "Completed one gig",
      value: dashboardStudentStatisticsData?.completed_gigs
    },
  ];

  // Table Columns
  const tableColumns = [
    { header: "Student ID", accessor: "id" },
    { header: "Name", accessor: "name" },
    { header: "Email", accessor: "email" },
    { header: "Phone", accessor: "phone" },
    { header: "Technical Provider", accessor: "techProvider" },
    { header: "Job Profiles", accessor: "jobProfiles" },
    { header: "Coaching Rounds", accessor: "coachingRounds" },
    { header: "Status", accessor: "status" },
  ];

  // Map API Data to Table Rows
  const tableData = results.map((student) => ({
    id: student.id, // Student ID
    name: student.full_name, // Full Name
    email: student.user.email, // Email from user object
    phone: student.phone, // Phone
    techProvider: student.technical_provider || "N/A", // Technical Provider
    jobProfiles: student.job_profile_round || "None", // Job Profile Round
    coachingRounds: student.coaching_rounds || "None", // Coaching Rounds
    status: student.user.is_joined ? "Active" : "Inactive", // Status
  }));

  return (
    <div className="p-4">
      <Header>
        <CardsInfo cards={studentCards} />
      </Header>

      {/* Filters Component */}
      <StudentFilters />

      <div className="mt-6">
        {isLoading ? (
          <TableSkeleton columns={tableColumns.length} rows={5} />
        ) : error ? (
          <p>Error fetching data</p>
        ) : results.length > 0 ? (
          <Table
            columns={tableColumns}
            data={tableData}
            rowsPerPageOptions={[5, 10, 20]}
            isLoading={isLoading}
            currentPage={page}
            totalPages={totalPages}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={handleRowsPerPageChange}
            setCurrentPage={setCurrentPage}
            handlePageChange={handlePageChange}
            prevPage={prevPage}
            nextPage={nextPage}
          />
        ) : (
          <p>No data available</p>
        )}
      </div>
    </div>
  );
}

export default Students;
