import "plyr/dist/plyr.css";
import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { pageRoutes } from "../../../constants";
import { useCourseLayoutContext } from "./CourseLayoutProvider";
import CourseVideoLessons from "./CourseVideoLessons/CourseVideoLessons";
import Loading from "../../../Components/shared/Loading";

function Course() {
  const navigate = useNavigate();
  const location = useLocation();

  const { courseData, courseLoading } = useCourseLayoutContext();
  useEffect(() => {
    if (courseData && location.pathname === `/${pageRoutes.course}`)
      navigate(
        `/${pageRoutes.course}/${pageRoutes.lesson}/${courseData?.lessons[0]?.id}`
      );
  }, [courseData]);

  if (courseLoading) return <Loading />;
  return (
    <div className="flex  gap-3 md:flex-row flex-col">
      <div className="md:w-[70%] w-[100%]">
        <Outlet />
      </div>
      <CourseVideoLessons />
    </div>
  );
}

export default Course;
