import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { toast } from "react-toastify";
import Image from "../../../assets/OBJECTS.svg";
import BackBtn from "../../../Components/svgs/BackBtn";
import CalenderIcon from "../../../Components/svgs/CalenderIcon";
import Person from "../../../Components/svgs/Person";
import TimeIcon from "../../../Components/svgs/TimeIcon";
import ZoomIcon from "../../../Components/svgs/ZoomIcon";
import useFetch from "../../../hooks/useFetch";
import { apiPostWebinarAttendance } from "../../../Services/apiPostWebinarAttendance";
import { NextWebinars } from "../../../Services/endPoints";

function NextWebinarCard() {
  const {
    data: nextWebinarData,
    loading: userFreelancePlatformsExternalLoading,
    error: userFreelancePlatformsExternalError,
  } = useFetch(NextWebinars);

  // Display loading message or spinner
  if (userFreelancePlatformsExternalLoading) {
    return (
      <Box sx={{ display: "flex" }}>
        <CircularProgress />
      </Box>
    );
  }
  if (nextWebinarData?.message) {
    return (
      <p className="text-gray-5f text-center font-semibold w-full text-xl my-3">
        {nextWebinarData?.message}
      </p>
    );
  }
  if (userFreelancePlatformsExternalError) {
    toast.error("error fetching next webinar");
    return;
  }
  const joinWebinar = async (webinar) => {
    await apiPostWebinarAttendance(webinar?.id);
    window.open(webinar?.link, "_blank");
  };

  return (
    <div className="flex bg-primary border rounded-2xl py-5 text-white ps-[3rem] ">
      <div>
        <h4 className="mb-2 text-sm">Your Next Webinar</h4>
        <h2 className="text-xl mb-5 font-semibold">{nextWebinarData?.name}</h2>
        <div className="flex items-center justify-between mb-4 text-lg">
          <div className="flex items-center mr-5">
            <CalenderIcon className="text-white mr-2 w-[1.125rem] h-[1.125rem]" />
            <h1 className="text-lg"> {nextWebinarData?.date} </h1>
          </div>
          <div className="flex items-center">
            <Person className="text-white mr-2 w-[1.125rem] h-[1.125rem]" />
            <h1 className="text-lg"> {nextWebinarData?.instructor_name} </h1>
          </div>
        </div>
        <div className="flex items-center justify-between text-base">
          <div className="flex items-center mr-5">
            <TimeIcon className="text-white mr-2 w-[1.125rem] h-[1.125rem]" />
            <h1 className="text-lg"> {nextWebinarData?.time} </h1>
          </div>
          <div className="flex items-center">
            <ZoomIcon className="text-white mr-2 w-[1.125rem] h-[1.125rem]" />
            <h1 className="text-lg"> {nextWebinarData?.date} </h1>
          </div>
        </div>
        <button
          onClick={() => joinWebinar(nextWebinarData)}
          target="_blank"
          className="bg-[#FFC107] px-4 py-3 mt-4 rounded-xl w-fit flex gap-3 items-center"
        >
          <span className="block text-sm font-semibold  text-[#273C97]">
            Join Now
          </span>

          <BackBtn className="rotate-180" />
        </button>
      </div>

      <div className="md:block hidden mx-auto">
        <img src={Image} alt="Webinar Banner" />
      </div>
    </div>
  );
}

export default NextWebinarCard;
