import React from "react";

function TableSkeleton({ columns = 5, rows = 5 }) {
  return (
    <div className="overflow-x-auto p-4">
      <table className="w-full table-auto border-collapse rounded-8xl">
        <thead className=" bg-gray-300 text-white">
          <tr>
            {[...Array(columns).keys()].map((index) => (
              <th
                key={index}
                className="text-center px-1 py-2 text-sm font-medium text-white"
              >
                <div className="h-4 bg-gray-300 animate-pulse rounded"></div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {[...Array(rows).keys()].map((rowIndex) => (
            <tr key={rowIndex} className="hover:bg-gray-50 bg-white">
              {[...Array(columns).keys()].map((colIndex) => (
                <td
                  key={colIndex}
                  className="px-4 py-2 border border-gray-200 text-sm text-center"
                >
                  <div className="h-4 bg-gray-300 animate-pulse rounded"></div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <div className="flex items-center mt-4">
        {/* Rows Per Page */}
        <div className="flex items-center gap-2">
          <span className="h-4 bg-gray-300 animate-pulse rounded w-12"></span>
          <div className="h-8 w-16 bg-gray-300 animate-pulse rounded"></div>
          <span className="h-4 bg-gray-300 animate-pulse rounded w-12"></span>
        </div>

        {/* Pagination Controls */}
        <div className="flex items-center justify-center gap-2 w-[60%]">
          <div className="h-8 w-8 bg-gray-300 animate-pulse rounded"></div>
          {[...Array(3).keys()].map((pageIndex) => (
            <div
              key={pageIndex}
              className="h-8 w-8 bg-gray-300 animate-pulse rounded"
            ></div>
          ))}
          <div className="h-8 w-8 bg-gray-300 animate-pulse rounded"></div>
        </div>
      </div>
    </div>
  );
}

export default TableSkeleton;
