import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import arrow from "../../../../assets/arrow-down.svg";
import { useFreelanceAccounts } from "./useFreelanceAccounts";
import CloseIcon from "../../../../Components/svgs/CloseIcon";
const FreelanceAccounts = () => {
  const {
    newPlatform,
    setNewPlatform,
    showInputs,
    setShowInputs,
    postPlatformLink,
    platformId,
    setPlatformId,
    urlError,
    submitError,
    freelancePlatforms,
    freelanceProfileLinks,
    platformLogos,
    normalizePlatformName,
  } = useFreelanceAccounts();
  const isPlatformIdEmpty = platformId === "";
  const modalTabletClass =
    "absolute top-[105%] left-[5%] w-[90%] bg-[#f8f8f8] shadow-lg rounded-xl flex flex-col items-center justify-center pt-[4rem] pb-[2rem] px-[1.5rem] z-[99] max-w-[30rem]";
  return (
    <>
      <button
        onClick={() => setShowInputs(!showInputs)}
        className="tablet:my-3 w-auto  tablet:w-full flex items-center gap-1"
      >
        <ControlPointOutlinedIcon className="text-black-12 !w-[1.2rem] !h-[1.2rem]" />
        <span className="block text-base tablet:text-sm">
          Add Freelance Account
        </span>
      </button>

      {showInputs && (
        <div
          className={`tablet:static tablet:translate-x-0  tablet:p-0 tablet:shadow-none tablet:border-none tablet:block tablet:bg-transparent ${modalTabletClass}`}
        >
          <CloseIcon
            className="absolute tablet:hidden block top-[1.8rem] right-[1.8rem] w-[1.2rem] h-[1.2rem] cursor-pointer "
            onClick={() => setShowInputs(false)}
          />
          <select
            value={platformId}
            onChange={(e) => setPlatformId(e.target.value)}
            className={`${
              isPlatformIdEmpty ? "opacity-50" : ""
            } w-full text-sm py-2 px-4 bg-white border border-gray-350 rounded-lg appearance-none focus:border-gray-7e bg-no-repeat bg-[95%_center] bg-[length:0.8rem] cursor-pointer`}
            style={{
              backgroundImage: `url(${arrow})`,
            }}
          >
            <option value="">Select Platform</option>
            {freelancePlatforms &&
              freelancePlatforms.map((platform) => (
                <option key={platform.id} value={platform.id}>
                  {platform.name}
                </option>
              ))}
          </select>
          <input
            type="text"
            value={newPlatform}
            onChange={(e) => setNewPlatform(e.target.value)}
            placeholder="Freelance Platform Link"
            className="text-sm mt-3 w-full border border-gray-350 rounded-lg py-2 px-4 focus:border-gray-7e"
          />
          <button
            onClick={postPlatformLink}
            className="btn-primary px-4 py-3 mt-4 mx-auto"
          >
            Submit Link
          </button>
          {urlError && (
            <>
              <Stack sx={{ width: "100%" }} spacing={2}>
                <Alert severity="error">{urlError}</Alert>
              </Stack>
            </>
          )}
          {submitError && (
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert severity="error">{submitError}</Alert>
            </Stack>
          )}
        </div>
      )}

      <div className="flex flex-wrap items-center self-start gap-2 tablet:my-3">
        {freelanceProfileLinks &&
          freelanceProfileLinks?.map((platform, index) => {
            const normalizedPlatformName = normalizePlatformName(
              platform.platform_name
            );

            return (
              <a
                key={`freelance-platforms-${index}`}
                className="w-[1.8rem] aspect-square rounded-[50%] overflow-hidden"
                href={platform.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={
                    platformLogos[normalizedPlatformName] ||
                    platformLogos["other"]
                  }
                  alt="freelance platform logo"
                />
              </a>
            );
          })}
      </div>

      {freelanceProfileLinks && freelanceProfileLinks?.length < 3 && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="warning">You must submit at least 3 links..</Alert>
        </Stack>
      )}
    </>
  );
};

export default FreelanceAccounts;
